export default {
    red: '#7c4ae8',
    red1: '#F25400',
    orange: '#F23F00',
    orange1: '#F26E00',
    orange2:  '#fc6e20',
    lightorange: '#f0edf7',
    black: '#000',
    white: '#fff',
    grey: '#727272',
    darkgrey: '#263b5d',
    lightgrey: '#F7F7F7',
    peach: '#8c62e5',
    lightpeach: '#c5aaff',
    green: '#ff0066',
    green1: '#ff0066',
    unverified: '#ff5251',
    verify: '#69af90',
    lightblue: '#d1b9ff',
    neomorph: '#e4ebf5',
    coinlight: '#FD9346',
    coinDark: '#FF6200',
    coinBackground: '#fff6ef',
    greenShadow: '#1e8c45',
    storeLightGreen: '#FcFFF9',
    storeDarkGreen: '#7abc43'
}

