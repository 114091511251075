import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Dimensions,
  Image,
  ScrollView,
  Button,
  useWindowDimensions,
} from "react-native";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import colors from "../constants/theme/colors";
import { useFonts } from "expo-font";
import LottieView from "react-native-web-lottie";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withTiming,
  withRepeat,
} from "react-native-reanimated";

const HowSection = (props) => {
  const deviceSize = useDeviceSize();
  const progress = useSharedValue(1);

  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: progress.value }],
    };
  }, []);

  useEffect(() => {
    progress.value = withRepeat(withTiming(10, { duration: 1000 }), -1, true);
  }, []);

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }

  if (deviceSize === "extralarge") {
    return (
      <View style={styles.featuresContainer}>
        <View style={styles.featureHeaderContainer}>
          <Text style={styles.headerTextBlack}>How it Works?</Text>
        </View>
        <View style={styles.featureTextContainer}>
          <Text style={styles.featuresText}>
            LinksApp provides a comprehensive solution for managing, sharing,
            and even monetizing your online content and recommendations.
          </Text>
        </View>
        <View style={styles.featureBlockContainer}>
          <View style={styles.howRightBlock}>
            <View>
              <Animated.Image
                style={[{ width: 300, height: 533 }, reanimatedStyle]}
                source={require("../assets/images/how-work.png")}
              />
            </View>
          </View>
          <View style={styles.howLeftBlock}>
            <View style={styles.featureBlock}>
              <View style={styles.iconMainContainer}>
                <View style={styles.stepContainer}>
                  <Image
                    style={{ width: 41, height: 60 }}
                    source={require("../assets/images/step1.png")}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>
                    Centralized Link Hub
                  </Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    LinksApp simplifies your online presence by creating a
                    central hub for all your important links. After signing up,
                    you can add links to your social media profiles, website,
                    blog, online store, and more in one place. This hub becomes
                    your go-to destination for sharing multiple links
                    seamlessly.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.featureBlock}>
              <View style={styles.iconMainContainer}>
                <View style={styles.stepContainer}>
                  <Image
                    style={{ width: 41, height: 60 }}
                    source={require("../assets/images/step2.png")}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>
                    Affiliate Feature for Websites
                  </Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    One unique aspect of LinksApp is its affiliate feature for
                    websites. Beyond simplifying link sharing, LinksApp allows
                    you to monetize your influence. If you're an affiliate
                    marketer or have products you recommend, you can incorporate
                    affiliate links into your LinksApp. When visitors click on
                    these links and make purchases, you earn commissions,
                    turning your influence into income seamlessly.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.featureBlock}>
              <View style={styles.iconMainContainer}>
                <View style={styles.stepContainer}>
                  <Image
                    style={{ width: 41, height: 60 }}
                    source={require("../assets/images/step3.png")}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>Store Management</Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    The store feature in LinksApp enables users to create and
                    manage their own online stores directly within the app. With
                    this feature, users can effortlessly set up their store, add
                    products, manage inventory, and track orders—all from one
                    convenient platform. Whether you're selling physical goods,
                    digital products, or services, our intuitive store feature
                    provides everything you need to establish and grow your
                    online business.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={stylesSM.featuresContainer}>
        <View style={stylesSM.featureHeaderContainer}>
          <Text style={stylesSM.headerTextBlack}>How it Works?</Text>
        </View>
        <View style={stylesSM.featureTextContainer}>
          <Text style={stylesSM.featuresText}>
            LinksApp provides a comprehensive solution for managing, sharing,
            and even monetizing your online content and recommendations.
          </Text>
        </View>
        <View style={stylesSM.featureBlockContainer}>
          <View style={stylesSM.howRightBlock}>
            <View>
              <Animated.Image
                style={[{ width: 300, height: 533 }, reanimatedStyle]}
                source={require("../assets/images/how-work.png")}
              />
            </View>
          </View>
          <View style={stylesSM.howLeftBlock}>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <Image
                  style={{ width: 41, height: 60 }}
                  source={require("../assets/images/step1.png")}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>
                    Centralized Link Hub
                  </Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    LinksApp simplifies your online presence by creating a
                    central hub for all your important links. After signing up,
                    you can add links to your social media profiles, website,
                    blog, online store, and more in one place. This hub becomes
                    your go-to destination for sharing multiple links
                    seamlessly.
                  </Text>
                </View>
              </View>
            </View>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <Image
                  style={{ width: 41, height: 60 }}
                  source={require("../assets/images/step2.png")}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>
                    Affiliate Feature for Websites
                  </Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    One unique aspect of LinksApp is its affiliate feature for
                    websites. Beyond simplifying link sharing, LinksApp allows
                    you to monetize your influence. If you're an affiliate
                    marketer or have products you recommend, you can incorporate
                    affiliate links into your LinksApp. When visitors click on
                    these links and make purchases, you earn commissions,
                    turning your influence into income seamlessly.
                  </Text>
                </View>
              </View>
            </View>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <Image
                  style={{ width: 41, height: 60 }}
                  source={require("../assets/images/step3.png")}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>
                    Store Management
                  </Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    The store feature in LinksApp enables users to create and
                    manage their own online stores directly within the app. With
                    this feature, users can effortlessly set up their store, add
                    products, manage inventory, and track orders—all from one
                    convenient platform. Whether you're selling physical goods,
                    digital products, or services, our intuitive store feature
                    provides everything you need to establish and grow your
                    online business.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: Dimensions.get("window").width,
  },
  iconContainer: {
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.25,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.peach,
  },
  iconStyle: {
    justifyContent: "center",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    shadowColor: "#000",
  },
  logo: {
    width: 110,
    height: 45,
  },
  appLogo: {
    width: 150,
    height: 45,
    marginVertical: 5,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.peach,
  },
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    height: (Dimensions.get("window").height / 10) * 8,
    //paddingVertical: 70,
    //flexDirection: "row",
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mainBannerRightCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 35,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 14,
    color: colors.white,
  },
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 50,
    //backgroundColor: colors.white
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 30,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 5,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: colors.red,
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleBlock: {
    width: (Dimensions.get("window").width / 10) * 3.5,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
  },
  rightBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "center",
    alignItems: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 4.5,
    height: (Dimensions.get("window").height / 10) * 1.75,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  iconConatiner: {
    width: 50,
    height: 50,
    backgroundColor: colors.lightorange,
    padding: 5,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  stepContainer: {
    width: 30,
    height: 64,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 3.5,
  },
  howLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 5,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  howRightBlock: {
    width: (Dimensions.get("window").width / 10) * 5,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 8.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  faqLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 4,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "center",
    alignItems: "center",
  },
  faqRightBlock: {
    width: (Dimensions.get("window").width / 10) * 4,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  screenShotsContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    //height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    width: 100,
    height: 100,
    backgroundColor: colors.red,
  },
  carouselContainer: {
    marginBottom: 20,
    //backgroundColor: 'blue'
  },
  plansButtonContainer: {},
  buttonContainer: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: 50,
    borderColor: colors.peach,
    borderWidth: 1,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
  },
  planBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    //height: (Dimensions.get("window").height / 10) * 1.75,
    //paddingVertical: 50,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    borderRadius: 10,
    overflow: "hidden",
    //backgroundColor: colors.peach,
  },
  collapsibleMainContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    marginVertical: 10,
  },
});

const stylesSM = StyleSheet.create({
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 20,
    //backgroundColor: colors.white
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 25,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: colors.red,
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    //height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 9,
    //height: (Dimensions.get("window").height / 10) * 1.75,
    //backgroundColor:colors.black,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    marginVertical: 10,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 7,
  },
  howLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 9,
    //height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  howRightBlock: {
    width: (Dimensions.get("window").width / 10) * 9,
    //height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
  },
});

export default HowSection;
