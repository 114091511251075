import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import colors from "../constants/theme/colors";
import { useFonts } from "expo-font";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";

const PlanSection = (props) => {
  const [monthlyBackColor, setMonthlyBackColor] = useState(colors.peach);
  const [yearlyBackColor, setYearlyBackColor] = useState(colors.white);
  const [monthlyTextColor, setMonthlyTextColor] = useState(colors.white);
  const [yearlyTextColor, setYearlyTextColor] = useState(colors.black);
  const [planType, setPlanType] = useState("monthly");
  const deviceSize = useDeviceSize();

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }

  const planHenadler = (props) => {
    setPlanType(props);
    if (props === "monthly") {
      setMonthlyBackColor(colors.peach);
      setMonthlyTextColor(colors.white);
      setYearlyBackColor(colors.white);
      setYearlyTextColor(colors.black);
    }
    if (props === "yearly") {
      setYearlyBackColor(colors.peach);
      setYearlyTextColor(colors.white);
      setMonthlyBackColor(colors.white);
      setMonthlyTextColor(colors.black);
    }
  };

  if (deviceSize === "extralarge") {
    return (
      <View style={styles.featuresContainer}>
        <View style={styles.featureHeaderContainer}>
          <Text style={styles.headerTextBlack}>Plans & Pricing</Text>
        </View>
        <View style={styles.featureTextContainer}>
          <Text style={styles.featuresText}>
            At LinksApp, we believe in providing flexible plans that empower you
            to present your online presence with finesse. Whether you're just
            starting or looking to level up, our plans and pricing ensure you
            have the tools you need to succeed. Choose your LinksApp journey
            today and elevate the way you connect, share, and engage online.
            Your links, your rules.
          </Text>
        </View>
        <View style={styles.plansButtonContainer}>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={() => planHenadler("monthly")}
              activeOpacity={0.7}
              style={{
                width: (Dimensions.get("window").width / 10) * 1,
                height: 50,
                backgroundColor: monthlyBackColor,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "MontserratSemiBold",
                  fontSize: 13,
                  color: monthlyTextColor,
                }}
              >
                Monthly
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={() => planHenadler("yearly")}
              style={{
                width: (Dimensions.get("window").width / 10) * 1,
                backgroundColor: yearlyBackColor,
                justifyContent: "center",
                alignItems: "center",
                height: 50,
              }}
            >
              <Text
                style={{
                  fontFamily: "MontserratSemiBold",
                  fontSize: 13,
                  color: yearlyTextColor,
                }}
              >
                Yearly
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.planContainer}>
          <View style={styles.planBlock}>
            <View style={styles.planHeader}>
              <Text style={styles.planHeaderText}>Free</Text>
            </View>
            <View style={styles.planMainContainer}>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Upto 10 Links</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Upto 10 Products</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Social Links</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>SEO</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>1 Free Theme</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.planBlock}>
            <View style={styles.planHeader}>
              <Text style={styles.planHeaderText}>
                Starter (₹{planType === "monthly" ? 49 : 45} / month)
              </Text>
            </View>
            <View style={styles.planMainContainer}>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Upto 20 Links</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Upto 20 Products</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Social Links</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>SEO</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>10+ Free Themes</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.planBlock}>
            <View style={styles.planHeader}>
              <Text style={styles.planHeaderText}>
                Pro (₹{planType === "monthly" ? 99 : 89} / month)
              </Text>
            </View>
            <View style={styles.planMainContainer}>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Upto 50 Links</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Upto 50 Products</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Social Links</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>SEO</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>20+ Free Themes</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.planBlock}>
            <View style={styles.planHeader}>
              <Text style={styles.planHeaderText}>
                Premium (₹{planType === "monthly" ? 149 : 119} / month)
              </Text>
            </View>
            <View style={styles.planMainContainer}>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Unlimited Links</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Unlimited Products</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>SEO</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>30+ Free Themes</Text>
                </View>
              </View>
              <View style={styles.planDetailsContainer}>
                <View style={styles.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={styles.planDetailContainer}>
                  <Text style={styles.planText}>Animated Backgrounds</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={stylesSM.featuresContainer}>
        <View style={stylesSM.featureHeaderContainer}>
          <Text style={stylesSM.headerTextBlack}>Plans & Pricing</Text>
        </View>
        <View style={stylesSM.featureTextContainer}>
          <Text style={stylesSM.featuresText}>
            At LinksApp, we believe in providing flexible plans that empower you
            to present your online presence with finesse. Whether you're just
            starting or looking to level up, our plans and pricing ensure you
            have the tools you need to succeed. Choose your LinksApp journey
            today and elevate the way you connect, share, and engage online.
            Your links, your rules.
          </Text>
        </View>
        <View style={stylesSM.plansButtonContainer}>
          <View style={stylesSM.buttonContainer}>
            <TouchableOpacity
              onPress={() => planHenadler("monthly")}
              activeOpacity={0.7}
              style={{
                width: (Dimensions.get("window").width / 10) * 2.5,
                height: 50,
                backgroundColor: monthlyBackColor,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "MontserratSemiBold",
                  fontSize: 13,
                  color: monthlyTextColor,
                }}
              >
                Monthly
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={() => planHenadler("yearly")}
              style={{
                width: (Dimensions.get("window").width / 10) * 2.5,
                backgroundColor: yearlyBackColor,
                justifyContent: "center",
                alignItems: "center",
                height: 50,
              }}
            >
              <Text
                style={{
                  fontFamily: "MontserratSemiBold",
                  fontSize: 13,
                  color: yearlyTextColor,
                }}
              >
                Yearly
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={stylesSM.planContainer}>
          <View style={stylesSM.planBlock}>
            <View style={stylesSM.planHeader}>
              <Text style={stylesSM.planHeaderText}>Free</Text>
            </View>
            <View style={stylesSM.planMainContainer}>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Upto 10 Links</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Upto 10 Products</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Social Links</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>SEO</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>1 Free Theme</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={stylesSM.planBlock}>
            <View style={stylesSM.planHeader}>
              <Text style={stylesSM.planHeaderText}>
                Starter (₹{planType === "monthly" ? 49 : 45} / month)
              </Text>
            </View>
            <View style={stylesSM.planMainContainer}>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Upto 20 Links</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Upto 20 Products</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Social Links</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>SEO</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>10+ Free Themes</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={stylesSM.planBlock}>
            <View style={stylesSM.planHeader}>
              <Text style={stylesSM.planHeaderText}>
                Pro (₹{planType === "monthly" ? 99 : 89} / month)
              </Text>
            </View>
            <View style={stylesSM.planMainContainer}>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Upto 50 Links</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Upto 50 Products</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Social Links</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>SEO</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>20+ Free Themes</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={stylesSM.planBlock}>
            <View style={stylesSM.planHeader}>
              <Text style={stylesSM.planHeaderText}>
                Premium (₹{planType === "monthly" ? 149 : 119} / month)
              </Text>
            </View>
            <View style={stylesSM.planMainContainer}>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Unlimited Links</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Unlimited Products</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>SEO</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>30+ Free Themes</Text>
                </View>
              </View>
              <View style={stylesSM.planDetailsContainer}>
                <View style={stylesSM.planIconContainer}>
                  <MaterialCommunityIcons
                    name="check"
                    color={colors.peach}
                    size={20}
                  />
                </View>
                <View style={stylesSM.planDetailContainer}>
                  <Text style={stylesSM.planText}>Animated Backgrounds</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
};

export default PlanSection;

const styles = StyleSheet.create({
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 50,
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 30,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 5,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 14,
    color: colors.textcolor,
    textAlign: "center",
  },
  buttonContainer: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: 50,
    borderColor: colors.peach,
    borderWidth: 1,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
  },
  planBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    borderRadius: 10,
    overflow: "hidden",
  },
  plansButtonContainer: {},
  planContainer: {
    flexDirection: "row",
    width: (Dimensions.get("window").width / 10) * 9,
    justifyContent: "space-around",
    marginVertical: 50,
  },
  planHeader: {
    backgroundColor: colors.peach,
    padding: 20,
    width: (Dimensions.get("window").width / 10) * 2,
    justifyContent: "center",
    alignItems: "center",
  },
  planHeaderText: {
    color: colors.white,
    fontFamily: "MontserratSemiBold",
    fontSize: 15,
  },
  planMainContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    width: (Dimensions.get("window").width / 10) * 2,
  },
  planDetailsContainer: {
    paddingVertical: 7,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: (Dimensions.get("window").width / 10) * 2,
  },
  planIconContainer: {
    backgroundColor: colors.lightorange,
    padding: 4,
    borderRadius: 50,
  },
  planDetailContainer: {
    justifyContent: "center",
    width: (Dimensions.get("window").width / 10) * 1.5,
  },
  planText: {
    fontFamily: "MontserratRegular",
    marginLeft: 10,
  },
});

const stylesSM = StyleSheet.create({
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 50,
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 25,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 9,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    textAlign: "center",
  },
  buttonContainer: {
    width: (Dimensions.get("window").width / 10) * 5,
    height: 50,
    borderColor: colors.peach,
    borderWidth: 1,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
  },
  planBlock: {
    width: (Dimensions.get("window").width / 10) * 8,
    marginVertical: 20,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    borderRadius: 10,
    overflow: "hidden",
  },
  plansButtonContainer: {},
  planContainer: {
    //flexDirection: "row",
    width: (Dimensions.get("window").width / 10) * 8,
    justifyContent: "space-around",
    marginVertical: 30,
  },
  planHeader: {
    backgroundColor: colors.peach,
    padding: 20,
    width: (Dimensions.get("window").width / 10) * 8,
    justifyContent: "center",
    alignItems: "center",
  },
  planHeaderText: {
    color: colors.white,
    fontFamily: "MontserratSemiBold",
    fontSize: 15,
  },
  planMainContainer: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    width: (Dimensions.get("window").width / 10) * 7,
    //backgroundColor: colors.black
  },
  planDetailsContainer: {
    paddingVertical: 7,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: (Dimensions.get("window").width / 10) * 7,
  },
  planIconContainer: {
    backgroundColor: colors.lightorange,
    padding: 4,
    borderRadius: 50,
    //width: (Dimensions.get("window").width / 10) * 1,
  },
  planDetailContainer: {
    justifyContent: "center",
    width: (Dimensions.get("window").width / 10) * 6,
  },
  planText: {
    fontFamily: "MontserratRegular",
    marginLeft: 10,
  },
});
