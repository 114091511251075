import * as React from "react";
import { View, Text, StyleSheet, Platform, Dimensions } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import {
  getFocusedRouteNameFromRoute,
  useTheme,
} from "@react-navigation/native";

import ProductDetailsScreen from "../../screens/ProductDetailsScreen";
import ProfileTab from "../ProfileTabNavigation";
import StoreProductsScreen from "../../screens/StoreProductsScreen";
import TestScreen from "../../screens/TestScreen";
import StoreCheckoutScreen from "../../screens/StoreCheckoutScreen";

const StoreStack = (props) => {
  const Stack = createStackNavigator();

  // React.useLayoutEffect(() => {
  //   const routeName = getFocusedRouteNameFromRoute(props.route);
  //   if (
  //     routeName === 'details'
  //   ) {
  //     props.navigation.setOptions({tabBarStyle: {display: 'none'}});
  //   } else {
  //     props.navigation.setOptions({
  //       tabBarStyle: {
  //         position: 'absolute',
  //         borderTopWidth: 0,
  //         borderRadius: Platform.OS === 'android' ? 100 : 0,
  //         backgroundColor: colors.black,
  //         marginHorizontal:
  //           Platform.OS === 'android'
  //             ? (Dimensions.get('window').width / 10) * 0.1
  //             : 0,
  //         marginBottom: Platform.OS === 'android' ? 10 : 0,
  //         shadowColor: colors.red,
  //         shadowOffset: {
  //           width: 0,
  //           height: 2,
  //         },
  //         shadowOpacity: 0.25,
  //         shadowRadius: 3.84,
  //         elevation: 5,
  //         paddingTop: 5,
  //         //height: 60,
  //       },
  //     });
  //   }
  // }, [props.navigation, props.route]);

  const { colors } = useTheme();
  colors.background = "transparent";

  return (
    <Stack.Navigator
      initialRouteName="all"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        initialParams={{ id: props.route.params.id }}
        name="all"
        component={StoreProductsScreen}
      />
      <Stack.Screen name="details" initialParams={{ item: props.route.params.item }} component={ProductDetailsScreen} />
      <Stack.Screen name="checkout" component={StoreCheckoutScreen} />
    </Stack.Navigator>
  );
};

export default StoreStack;
