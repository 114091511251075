import React from "react";
import { View, Text, StyleSheet, Dimensions, ScrollView } from "react-native";
import colors from "../constants/theme/colors";
import MainHeader from "../components/mainHeader";
import PageBanner from "../components/pageBanner";
import FooterSection from "../components/footerSection";
import PlanSection from "../components/plansSection";
import CopyrightSection from "../components/copyrightSection";
import { pageContent } from "../constants/pages/terms";
import WhatsAppChat from "../components/whatsAppChat";

const TermsScreen = (props) => {
  return (
    <View style={styles.container}>
      <MainHeader props={props} />
      <ScrollView>
        <PageBanner pageTitle='Terms & Conditions' pageContent={pageContent} />
        <FooterSection props={props} />
        <CopyrightSection />
      </ScrollView>
      <WhatsAppChat />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: Dimensions.get("window").width,
  },
  scrollView: {
    width: (Dimensions.get("window").width / 10) * 10,
  },
});

export default TermsScreen;
