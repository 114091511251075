import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Dimensions,
  Image,
  ScrollView,
  Button,
  useWindowDimensions,
} from "react-native";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import colors from "../constants/theme/colors";
import { useFonts } from "expo-font";
import LottieView from "react-native-web-lottie";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withTiming,
  withRepeat,
} from "react-native-reanimated";

const FeatureSection = (props) => {
  const deviceSize = useDeviceSize();
  const progress = useSharedValue(1);

  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: progress.value }],
    };
  }, []);

  useEffect(() => {
    progress.value = withRepeat(withTiming(10, { duration: 1000 }), -1, true);
  }, []);

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }
  if (deviceSize === "extralarge") {
    return (
      <View style={styles.featuresContainer}>
        <View style={styles.featureHeaderContainer}>
          <Text style={styles.headerTextBlack}>Core Features</Text>
        </View>
        <View style={styles.featureTextContainer}>
          <Text style={styles.featuresText}>
            LinksApp offers a seamless way to optimize your online presence. Our
            platform centralizes your digital links, simplifying navigation for
            your audience. With sleek customization options, you can personalize
            your link hub to align with your brand's identity
          </Text>
        </View>
        <View style={styles.featureBlockContainer}>
          <View style={styles.leftBlock}>
            <View style={styles.featureBlock}>
              <View style={styles.iconMainContainer}>
                <View style={styles.iconConatiner}>
                  <MaterialCommunityIcons
                    name="link-variant"
                    color={colors.peach}
                    size={30}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>
                    Effortless Link Management
                  </Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    LinksApp lets you consolidate all your important links in
                    one place. No more limitations, no more confusion – one hub
                    to rule them all.
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.featureBlock}>
              <View style={styles.iconMainContainer}>
                <View style={styles.iconConatiner}>
                  <MaterialCommunityIcons
                    name="link-variant"
                    color={colors.peach}
                    size={30}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>Online Store</Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    Effortlessly set up your online store and manage products
                    and orders within the app
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.featureBlock}>
              <View style={styles.iconMainContainer}>
                <View style={styles.iconConatiner}>
                  <MaterialCommunityIcons
                    name="link-variant"
                    color={colors.peach}
                    size={30}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>
                    Indian Innovation
                  </Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    Developed in India, LinksApp is a testament to the
                    technological prowess of the country, delivering a
                    world-class application that meets global standards
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.middleBlock}>
            <View>
              <Animated.Image
                style={[{ width: 250, height: 500 }, reanimatedStyle]}
                source={require("../assets/images/linksapp-demo.png")}
              />
            </View>
          </View>
          <View style={styles.leftBlock}>
            <View style={styles.featureBlock}>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 0.5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: colors.lightorange,
                    padding: 5,
                    borderRadius: 50,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name="link-variant"
                    color={colors.peach}
                    size={30}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>
                    Customization at Your Fingertips
                  </Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    Tailor your LinksApp profile to reflect your style and
                    personal brand. Choose from various themes and layouts to
                    make it uniquely yours
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.featureBlock}>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 0.5,

                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: colors.lightorange,
                    padding: 5,
                    borderRadius: 50,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name="link-variant"
                    color={colors.peach}
                    size={30}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>Seamless Sharing</Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    Our user-friendly interface makes sharing your LinksApp
                    profile a breeze. From social media profiles to your website
                    and beyond, your audience can access all your links in a
                    single click
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.featureBlock}>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 0.5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: 50,
                    height: 50,
                    backgroundColor: colors.lightorange,
                    padding: 5,
                    borderRadius: 50,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MaterialCommunityIcons
                    name="link-variant"
                    color={colors.peach}
                    size={30}
                  />
                </View>
              </View>
              <View style={styles.featuresTextContainer}>
                <View>
                  <Text style={styles.featureHeaderText}>
                    Monetize Your Influence
                  </Text>
                </View>
                <View>
                  <Text style={styles.featureText}>
                    Take it a step further with our product affiliate feature.
                    Not only can you share your content, but you can also turn
                    your recommendations into revenue
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={stylesSM.featuresContainer}>
        <View style={stylesSM.featureHeaderContainer}>
          <Text style={stylesSM.headerTextBlack}>Core Features</Text>
        </View>
        <View style={stylesSM.featureTextContainer}>
          <Text style={stylesSM.featuresText}>
            LinksApp offers a seamless way to optimize your online presence. Our
            platform centralizes your digital links, simplifying navigation for
            your audience. With sleek customization options, you can personalize
            your link hub to align with your brand's identity
          </Text>
        </View>
        <View style={stylesSM.featureBlockContainer}>
          <View style={stylesSM.leftBlock}>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <MaterialCommunityIcons
                  name="link-variant"
                  color={colors.peach}
                  size={30}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>
                    Effortless Link Management
                  </Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    LinksApp lets you consolidate all your important links in
                    one place. No more limitations, no more confusion – one hub
                    to rule them all.
                  </Text>
                </View>
              </View>
            </View>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <MaterialCommunityIcons
                  name="link-variant"
                  color={colors.peach}
                  size={30}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>Online Store</Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    Effortlessly set up your online store and manage products
                    and orders within the app
                  </Text>
                </View>
              </View>
            </View>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <MaterialCommunityIcons
                  name="link-variant"
                  color={colors.peach}
                  size={30}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>
                    Indian Innovation
                  </Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    Developed in India, LinksApp is a testament to the
                    technological prowess of the country, delivering a
                    world-class application that meets global standards
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={stylesSM.middleBlock}>
            <View>
              <Animated.Image
                style={[{ width: 250, height: 500 }, reanimatedStyle]}
                source={require("../assets/images/linksapp-demo.png")}
              />
            </View>
          </View>
          <View style={stylesSM.leftBlock}>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <MaterialCommunityIcons
                  name="link-variant"
                  color={colors.peach}
                  size={30}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>
                    Customization at Your Fingertips
                  </Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    Tailor your LinksApp profile to reflect your style and
                    personal brand. Choose from various themes and layouts to
                    make it uniquely yours
                  </Text>
                </View>
              </View>
            </View>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <MaterialCommunityIcons
                  name="link-variant"
                  color={colors.peach}
                  size={30}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>
                    Seamless Sharing
                  </Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    Our user-friendly interface makes sharing your LinksApp
                    profile a breeze. From social media profiles to your website
                    and beyond, your audience can access all your links in a
                    single click
                  </Text>
                </View>
              </View>
            </View>
            <View style={stylesSM.featureBlock}>
              <View style={stylesSM.iconConatiner}>
                <MaterialCommunityIcons
                  name="link-variant"
                  color={colors.peach}
                  size={30}
                />
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 7,
                  padding: 15,
                }}
              >
                <View>
                  <Text style={stylesSM.featureHeaderText}>
                    Monetize Your Influence
                  </Text>
                </View>
                <View>
                  <Text style={stylesSM.featureText}>
                    Take it a step further with our product affiliate feature.
                    Not only can you share your content, but you can also turn
                    your recommendations into revenue
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 50,
    backgroundColor: colors.white,
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 30,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 5,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: colors.red,
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    width: (Dimensions.get("window").width / 10) * 2.5,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleBlock: {
    width: (Dimensions.get("window").width / 10) * 3.5,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
  },
  rightBlock: {
    width: (Dimensions.get("window").width / 10) * 2.5,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "center",
    alignItems: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 2.5,
    height: (Dimensions.get("window").height / 10) * 1.75,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratSemiBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    marginTop: 2,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  iconConatiner: {
    width: 50,
    height: 50,
    backgroundColor: colors.lightorange,
    padding: 5,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  stepContainer: {
    width: 30,
    height: 64,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 1.75,
  },
});

const stylesSM = StyleSheet.create({
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 50,
    //backgroundColor: colors.white
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 25,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 8.5,
    //height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 20,
    //flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    width: (Dimensions.get("window").width / 10) * 8,
    //height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleBlock: {
    width: (Dimensions.get("window").width / 10) * 7,
    //height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
  },
  rightBlock: {
    width: (Dimensions.get("window").width / 10) * 7,
    //height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "center",
    alignItems: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 9,
    //height: (Dimensions.get("window").height / 10) * 1.75,
    marginVertical: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    marginTop: 2,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  iconConatiner: {
    width: 50,
    height: 50,
    backgroundColor: colors.lightorange,
    padding: 5,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  stepContainer: {
    width: 30,
    height: 64,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 1.5,
  },
});

export default FeatureSection;
