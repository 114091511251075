import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Dimensions,
  Image,
  ScrollView,
  Button,
  useWindowDimensions,
} from "react-native";
import colors from "../constants/theme/colors";
import { useFonts } from "expo-font";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withTiming,
  withRepeat,
} from "react-native-reanimated";
import CustomImageCarousal from "../components/carouselSquare";

const ScreenshotSection = (props) => {
  const deviceSize = useDeviceSize();
  const progress = useSharedValue(1);

  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: progress.value }],
    };
  }, []);

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }

  const data = [
    {
      image: require("../assets/images/1.jpg"),
    },
    {
      image: require("../assets/images/2.jpg"),
    },
    {
      image: require("../assets/images/3.jpg"),
    },
    {
      image: require("../assets/images/4.jpg"),
    },
    {
      image: require("../assets/images/9.png"),
    },
    {
      image: require("../assets/images/10.png"),
    },
    {
      image: require("../assets/images/7.jpg"),
    },
    {
      image: require("../assets/images/8.jpg"),
    },
    {
      image: require("../assets/images/5.jpg"),
    },
    {
      image: require("../assets/images/6.jpg"),
    },
  ];

  if (deviceSize === "extralarge") {
    return (
      <View>
        <View style={styles.featuresContainer}>
          <View style={styles.featureHeaderContainer}>
            <Text style={styles.headerTextBlack}>App Screenshots</Text>
          </View>
          <View style={styles.featureTextContainer}>
            <Text style={styles.featuresText}>
              Welcome to the streamlined world of LinksApp, where simplicity
              meets functionality. Our application interface is designed with
              you in mind, ensuring a smooth and intuitive experience from the
              moment you log in. Here's a glimpse of what awaits you.
            </Text>
          </View>
        </View>
        <CustomImageCarousal data={data} autoPlay={true} pagination={true} />
      </View>
    );
  } else {
    return (
      <View>
        <View style={stylesSM.featuresContainer}>
          <View style={stylesSM.featureHeaderContainer}>
            <Text style={stylesSM.headerTextBlack}>App Screenshots</Text>
          </View>
          <View style={stylesSM.featureTextContainer}>
            <Text style={stylesSM.featuresText}>
              Welcome to the streamlined world of LinksApp, where simplicity
              meets functionality. Our application interface is designed with
              you in mind, ensuring a smooth and intuitive experience from the
              moment you log in. Here's a glimpse of what awaits you.
            </Text>
          </View>
        </View>
        <CustomImageCarousal data={data} autoPlay={true} pagination={true} />
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: Dimensions.get("window").width,
  },
  iconContainer: {
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.25,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.peach,
  },
  iconStyle: {
    justifyContent: "center",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    shadowColor: "#000",
  },
  logo: {
    width: 110,
    height: 45,
  },
  appLogo: {
    width: 150,
    height: 45,
    marginVertical: 5,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.peach,
  },
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    height: (Dimensions.get("window").height / 10) * 8,
    //paddingVertical: 70,
    //flexDirection: "row",
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mainBannerRightCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 35,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 14,
    color: colors.white,
  },
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 20,
    //backgroundColor: colors.white
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 30,
    color: colors.black,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 5,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: colors.red,
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 14,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleBlock: {
    width: (Dimensions.get("window").width / 10) * 3.5,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
  },
  rightBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "center",
    alignItems: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 2.5,
    height: (Dimensions.get("window").height / 10) * 1.75,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 12,
    color: colors.textcolor,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  iconConatiner: {
    width: 50,
    height: 50,
    backgroundColor: colors.lightorange,
    padding: 5,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  stepContainer: {
    width: 30,
    height: 64,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 1.5,
  },
  howLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 3,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  howRightBlock: {
    width: (Dimensions.get("window").width / 10) * 3,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 8.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  faqLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 4,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "center",
    alignItems: "center",
  },
  faqRightBlock: {
    width: (Dimensions.get("window").width / 10) * 4,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  screenShotsContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    //height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    width: 100,
    height: 100,
    backgroundColor: colors.red,
  },
  carouselContainer: {
    marginBottom: 20,
    //backgroundColor: 'blue'
  },
  plansButtonContainer: {},
  buttonContainer: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: 50,
    borderColor: colors.peach,
    borderWidth: 1,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
  },
  planBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    //height: (Dimensions.get("window").height / 10) * 1.75,
    //paddingVertical: 50,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    borderRadius: 10,
    overflow: "hidden",
    //backgroundColor: colors.peach,
  },
  collapsibleMainContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    marginVertical: 10,
  },
  featureText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: colors.grey,
  },
});

const stylesSM = StyleSheet.create({
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 20,
    //backgroundColor: colors.white
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 25,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: colors.red,
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    //height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 8,
    height: (Dimensions.get("window").height / 10) * 1.75,
    //backgroundColor:colors.black,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 12,
    color: colors.textcolor,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 7,
  },
  howLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 7,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  howRightBlock: {
    width: (Dimensions.get("window").width / 10) * 7,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  featureText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: colors.grey,
  },
});

export default ScreenshotSection;
