import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Dimensions,
  ActivityIndicator,
  FlatList,
  TouchableOpacity,
  ScrollView,
  Linking,
} from "react-native";
import SwipeableItem, {
  useSwipeableItemParams,
} from "react-native-swipeable-item";
import {
  EXPO_PUBLIC_API_URL,
  EXPO_PUBLIC_API_KEY,
  EXPO_PUBLIC_BASE_URL,
} from "@env";
import colors from "../constants/theme/colors";
import Collapsible from "react-native-collapsible";
import MaterialIcons from "@expo/vector-icons//MaterialIcons";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import Ionicons from "@expo/vector-icons//Ionicons";
const OVERSWIPE_DIST = 20;
import LottieView from "react-native-web-lottie";
import { Card, Button } from "ui-neumorphism";
import { overrideThemeVariables } from "ui-neumorphism";
import "ui-neumorphism/dist/index.css";
import CustomCard from "../components/Card";
let stopFetchMore = true;
import { Helmet } from "react-helmet";
import SEOSection from "../components/SEOSection";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";

const ProductsScreen = (props) => {
  const [userProfileDetails, setUserProfileDetails] = useState([]);
  const [courierFolderPath, setCourierFolderPath] = useState();
  const [userFolderPath, setUserFolderPath] = useState();
  const [linkHistory, setLinkHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const linkRef = useRef([]);
  const [endList, setEndList] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [allColor, setAllColor] = useState(colors.peach);
  const [fashionColor, setFashionColor] = useState(colors.lightpeach);
  const [electronicsColor, setElectronicsColor] = useState(colors.lightpeach);
  const [booksColor, setBooksColor] = useState(colors.lightpeach);
  const [gadgetsColor, setGadgetsColor] = useState(colors.lightpeach);
  const [othersColor, setOthersColor] = useState(colors.lightpeach);
  const [laptopsColor, setLaptopsColor] = useState(colors.lightpeach);
  const [phonesColor, setPhonesColor] = useState(colors.lightpeach);
  const user_id = props.route.params.id;
  const styles = useStyles();
  const deviceSize = useDeviceSize();

  useEffect(() => {
    const fetchUserDetails = async (props) => {
      const userDetails = await getuserDetails(page, selectedCategory);
      //console.log(userDetails);
      if (userDetails.success) {
        setUserProfileDetails(userDetails.userDetails);
        setLinkHistory(userDetails.products);
        setCourierFolderPath(userDetails.productFolderPath);
        setUserFolderPath(userDetails.userFolderPath);
      } else {
        props.navigation.navigate("Drawer");
      }
    };
    fetchUserDetails();
    setInterval(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const getuserDetails = async (page, category) => {
    //console.log(selectedCategory);
    try {
      const apiResult = await fetch(
        `${EXPO_PUBLIC_API_URL}users/getProfileDetails?id=${user_id}&page=${page}&limit=10&category=${category}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "ps-key": EXPO_PUBLIC_API_KEY,
          },
        }
      );
      const resData = await apiResult.json();
      //console.log(resData);
      return resData;
    } catch (error) {
      //console.log(error);
    }
  };

  const handleOnEndReached = async () => {
    setPage(page + 1);
    const newHistory = await getuserDetails(page + 1, selectedCategory);
    if (newHistory) {
      setLinkHistory([...linkHistory, ...newHistory.links]);
    }
  };

  const openLinkHandler = async (props) => {
    await Linking.openURL(props);
  };

  const renderItem = ({ item }) => (
    <View style={styles.linksContainer}>
      {userProfileDetails.meta_title ? (
        <SEOSection
          title={userProfileDetails.meta_title}
          description={
            userProfileDetails.meta_description
              ? userProfileDetails.meta_description
              : userProfileDetails.profile_bio
          }
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
      ) : (
        <SEOSection
          title={
            userProfileDetails.firstname + " " + userProfileDetails.lastname
          }
          description={userProfileDetails.profile_bio}
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
      )}
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={async () => {
          await openLinkHandler(item.product_url);
        }}
        style={styles.productsBodyContainer}
      >
        <View style={styles.productContainer}>
          <View style={styles.productImageContainer}>
            <Image
              style={styles.logo}
              //source={require('../../assets/images/product-1.jpg')}
              source={{
                uri:
                  EXPO_PUBLIC_BASE_URL + courierFolderPath + item.product_image,
              }}
            />
          </View>
          <View style={styles.productDetailsContainer}>
            <View>
              <View>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.productTitleText}
                >
                  {item.product_title}
                </Text>
              </View>
              <View>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.productBrandText}
                >
                  by {item.product_brand}
                </Text>
              </View>
              <View style={{ marginTop: 5 }}>
                <View>
                  <Text
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    style={styles.productDescriptionText}
                  >
                    {item.product_description}
                  </Text>
                </View>
                {item.product_coupon && (
                  <View style={styles.couponContainer}>
                    <View
                      style={{
                        backgroundColor: colors.lightpeach,
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 2,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          color: colors.white,
                          fontSize: 13,
                        }}
                      >
                        Code: {item.product_coupon}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </View>
            <View style={styles.productInfoContainer}>
              {item.product_selling_price ? (
                <View style={{ flexDirection: "row" }}>
                  <View style={{ marginLeft: 5, justifyContent: "center" }}>
                    <Text style={styles.spText}>
                      ₹{item.product_selling_price}
                    </Text>
                  </View>
                  <View style={{ marginLeft: 5, justifyContent: "center" }}>
                    <Text style={styles.mrpText}>₹{item.product_mrp}</Text>
                  </View>
                </View>
              ) : (
                <View style={{ flexDirection: "row" }}>
                  <View style={{ marginLeft: 5, justifyContent: "center" }}>
                    <Text style={styles.spText}>₹{item.product_mrp}</Text>
                  </View>
                </View>
              )}
              <View style={styles.buyButton}>
                <Text style={styles.buyText}>Buy Now</Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );

  const fetchCategoryProducts = async (props) => {
    if (props == "") {
      setSelectedCategory(props);
      setAllColor(colors.peach);
      setFashionColor(colors.lightpeach);
      setElectronicsColor(colors.lightpeach);
      setBooksColor(colors.lightpeach);
      setGadgetsColor(colors.lightpeach);
      setOthersColor(colors.lightpeach);
      setLaptopsColor(colors.lightpeach);
      setPhonesColor(colors.lightpeach);
    }
    if (props == "Fashion") {
      setSelectedCategory(props);
      setAllColor(colors.lightpeach);
      setFashionColor(colors.peach);
      setElectronicsColor(colors.lightpeach);
      setBooksColor(colors.lightpeach);
      setGadgetsColor(colors.lightpeach);
      setOthersColor(colors.lightpeach);
      setLaptopsColor(colors.lightpeach);
      setPhonesColor(colors.lightpeach);
    }
    if (props == "Electronics") {
      setSelectedCategory(props);
      setAllColor(colors.lightpeach);
      setElectronicsColor(colors.peach);
      setFashionColor(colors.lightpeach);
      setBooksColor(colors.lightpeach);
      setGadgetsColor(colors.lightpeach);
      setOthersColor(colors.lightpeach);
      setLaptopsColor(colors.lightpeach);
      setPhonesColor(colors.lightpeach);
    }
    if (props == "Books") {
      setSelectedCategory(props);
      setAllColor(colors.lightpeach);
      setBooksColor(colors.peach);
      setElectronicsColor(colors.lightpeach);
      setFashionColor(colors.lightpeach);
      setGadgetsColor(colors.lightpeach);
      setOthersColor(colors.lightpeach);
      setLaptopsColor(colors.lightpeach);
      setPhonesColor(colors.lightpeach);
    }
    if (props == "Gadgets") {
      setSelectedCategory(props);
      setAllColor(colors.lightpeach);
      setGadgetsColor(colors.peach);
      setElectronicsColor(colors.lightpeach);
      setBooksColor(colors.lightpeach);
      setFashionColor(colors.lightpeach);
      setOthersColor(colors.lightpeach);
      setLaptopsColor(colors.lightpeach);
      setPhonesColor(colors.lightpeach);
    }
    if (props == "Others") {
      setSelectedCategory(props);
      setAllColor(colors.lightpeach);
      setOthersColor(colors.peach);
      setElectronicsColor(colors.lightpeach);
      setBooksColor(colors.lightpeach);
      setGadgetsColor(colors.lightpeach);
      setFashionColor(colors.lightpeach);
      setLaptopsColor(colors.lightpeach);
      setPhonesColor(colors.lightpeach);
    }
    if (props == "Laptops") {
      setSelectedCategory(props);
      setAllColor(colors.lightpeach);
      setLaptopsColor(colors.peach);
      setElectronicsColor(colors.lightpeach);
      setBooksColor(colors.lightpeach);
      setGadgetsColor(colors.lightpeach);
      setOthersColor(colors.lightpeach);
      setFashionColor(colors.lightpeach);
      setPhonesColor(colors.lightpeach);
    }
    if (props == "Phones") {
      setSelectedCategory(props);
      setAllColor(colors.lightpeach);
      setPhonesColor(colors.peach);
      setElectronicsColor(colors.lightpeach);
      setBooksColor(colors.lightpeach);
      setGadgetsColor(colors.lightpeach);
      setOthersColor(colors.lightpeach);
      setLaptopsColor(colors.lightpeach);
      setFashionColor(colors.lightpeach);
    }

    setLoading(true);
    //setEndList(false);
    setPage(1);
    const getProducts = await getuserDetails(1, props);
    if (getProducts.products.length > 0) {
      setLinkHistory(getProducts.products);
      setLoading(false);
    } else {
      setLinkHistory([]);
      setLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      {loading || userProfileDetails == null ? (
        <View style={styles.activityContainer}>
          <ActivityIndicator size="large" color={colors.peach} />
        </View>
      ) : (
        <FlatList
          data={linkHistory}
          renderItem={renderItem}
          keyExtractor={(item) => item._id}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={
            <View style={{ paddingBottom: 70 }}>
              <View style={styles.endListContainer}>
                {endList ? (
                  <View style={styles.endListMain}></View>
                ) : (
                  <View>
                    {loadMore && (
                      <View style={styles.loadMoreContainer}>
                        <ActivityIndicator size={30} color={colors.peach} />
                      </View>
                    )}
                  </View>
                )}
              </View>
              {/* {linkHistory.length == 0 && (
                <View style={styles.lottieMainContainer}>
                  <View style={styles.lottieContainer}>
                    <LottieView
                      source={require("../assets/lottie/empty-link.json")}
                      autoPlay
                      loop
                    />
                  </View>
                  <View style={styles.emptyProductContainer}>
                    <Text style={styles.emptyText}>No Product Found!</Text>
                  </View>
                </View>
              )} */}
              <View style={styles.footerLogoContainer}>
                {userProfileDetails.profile_logo == false ||
                new Date(userProfileDetails.plan_validity) < new Date() ? (
                  <View style={styles.logoMainContainer}>
                    <View style={styles.footerPowerContainer}>
                      <Text style={styles.powerText}>Powered by</Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => {
                        props.navigation.navigate("Drawer", { screen: "Home" });
                      }}
                      style={styles.logoContainer}>
                      <Image
                        style={styles.applogo}
                        source={require("../assets/logo/links-app-logo-border.png")}
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <Text></Text>
                )}
              </View>
            </View>
          }
          onEndReachedThreshold={0.01}
          onEndReached={handleOnEndReached}
          ListHeaderComponent={
            <View style={{}}>
              <View style={styles.headerMainContainer}></View>
              <View style={styles.headerSubContainer}>
                <View style={styles.headerCategoryMainContainer}>
                  <View style={styles.headerCategoryContainer}>
                    <View style={styles.headerScrollViewContainer}>
                      <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        style={styles.categoryScrollView}
                      >
                        <View>
                          <Text>{"     "}</Text>
                        </View>
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={() => {
                            fetchCategoryProducts("");
                          }}
                          style={{
                            backgroundColor: allColor,
                            borderRadius: 20,
                            paddingHorizontal: 20,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.white,
                            }}
                          >
                            Show All
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={() => {
                            fetchCategoryProducts("Fashion");
                          }}
                          style={{
                            backgroundColor: fashionColor,
                            borderRadius: 20,
                            paddingHorizontal: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.white,
                            }}
                          >
                            Fashion
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={() => {
                            fetchCategoryProducts("Electronics");
                          }}
                          style={{
                            backgroundColor: electronicsColor,
                            borderRadius: 20,
                            paddingHorizontal: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.white,
                            }}
                          >
                            Electronics
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={() => {
                            fetchCategoryProducts("Books");
                          }}
                          style={{
                            backgroundColor: booksColor,
                            borderRadius: 20,
                            paddingHorizontal: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.white,
                            }}
                          >
                            Books
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={() => {
                            fetchCategoryProducts("Gadgets");
                          }}
                          style={{
                            backgroundColor: gadgetsColor,
                            borderRadius: 20,
                            paddingHorizontal: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.white,
                            }}
                          >
                            Gadgets
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={() => {
                            fetchCategoryProducts("Laptops");
                          }}
                          style={{
                            backgroundColor: laptopsColor,
                            borderRadius: 20,
                            paddingHorizontal: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.white,
                            }}
                          >
                            Laptops
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={() => {
                            fetchCategoryProducts("Phones");
                          }}
                          style={{
                            backgroundColor: phonesColor,
                            borderRadius: 20,
                            paddingHorizontal: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.white,
                            }}
                          >
                            Phones
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={() => {
                            fetchCategoryProducts("Others");
                          }}
                          style={{
                            backgroundColor: othersColor,
                            borderRadius: 20,
                            paddingHorizontal: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 3,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.white,
                            }}
                          >
                            Others
                          </Text>
                        </TouchableOpacity>
                      </ScrollView>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          }
        />
      )}
    </View>
  );
};

export default ProductsScreen;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: (Dimensions.get("window").width / 10) * 3.5,
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: (Dimensions.get("window").width / 10) * 1,
      height: (Dimensions.get("window").width / 10) * 1.33,
      borderRadius: (Dimensions.get("window").width / 10) * 0.05,
      overflow: "hidden",
    },
    linksContainer: {
      marginTop: 10,
      width: (Dimensions.get("window").width / 10) * 10,
      justifyContent: "center",
      alignItems: "center",
    },
    applogo: {
      width: 110,
      height: 45,
    },
    productsBodyContainer: {
      width: (Dimensions.get("window").width / 10) * 3.3,
      borderRadius: (Dimensions.get("window").width / 10) * 0.05,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 5,
      backgroundColor: colors.white,
    },
    productContainer: {
      width: (Dimensions.get("window").width / 10) * 3.3,
      overflow: "hidden",
      flexDirection: "row",
      justifyContent: "space-around",
      //alignItems: 'center',
      paddingVertical: 10,
      //backgroundColor: colors.orange,
      borderRadius: 10,
      shadowRadius: 10,
      shadowColor: colors.darkgrey,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.1,
    },
    productImageContainer: {
      width: (Dimensions.get("window").width / 10) * 1,
      backgroundColor: colors.lightorange,
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      borderRadius: (Dimensions.get("window").width / 10) * 0.05,
    },
    productDetailsContainer: {
      width: (Dimensions.get("window").width / 10) * 1.9,
      justifyContent: "space-between",
      //backgroundColor: colors.black,
    },
    productTitleText: {
      fontFamily: "MontserratSemiBold",
      color: colors.black,
      fontSize: 16,
    },
    productBrandText: {
      fontFamily: "MontserratRegular",
      color: colors.grey,
      fontSize: 14,
    },
    productDescriptionText: {
      fontFamily: "MontserratRegular",
      color: colors.black,
      fontSize: 13,
    },
    activityContainer: {
      width: (Dimensions.get("window").width / 10) * 4,
      alignItems: "center",
      justifyContent: "center",
    },
    endListContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    endListMain: {
      justifyContent: "center",
      alignItems: "center",
      height: (Dimensions.get("window").height / 10) * 0.5,
    },
    loadMoreContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    lottieMainContainer: {
      width: (Dimensions.get("window").width / 10) * 10,
    },
    lottieContainer: {
      width: (Dimensions.get("window").width / 10) * 10,
      height: (Dimensions.get("window").width / 10) * 3,
      opacity: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    emptyProductContainer: {
      width: (Dimensions.get("window").width / 10) * 10,
      opacity: 1,
      alignItems: "center",
    },
    emptyText: {
      fontFamily: "MontserratSemiBold",
      fontSize: 14,
      color: colors.black,
    },
    footerLogoContainer: {
      alignItems: "center",
      width: (Dimensions.get("window").width / 10) * 10,
    },
    logoMainContainer: {
      marginTop: 10,
      alignItems: "center",
      width: (Dimensions.get("window").width / 10) * 10,
    },
    footerPowerContainer: {
      alignItems: "center",
      width: (Dimensions.get("window").width / 10) * 10,
    },
    powerText: {
      fontFamily: "MontserratRegular",
      color: colors.black,
      fontSize: 14,
    },
    logoContainer: {
      width: (Dimensions.get("window").width / 10) * 10,
      alignItems: "center",
    },
    headerMainContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    headerSubContainer: {
      alignItems: "center",
      width: (Dimensions.get("window").width / 10) * 10,
    },
    headerCategoryMainContainer: {
      marginTop: 10,
      alignItems: "center",
      width: (Dimensions.get("window").width / 10) * 3,
    },
    headerCategoryContainer: {
      width: (Dimensions.get("window").width / 10) * 3,
      alignItems: "center",
    },
    headerScrollViewContainer: {
      height: (Dimensions.get("window").width / 10) * 0.25,
      width: (Dimensions.get("window").width / 10) * 3,
      marginVertical: 10,
      justifyContent: "center",
      alignItems: "center",
    },
    categoryScrollView: {
      flex: 1,
      width: (Dimensions.get("window").width / 10) * 3.5,
    },
    couponContainer: {
      marginTop: 5,
      //backgroundColor: colors.lightpeach,
      width: (Dimensions.get("window").width / 10) * 2,
      padding: 3,
      justifyContent: "center",
      alignItems: "flex-start",
      borderRadius: 5,
    },
    productInfoContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    spText: {
      fontFamily: "MontserratSemiBold",
      color: colors.black,
      fontSize: 17,
    },
    mrpText: {
      textDecorationLine: "line-through",
      textDecorationStyle: "solid",
      fontFamily: "MontserratRegular",
      color: colors.grey,
      fontSize: 14,
    },
    buyButton: {
      backgroundColor: colors.peach,
      borderRadius: 20,
      justifyContent: "center",
      alignItems: "center",
      paddingHorizontal: 20,
      paddingVertical: 7,
    },
    buyText: {
      fontFamily: "MontserratRegular",
      color: colors.white,
      fontSize: 14,
    },
  },
  {
    [DEVICE_SIZES.XS]: {
      container: {
        flex: 1,
        width: (Dimensions.get("window").width / 10) * 10,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      },
      logo: {
        width: (Dimensions.get("window").width / 10) * 3,
        height: (Dimensions.get("window").width / 10) * 4,
        borderRadius: (Dimensions.get("window").width / 10) * 0.1,
        overflow: "hidden",
      },
      linksContainer: {
        marginTop: 10,
        width: (Dimensions.get("window").width / 10) * 10,
        //backgroundColor: colors.black,
        justifyContent: "center",
        alignItems: "center",
      },
      applogo: {
        width: 110,
        height: 45,
      },
      productsBodyContainer: {
        marginVertical: 5,
        width: (Dimensions.get("window").width / 10) * 9,
        borderRadius: (Dimensions.get("window").width / 10) * 0.2,
        justifyContent: "center",
        alignItems: "center",
      },
      productContainer: {
        width: (Dimensions.get("window").width / 10) * 9,
        flexDirection: "row",
        justifyContent: "space-around",
        paddingVertical: 10,
        shadowRadius: 10,
        shadowColor: colors.darkgrey,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
      },
      productImageContainer: {
        width: (Dimensions.get("window").width / 10) * 3,
      },
      productDetailsContainer: {
        width: (Dimensions.get("window").width / 10) * 5,
        justifyContent: "space-between",
      },
      productTitleText: {
        fontFamily: "MontserratSemiBold",
        color: colors.black,
        fontSize: 14,
      },
      productBrandText: {
        fontFamily: "MontserratRegular",
        color: colors.grey,
        fontSize: 12,
      },
      productDescriptionText: {
        fontFamily: "MontserratRegular",
        color: colors.black,
        fontSize: 13,
      },
      activityContainer: {
        width: (Dimensions.get("window").width / 10) * 4,
        alignItems: "center",
        justifyContent: "center",
      },
      endListContainer: {
        justifyContent: "center",
        alignItems: "center",
      },
      endListMain: {
        justifyContent: "center",
        alignItems: "center",
        height: (Dimensions.get("window").height / 10) * 0.5,
      },
      loadMoreContainer: {
        justifyContent: "center",
        alignItems: "center",
      },
      lottieMainContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
      lottieContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        height: (Dimensions.get("window").width / 10) * 10,
        opacity: 1,
        justifyContent: "center",
        alignItems: "center",
      },
      emptyProductContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        opacity: 1,
        alignItems: "center",
      },
      emptyText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      footerLogoContainer: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      logoMainContainer: {
        marginTop: 10,
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      footerPowerContainer: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      powerText: {
        fontFamily: "MontserratRegular",
        color: colors.black,
        fontSize: 14,
      },
      logoContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        alignItems: "center",
      },
      headerMainContainer: {
        justifyContent: "center",
        alignItems: "center",
      },
      headerSubContainer: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      headerCategoryMainContainer: {
        marginTop: 10,
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      headerCategoryContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        alignItems: "center",
      },
      headerScrollViewContainer: {
        height: (Dimensions.get("window").width / 10) * 0.7,
        width: (Dimensions.get("window").width / 10) * 5,
        marginVertical: 10,
        justifyContent: "center",
        alignItems: "center",
      },
      categoryScrollView: {
        flex: 1,
        width: (Dimensions.get("window").width / 10) * 10,
      },
      couponContainer: {
        marginTop: 5,
        //backgroundColor: colors.lightpeach,
        width: (Dimensions.get("window").width / 10) * 5,
        padding: 3,
        justifyContent: "center",
        alignItems: "flex-start",
        borderRadius: 5,
      },
      spText: {
        fontFamily: "MontserratSemiBold",
        color: colors.black,
        fontSize: 14,
      },
      mrpText: {
        textDecorationLine: "line-through",
        textDecorationStyle: "solid",
        fontFamily: "MontserratRegular",
        color: colors.grey,
        fontSize: 12,
      },
      buyButton: {
        backgroundColor: colors.peach,
        borderRadius: 20,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 10,
        paddingVertical: 5,
      },
      buyText: {
        fontFamily: "MontserratRegular",
        color: colors.white,
        fontSize: 13,
      },
    },
  },
  {
    [DEVICE_SIZES.SM]: {
      container: {
        flex: 1,
        width: (Dimensions.get("window").width / 10) * 10,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      },
      logo: {
        width: (Dimensions.get("window").width / 10) * 3,
        height: (Dimensions.get("window").width / 10) * 4,
        borderRadius: (Dimensions.get("window").width / 10) * 0.1,
        overflow: "hidden",
      },
      linksContainer: {
        marginTop: 10,
        width: (Dimensions.get("window").width / 10) * 10,
        justifyContent: "center",
        alignItems: "center",
      },
      applogo: {
        width: 110,
        height: 45,
      },
      productsBodyContainer: {
        marginVertical: 5,
        width: (Dimensions.get("window").width / 10) * 9,
        borderRadius: (Dimensions.get("window").width / 10) * 0.2,
        justifyContent: "center",
        alignItems: "center",
      },
      productContainer: {
        width: (Dimensions.get("window").width / 10) * 9,
        flexDirection: "row",
        justifyContent: "space-around",
        paddingVertical: 10,
        shadowRadius: 10,
        shadowColor: colors.darkgrey,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
      },
      productImageContainer: {
        width: (Dimensions.get("window").width / 10) * 3,
      },
      productDetailsContainer: {
        width: (Dimensions.get("window").width / 10) * 5,
        justifyContent: "space-between",
      },
      productTitleText: {
        fontFamily: "MontserratSemiBold",
        color: colors.black,
        fontSize: 16,
      },
      productBrandText: {
        fontFamily: "MontserratRegular",
        color: colors.grey,
        fontSize: 14,
      },
      productDescriptionText: {
        fontFamily: "MontserratRegular",
        color: colors.black,
        fontSize: 13,
      },
      activityContainer: {
        width: (Dimensions.get("window").width / 10) * 4,
        alignItems: "center",
        justifyContent: "center",
      },
      endListContainer: {
        justifyContent: "center",
        alignItems: "center",
      },
      endListMain: {
        justifyContent: "center",
        alignItems: "center",
        height: (Dimensions.get("window").height / 10) * 0.5,
      },
      loadMoreContainer: {
        justifyContent: "center",
        alignItems: "center",
      },
      lottieMainContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
      lottieContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        height: (Dimensions.get("window").width / 10) * 10,
        opacity: 1,
        justifyContent: "center",
        alignItems: "center",
      },
      emptyProductContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        opacity: 1,
        alignItems: "center",
      },
      emptyText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      footerLogoContainer: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      logoMainContainer: {
        marginTop: 10,
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      footerPowerContainer: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      powerText: {
        fontFamily: "MontserratRegular",
        color: colors.black,
        fontSize: 14,
      },
      logoContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        alignItems: "center",
      },
      headerMainContainer: {
        justifyContent: "center",
        alignItems: "center",
      },
      headerSubContainer: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      headerCategoryMainContainer: {
        marginTop: 10,
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      headerCategoryContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        alignItems: "center",
      },
      headerScrollViewContainer: {
        height: (Dimensions.get("window").width / 10) * 0.7,
        width: (Dimensions.get("window").width / 10) * 5,
        marginVertical: 10,
        justifyContent: "center",
        alignItems: "center",
      },
      categoryScrollView: {
        flex: 1,
        width: (Dimensions.get("window").width / 10) * 10,
      },
      couponContainer: {
        marginTop: 5,
        //backgroundColor: colors.lightpeach,
        width: (Dimensions.get("window").width / 10) * 5,
        padding: 3,
        justifyContent: "center",
        alignItems: "flex-start",
        borderRadius: 5,
      },
      spText: {
        fontFamily: "MontserratSemiBold",
        color: colors.black,
        fontSize: 14,
      },
      mrpText: {
        textDecorationLine: "line-through",
        textDecorationStyle: "solid",
        fontFamily: "MontserratRegular",
        color: colors.grey,
        fontSize: 12,
      },
      buyButton: {
        backgroundColor: colors.peach,
        borderRadius: 20,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 10,
        paddingVertical: 7,
      },
      buyText: {
        fontFamily: "MontserratRegular",
        color: colors.white,
        fontSize: 13,
      },
    },
  }
);
