import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
  Button,
  useWindowDimensions,
  Linking,
} from "react-native";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import FontAwesome5 from "@expo/vector-icons/FontAwesome5";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import colors from "../constants/theme/colors";
import { useFonts } from "expo-font";
import LottieView from "react-native-web-lottie";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";

const MainBanner = (props) => {
  const deviceSize = useDeviceSize();

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }
  if (deviceSize === "extralarge") {
    return (
      <View style={styles.mainBanner}>
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <Image
            style={{ ...StyleSheet.absoluteFillObject }}
            source={require("../assets/images/home-background-1.jpg")}
            resizeMode="cover"
          />
          <View style={styles.overlay} />
        </View>
        <View style={styles.bannerContainer}>
          <View style={styles.mainBannerLeftCoulumn}>
            <View style={styles.maineBannerLeftText}>
              <View style={styles.headetTextContainer}>
                <Text style={styles.headerText}>LinksApp for Smart Life!</Text>
              </View>
              <View style={styles.bodyTextContainer}>
                <Text style={styles.bodyText}>
                  Welcome to LinksApp, your one-stop solution for streamlining
                  the way you share content online. We're here to make your life
                  easier. LinksApp isn't just an app; it's a movement that
                  empowers individuals, businesses, and influencers to connect
                  effortlessly. With a nod to innovation, we're proud to bring
                  you this Indian-born solution designed to revolutionize your
                  link-sharing experience.
                </Text>
              </View>
              <View style={styles.downloadContainer}>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://play.google.com/store/apps/details?id=com.linksapp"
                    );
                  }}
                  style={styles.appStoreContainer}
                >
                  <View>
                    <FontAwesome5
                      name="google-play"
                      color={colors.white}
                      size={30}
                    />
                  </View>
                  <View>
                    <View>
                      <Text style={styles.downloadHText}>From Play Store</Text>
                    </View>
                    <View>
                      <Text style={styles.downloadBText}>Play Store</Text>
                    </View>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://apps.apple.com/in/app/linksapp/id6466167153"
                    );
                  }}
                  style={styles.appStoreContainer}
                >
                  <View>
                    <FontAwesome name="apple" color={colors.white} size={30} />
                  </View>
                  <View>
                    <View>
                      <Text style={styles.downloadHText}>From App Store</Text>
                    </View>
                    <View>
                      <Text style={styles.downloadBText}>APP Store</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={styles.mainBannerRightCoulumn}>
            <View style={styles.maineBannerRigntText}>
              <Image
                style={{ width: 480, height: 480 }}
                source={require("../assets/images/home-banner.png")}
              />
            </View>
          </View>
        </View>
        <View style={styles.lottiContainer}>
          <View
            style={{
              width: Dimensions.get("window").width,
              height: (Dimensions.get("window").height / 10) * 0.2,
              backgroundColor: colors.peach,
            }}
          ></View>
          <View
            style={{
              width: Dimensions.get("window").width,
              //height: (Dimensions.get("window").height / 10) * 1,
              backgroundColor: colors.peach,
            }}
          >
            <LottieView
              source={require("../assets/lottie/wave.json")}
              autoPlay
              loop
              resizeMode="cover"
            />
          </View>
          <View
            style={{
              width: Dimensions.get("window").width,
              height: (Dimensions.get("window").height / 10) * 4,
              backgroundColor: colors.white,
              marginTop: -10,
              zIndex: 1111,
            }}
          ></View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={stylesSM.mainBanner}>
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <Image
            style={{ ...StyleSheet.absoluteFillObject }}
            source={require("../assets/images/home-background-1.jpg")}
            resizeMode="cover"
          />
          <View style={stylesSM.overlay} />
        </View>
        <View style={stylesSM.bannerContainer}>
          <View style={stylesSM.mainBannerLeftCoulumn}>
            <View style={stylesSM.maineBannerLeftText}>
              <View style={stylesSM.headetTextContainer}>
                <Text style={stylesSM.headerText}>
                  LinksApp for Smart Life!
                </Text>
              </View>
              <View style={stylesSM.bodyTextContainer}>
                <Text style={stylesSM.bodyText}>
                  Welcome to LinksApp, your one-stop solution for streamlining
                  the way you share content online. We're here to make your life
                  easier. LinksApp isn't just an app; it's a movement that
                  empowers individuals, businesses, and influencers to connect
                  effortlessly. With a nod to innovation, we're proud to bring
                  you this Indian-born solution designed to revolutionize your
                  link-sharing experience.
                </Text>
              </View>
              <View style={stylesSM.downloadContainer}>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://play.google.com/store/apps/details?id=com.linksapp"
                    );
                  }}
                  style={stylesSM.appStoreContainer}
                >
                  <View>
                    <FontAwesome5
                      name="google-play"
                      color={colors.white}
                      size={28}
                    />
                  </View>
                  <View>
                    <View>
                      <Text style={stylesSM.downloadHText}>
                        From Play Store
                      </Text>
                    </View>
                    <View>
                      <Text style={stylesSM.downloadBText}>Play Store</Text>
                    </View>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://apps.apple.com/in/app/linksapp/id6466167153"
                    );
                  }}
                  style={stylesSM.appStoreContainer}
                >
                  <View>
                    <FontAwesome name="apple" color={colors.white} size={30} />
                  </View>
                  <View>
                    <View>
                      <Text style={stylesSM.downloadHText}>From App Store</Text>
                    </View>
                    <View>
                      <Text style={stylesSM.downloadBText}>APP Store</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <View style={stylesSM.lottiContainer}>
          <LottieView
            source={require("../assets/lottie/wave.json")}
            autoPlay
            loop
            resizeMode="cover"
          />
          <View
            style={{
              width: Dimensions.get("window").width,
              height: (Dimensions.get("window").height / 10) * 3,
              backgroundColor: colors.white,
            }}
          ></View>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.peach,
  },
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    height: (Dimensions.get("window").height / 10) * 8,
  },
  bannerContainer: {
    flexDirection: "row",
    width: Dimensions.get("window").width,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    height: (Dimensions.get("window").height / 10) * 6,
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mainBannerRightCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 30,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 14,
    color: colors.white,
  },
  lottiContainer: {
    width: Dimensions.get("window").width,
    height: (Dimensions.get("window").height / 10) * 4,
    //paddingVertical: -10,
    backgroundColor: colors.white,
  },
});

const stylesSM = StyleSheet.create({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.peach,
  },
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    height: (Dimensions.get("window").height / 10) * 6.5,
  },
  bannerContainer: {
    flexDirection: "row",
    width: Dimensions.get("window").width,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    height: (Dimensions.get("window").height / 10) * 6,
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 8,
    alignItems: "center",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 9,
    alignItems: "center",
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 8,
    alignItems: "center",
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 25,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 9,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 3.9,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 14,
    color: colors.white,
  },
  lottiContainer: {
    width: Dimensions.get("window").width,
    height: (Dimensions.get("window").height / 10) * 3,
  },
});

export default MainBanner;
