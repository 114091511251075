import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import colors from "../constants/theme/colors";
import { useFonts } from "expo-font";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withTiming,
  withRepeat,
} from "react-native-reanimated";
import Collapsible from "react-native-collapsible";

const FaqSection = (props) => {
  const [isCollapsed1, setIsCollapsed1] = useState(true);
  const [isCollapsed2, setIsCollapsed2] = useState(true);
  const [isCollapsed3, setIsCollapsed3] = useState(true);
  const [isCollapsed4, setIsCollapsed4] = useState(true);
  const [isCollapsed5, setIsCollapsed5] = useState(true);
  const [isCollapsed6, setIsCollapsed6] = useState(true);

  const deviceSize = useDeviceSize();
  const progress = useSharedValue(1);

  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: progress.value }],
    };
  }, []);

  useEffect(() => {
    progress.value = withRepeat(withTiming(10, { duration: 1000 }), -1, true);
  }, []);

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }

  if (deviceSize === "extralarge") {
    return (
      <View style={styles.featuresContainer}>
        <View style={styles.featureHeaderContainer}>
          <Text style={styles.headerTextBlack}>Frequently Asked Questions</Text>
        </View>
        <View style={styles.featureTextContainer}>
          <Text style={styles.featuresText}>
            Welcome to the FAQs section of LinksApp, where we address your
            queries to help you make the most of our platform.
          </Text>
        </View>
        <View style={styles.faqBlockContainer}>
          <View style={styles.faqRightBlock}>
            <View>
              <Animated.Image
                style={[{ width: 400, height: 450 }, reanimatedStyle]}
                source={require("../assets/images/faq.png")}
              />
            </View>
          </View>
          <View style={styles.faqLeftBlock}>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 4,
                marginVertical: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed1(!isCollapsed1);
                  setIsCollapsed2(true);
                  setIsCollapsed3(true);
                  setIsCollapsed4(true);
                  setIsCollapsed5(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={{
                  width: (Dimensions.get("window").width / 10) * 4,
                  backgroundColor: colors.peach,
                  paddingVertical: 15,
                  paddingHorizontal: 20,
                  borderRadius: 50,
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. What is LinksApp?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed1}>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 4,
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    LinksApp is a web-based application that allows you to
                    create a single, customizable landing page where you can
                    house multiple links. It's designed to simplify the process
                    of sharing various links across your social media profiles,
                    websites, and other digital platforms.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 4,
                marginVertical: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed2(!isCollapsed2);
                  setIsCollapsed1(true);
                  setIsCollapsed3(true);
                  setIsCollapsed4(true);
                  setIsCollapsed5(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={{
                  width: (Dimensions.get("window").width / 10) * 4,
                  backgroundColor: colors.peach,
                  paddingVertical: 15,
                  paddingHorizontal: 20,
                  borderRadius: 50,
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. How does LinksApp work?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed2}>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 4,
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    After signing up for a LinksApp account, you can create a
                    personalized landing page by adding links to your social
                    media profiles, websites, online stores, blogs, and more.
                    You can customize the appearance of your LinksApp page to
                    match your branding. Then, simply share the URL of your
                    LinksApp page, and anyone who visits it can easily access
                    all the important links you've shared.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 4,
                marginVertical: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed3(!isCollapsed3);
                  setIsCollapsed2(true);
                  setIsCollapsed1(true);
                  setIsCollapsed4(true);
                  setIsCollapsed5(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={{
                  width: (Dimensions.get("window").width / 10) * 4,
                  backgroundColor: colors.peach,
                  paddingVertical: 15,
                  paddingHorizontal: 20,
                  borderRadius: 50,
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. Is LinksApp free to use?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed3}>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 4,
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    LinksApp offers both free and paid plans. The free plan
                    allows you to create a basic LinksApp page with essential
                    features. The paid plans, such as LinksApp Pro and LinksApp
                    Pro+, offer more advanced customization options, analytics,
                    scheduling, and additional features to enhance your
                    experience.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 4,
                marginVertical: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed4(!isCollapsed4);
                  setIsCollapsed2(true);
                  setIsCollapsed3(true);
                  setIsCollapsed1(true);
                  setIsCollapsed5(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={{
                  width: (Dimensions.get("window").width / 10) * 4,
                  backgroundColor: colors.peach,
                  paddingVertical: 15,
                  paddingHorizontal: 20,
                  borderRadius: 50,
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. Can I use LinksApp for business purposes?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed4}>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 4,
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    Absolutely. LinksApp is a versatile tool suitable for
                    individuals, influencers, creators, and businesses alike. It
                    can help you showcase products, services, events, and more,
                    making it a valuable asset for promoting your brand online.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 4,
                marginVertical: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed5(!isCollapsed5);
                  setIsCollapsed2(true);
                  setIsCollapsed3(true);
                  setIsCollapsed4(true);
                  setIsCollapsed1(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={{
                  width: (Dimensions.get("window").width / 10) * 4,
                  backgroundColor: colors.peach,
                  paddingVertical: 15,
                  paddingHorizontal: 20,
                  borderRadius: 50,
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. Can I change my links on my LinksApp page after it's been
                  created?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed5}>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 4,
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    Yes, you can update the links on your LinksApp page at any
                    time. This flexibility is particularly useful for keeping
                    your audience informed about new content, offers, or
                    updates.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 4,
                marginVertical: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed6(!isCollapsed6);
                  setIsCollapsed2(true);
                  setIsCollapsed3(true);
                  setIsCollapsed4(true);
                  setIsCollapsed5(true);
                  setIsCollapsed1(true);
                }}
                activeOpacity={0.7}
                style={{
                  width: (Dimensions.get("window").width / 10) * 4,
                  backgroundColor: colors.peach,
                  paddingVertical: 15,
                  paddingHorizontal: 20,
                  borderRadius: 50,
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. How do I integrate my LinksApp page with my social media
                  profiles?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed6}>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 4,
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    LinksApp provides easy-to-follow instructions on how to
                    integrate your LinksApp page with platforms like Instagram,
                    Twitter, TikTok, and more. You'll typically add your
                    LinksApp URL to your profile bio or share it in posts.
                  </Text>
                </View>
              </Collapsible>
            </View>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={stylesSM.featuresContainer}>
        <View style={stylesSM.featureHeaderContainer}>
          <Text style={stylesSM.headerTextBlack}>
            Frequently Asked Questions
          </Text>
        </View>
        <View style={stylesSM.featureTextContainer}>
          <Text style={stylesSM.featuresText}>
            Welcome to the FAQs section of LinksApp, where we address your
            queries to help you make the most of our platform.
          </Text>
        </View>
        <View style={stylesSM.faqBlockContainer}>
          <View style={stylesSM.faqRightBlock}>
            <View>
              <Animated.Image
                style={[{ width: 400, height: 450 }, reanimatedStyle]}
                source={require("../assets/images/faq.png")}
              />
            </View>
          </View>
          <View style={stylesSM.faqLeftBlock}>
            <View style={stylesSM.faqContainer}>
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed1(!isCollapsed1);
                  setIsCollapsed2(true);
                  setIsCollapsed3(true);
                  setIsCollapsed4(true);
                  setIsCollapsed5(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={stylesSM.faqQuesContainer}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. What is LinksApp?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed1}>
                <View style={stylesSM.faqAnsContainer}>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    LinksApp is a web-based application that allows you to
                    create a single, customizable landing page where you can
                    house multiple links. It's designed to simplify the process
                    of sharing various links across your social media profiles,
                    websites, and other digital platforms.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View style={stylesSM.faqContainer}>
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed5(!isCollapsed5);
                  setIsCollapsed2(true);
                  setIsCollapsed3(true);
                  setIsCollapsed4(true);
                  setIsCollapsed1(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={stylesSM.faqQuesContainer}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. How does LinksApp work?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed5}>
                <View style={stylesSM.faqAnsContainer}>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    After signing up for a LinksApp account, you can create a
                    personalized landing page by adding links to your social
                    media profiles, websites, online stores, blogs, and more.
                    You can customize the appearance of your LinksApp page to
                    match your branding. Then, simply share the URL of your
                    LinksApp page, and anyone who visits it can easily access
                    all the important links you've shared.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View style={stylesSM.faqContainer}>
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed2(!isCollapsed2);
                  setIsCollapsed1(true);
                  setIsCollapsed3(true);
                  setIsCollapsed4(true);
                  setIsCollapsed5(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={stylesSM.faqQuesContainer}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. Is LinksApp free to use?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed2}>
                <View style={stylesSM.faqAnsContainer}>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    LinksApp offers both free and paid plans. The free plan
                    allows you to create a basic LinksApp page with essential
                    features. The paid plans, such as LinksApp Pro and LinksApp
                    Pro+, offer more advanced customization options, analytics,
                    scheduling, and additional features to enhance your
                    experience.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View style={stylesSM.faqContainer}>
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed3(!isCollapsed3);
                  setIsCollapsed1(true);
                  setIsCollapsed2(true);
                  setIsCollapsed4(true);
                  setIsCollapsed5(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={stylesSM.faqQuesContainer}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. Can I use LinksApp for business purposes?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed3}>
                <View style={stylesSM.faqAnsContainer}>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    Absolutely. LinksApp is a versatile tool suitable for
                    individuals, influencers, creators, and businesses alike. It
                    can help you showcase products, services, events, and more,
                    making it a valuable asset for promoting your brand online.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View style={stylesSM.faqContainer}>
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed4(!isCollapsed4);
                  setIsCollapsed1(true);
                  setIsCollapsed3(true);
                  setIsCollapsed2(true);
                  setIsCollapsed5(true);
                  setIsCollapsed6(true);
                }}
                activeOpacity={0.7}
                style={stylesSM.faqQuesContainer}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. Can I change my links on my LinksApp page after it's been
                  created?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed4}>
                <View style={stylesSM.faqAnsContainer}>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    Yes, you can update the links on your LinksApp page at any
                    time. This flexibility is particularly useful for keeping
                    your audience informed about new content, offers, or
                    updates.
                  </Text>
                </View>
              </Collapsible>
            </View>
            <View style={stylesSM.faqContainer}>
              <TouchableOpacity
                onPress={() => {
                  setIsCollapsed6(!isCollapsed6);
                  setIsCollapsed1(true);
                  setIsCollapsed3(true);
                  setIsCollapsed4(true);
                  setIsCollapsed5(true);
                  setIsCollapsed2(true);
                }}
                activeOpacity={0.7}
                style={stylesSM.faqQuesContainer}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    color: colors.white,
                  }}
                >
                  Q. How do I integrate my LinksApp page with my social media
                  profiles?
                </Text>
              </TouchableOpacity>
              <Collapsible collapsed={isCollapsed6}>
                <View style={stylesSM.faqAnsContainer}>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      color: colors.darkgrey,
                    }}
                  >
                    LinksApp provides easy-to-follow instructions on how to
                    integrate your LinksApp page with platforms like Instagram,
                    Twitter, TikTok, and more. You'll typically add your
                    LinksApp URL to your profile bio or share it in posts.
                  </Text>
                </View>
              </Collapsible>
            </View>
          </View>
        </View>
      </View>
    );
  }
};

export default FaqSection;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: Dimensions.get("window").width,
  },
  iconContainer: {
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.25,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.peach,
  },
  iconStyle: {
    justifyContent: "center",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    shadowColor: "#000",
  },
  logo: {
    width: 110,
    height: 45,
  },
  appLogo: {
    width: 150,
    height: 45,
    marginVertical: 5,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.peach,
  },
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    height: (Dimensions.get("window").height / 10) * 8,
    //paddingVertical: 70,
    //flexDirection: "row",
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mainBannerRightCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 35,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 14,
    color: colors.white,
  },
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 50,
    //backgroundColor: colors.white
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 30,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 5,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: colors.red,
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 14,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleBlock: {
    width: (Dimensions.get("window").width / 10) * 3.5,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
  },
  rightBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "center",
    alignItems: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 2.5,
    height: (Dimensions.get("window").height / 10) * 1.75,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 12,
    color: colors.textcolor,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  iconConatiner: {
    width: 50,
    height: 50,
    backgroundColor: colors.lightorange,
    padding: 5,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  stepContainer: {
    width: 30,
    height: 64,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 1.5,
  },
  howLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 3,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  howRightBlock: {
    width: (Dimensions.get("window").width / 10) * 3,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 8.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  faqLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 4,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "center",
    alignItems: "center",
  },
  faqRightBlock: {
    width: (Dimensions.get("window").width / 10) * 4,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "center",
    alignItems: "center",
  },
  screenShotsContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    //height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    width: 100,
    height: 100,
    backgroundColor: colors.red,
  },
  carouselContainer: {
    marginBottom: 20,
    //backgroundColor: 'blue'
  },
  plansButtonContainer: {},
  buttonContainer: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: 50,
    borderColor: colors.peach,
    borderWidth: 1,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
  },
  planBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    //height: (Dimensions.get("window").height / 10) * 1.75,
    //paddingVertical: 50,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    borderRadius: 10,
    overflow: "hidden",
    //backgroundColor: colors.peach,
  },
  collapsibleMainContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    marginVertical: 10,
  },
  featureText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: colors.grey,
  },
});

const stylesSM = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: Dimensions.get("window").width,
  },
  iconContainer: {
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.25,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.peach,
  },
  iconStyle: {
    justifyContent: "center",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    shadowColor: "#000",
  },
  logo: {
    width: 110,
    height: 45,
  },
  appLogo: {
    width: 150,
    height: 45,
    marginVertical: 5,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.peach,
  },
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    height: (Dimensions.get("window").height / 10) * 8,
    //paddingVertical: 70,
    //flexDirection: "row",
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mainBannerRightCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 35,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 13,
    color: colors.white,
  },
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 20,
    //backgroundColor: colors.white
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 25,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: colors.red,
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 14,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleBlock: {
    width: (Dimensions.get("window").width / 10) * 3.5,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
  },
  rightBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "center",
    alignItems: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 2.5,
    height: (Dimensions.get("window").height / 10) * 1.75,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 12,
    color: colors.textcolor,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  iconConatiner: {
    width: 50,
    height: 50,
    backgroundColor: colors.lightorange,
    padding: 5,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  stepContainer: {
    width: 30,
    height: 64,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 1.5,
  },
  howLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 3,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  howRightBlock: {
    width: (Dimensions.get("window").width / 10) * 3,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 9,
    //height: (Dimensions.get("window").height / 10) * 6,
    //marginVertical: 20,
    //flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  faqLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 9,
    marginVertical: 20,
    //height: (Dimensions.get("window").height / 10) * 6,
    // justifyContent: "center",
    // alignItems: "center",
  },
  faqRightBlock: {
    width: (Dimensions.get("window").width / 10) * 8,
    //height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  screenShotsContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    //height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    width: 100,
    height: 100,
    backgroundColor: colors.red,
  },
  carouselContainer: {
    marginBottom: 20,
    //backgroundColor: 'blue'
  },
  plansButtonContainer: {},
  buttonContainer: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: 50,
    borderColor: colors.peach,
    borderWidth: 1,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
  },
  planBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    //height: (Dimensions.get("window").height / 10) * 1.75,
    //paddingVertical: 50,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    borderRadius: 10,
    overflow: "hidden",
    //backgroundColor: colors.peach,
  },
  collapsibleMainContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    marginVertical: 10,
  },
  featureText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: colors.grey,
  },
  faqContainer: {
    width: (Dimensions.get("window").width / 10) * 9,
    marginVertical: 10,
  },
  faqQuesContainer: {
    width: (Dimensions.get("window").width / 10) * 9,
    backgroundColor: colors.peach,
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 50,
  },
  faqAnsContainer: {
    width: (Dimensions.get("window").width / 10) * 9,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
