import { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  Image,
  ActivityIndicator,
} from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { useFonts } from "expo-font";
import colors from "../constants/theme/colors";
import LinksScreen from "../screens/LinksScreen";
import ProductsScreen from "../screens/ProductsScreen";
import LottieView from "react-native-web-lottie";
import SEOSection from "../components/SEOSection";
import {
  EXPO_PUBLIC_API_URL,
  EXPO_PUBLIC_API_KEY,
  EXPO_PUBLIC_BASE_URL,
} from "@env";
import { Helmet } from "react-helmet";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";
import StoreProductsScreen from "../screens/StoreProductsScreen.js";
import StoreStack from "./stack/StoreStack.js";
import InactiveTab from "../screens/InactiveTab.js";

let profileTabs = [
  {
    id: "1",
    type: "link",
    name: "LinksScreen",
    component: LinksScreen,
    label: "Links",
    position: 1,
    active: true,
  },
  {
    id: "2",
    type: "affiliate",
    name: "ProductsScreen",
    component: ProductsScreen,
    label: "Affiliate",
    position: 2,
    active: true,
  },
  {
    id: "3",
    type: "store",
    name: "StoreProductsScreen",
    component: StoreProductsScreen,
    label: "Store",
    position: 3,
    active: true,
  },
];

const ProfileTab = ({ route, navigation }) => {
  const [userProfileDetails, setUserProfileDetails] = useState([]);
  const [userFolderPath, setUserFolderPath] = useState();
  const styles = useStyles();
  const deviceSize = useDeviceSize();
  const [tabFirstLabel, setTabFirstLabel] = useState("Links");
  const [tabSecondLabel, setTabSecondLabel] = useState("Affiliate");
  const [tabThirdLabel, setTabThirdLabel] = useState("Store");
  const [tabLoading, setTabLoading] = useState(true);

  //console.log(EXPO_PUBLIC_API_URL);

  useEffect(() => {
    const fetchUserDetails = async (props) => {
      const userDetails = await getuserDetails();
      //console.log(userDetails);
      if (userDetails.success) {
        setUserProfileDetails(userDetails.userDetails);
        setUserFolderPath(userDetails.userFolderPath);

        // ** Set Tab Navigation

        //console.log(profileTabs);
        profileTabs.forEach((item, index) => {
          if (item.type == "link") {
            item.position = userDetails.userDetails.link_position;
            item.active = userDetails.userDetails.link_tab;
          }
          if (item.type == "affiliate") {
            item.position = userDetails.userDetails.affiliate_position;
            item.active = userDetails.userDetails.link_tab;
          }
          if (item.type == "store") {
            item.position = userDetails.userDetails.store_position;
            item.active = userDetails.userDetails.store_tab;
          }
        });

        let newProfileTabs = [...profileTabs];

        newProfileTabs.sort((a, b) => a.position - b.position);
        profileTabs = newProfileTabs;

        profileTabs.forEach((item, index) => {
          if (item.position == 1) {
            setTabFirstLabel(item.label);
          }
          if (item.position == 2) {
            setTabSecondLabel(item.label);
          }
          if (item.position == 3) {
            setTabThirdLabel(item.label);
          }
        });

        //console.log(profileTabs);
        setTabLoading(false);
        // console.log(tabFirstLabel);
        // console.log(tabSecondLabel);
        // console.log(tabThirdLabel);
      } else {
        navigation.navigate("Drawer");
      }
    };
    fetchUserDetails();
  }, []);

  const getuserDetails = async () => {
    try {
      const apiResult = await fetch(
        `${EXPO_PUBLIC_API_URL}users/getProfileDetails?id=${route.params.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "ps-key": EXPO_PUBLIC_API_KEY,
          },
        }
      );
      const resData = await apiResult.json();
      //console.log(resData);
      return resData;
    } catch (error) {
      navigation.navigate("Drawer");
      //console.log(error);
    }
  };
  const Tab = createMaterialTopTabNavigator();
  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }

  if (tabLoading) {
    return (
      <View
        style={{
          width: (Dimensions.get("window").width / 10) * 10,
          height: (Dimensions.get("window").height / 10) * 10,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator color={colors.black} size={24} />
      </View>
    );
  }

  // if (
  //   !userProfileDetails.link_tab &&
  //   !userProfileDetails.affiliate_tab &&
  //   !userProfileDetails.store_tab
  // ) {
  //   return (
  //     <View
  //       style={{
  //         width: (Dimensions.get("window").width / 10) * 10,
  //         height: (Dimensions.get("window").height / 10) * 10,
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <ActivityIndicator color={colors.black} size={24} />
  //       <Text>Account is not active! Kinldy enable atleast one tab.</Text>
  //     </View>
  //   );
  // }

  if (
    tabFirstLabel == "Store" &&
    tabSecondLabel == "Links" &&
    tabThirdLabel == "Affiliate"
  ) {
    return (
      <View style={styles.container}>
        <SEOSection
          title={
            userProfileDetails.firstname + " " + userProfileDetails.lastname
          }
          description={userProfileDetails.profile_bio}
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 10,
            height: (Dimensions.get("window").height / 10) * 10,
            position: "absolute",
            opacity: 1,
          }}
        >
          <Image
            style={styles.backgroundMain}
            source={require("../assets/images/mental-health.jpg")}
          />
        </View>
        <View style={styles.mainContainer}>
          {userProfileDetails && (
            <View style={styles.background}>
              {userProfileDetails.profile_animation == "default" ||
              userProfileDetails.profile_animation == null ||
              userProfileDetails.profile_animation == undefined ? (
                <View style={styles.imageBackground}>
                  <Image
                    style={styles.imageBackground}
                    source={
                      userProfileDetails.profile_theme == "purple"
                        ? require("../assets/images/theme/purple.jpg")
                        : userProfileDetails.profile_theme == "rainbow-pattern"
                        ? require("../assets/images/theme/rainbow-pattern.jpg")
                        : userProfileDetails.profile_theme == "sketch-pattern"
                        ? require("../assets/images/theme/sketch-pattern.jpg")
                        : userProfileDetails.profile_theme == "water-color"
                        ? require("../assets/images/theme/water-color.jpg")
                        : userProfileDetails.profile_theme == "night-girl"
                        ? require("../assets/images/theme/night-girl.jpg")
                        : userProfileDetails.profile_theme == "mandala"
                        ? require("../assets/images/theme/mandala.jpg")
                        : userProfileDetails.profile_theme == "couple-winter"
                        ? require("../assets/images/theme/couple-winter.jpg")
                        : userProfileDetails.profile_theme == "cloud"
                        ? require("../assets/images/theme/cloud.jpg")
                        : userProfileDetails.profile_theme == "planet"
                        ? require("../assets/images/theme/planet.jpg")
                        : userProfileDetails.profile_theme == "forest-cloudy"
                        ? require("../assets/images/theme/forest-cloudy.jpg")
                        : userProfileDetails.profile_theme == "rainbow-cloud"
                        ? require("../assets/images/theme/rainbow-cloud.jpg")
                        : userProfileDetails.profile_theme == "peach-flower"
                        ? require("../assets/images/theme/peach-flower.jpg")
                        : userProfileDetails.profile_theme == "wood-texture"
                        ? require("../assets/images/theme/wood-texture.jpg")
                        : userProfileDetails.profile_theme == "flower"
                        ? require("../assets/images/theme/flower.jpg")
                        : userProfileDetails.profile_theme == "space"
                        ? require("../assets/images/theme/space.jpg")
                        : userProfileDetails.profile_theme == "couple-umbrella"
                        ? require("../assets/images/theme/couple-umbrella.jpg")
                        : userProfileDetails.profile_theme == "alone-night"
                        ? require("../assets/images/theme/alone-night.jpg")
                        : userProfileDetails.profile_theme == "city-dark"
                        ? require("../assets/images/theme/city-dark.jpg")
                        : userProfileDetails.profile_theme == "pattern-light"
                        ? require("../assets/images/theme/pattern-light.jpg")
                        : userProfileDetails.profile_theme == "cloud-sunset"
                        ? require("../assets/images/theme/cloud-sunset.jpg")
                        : userProfileDetails.profile_theme == "leaves-spring"
                        ? require("../assets/images/theme/leaves-spring.jpg")
                        : userProfileDetails.profile_theme == "feather"
                        ? require("../assets/images/theme/feather.jpg")
                        : userProfileDetails.profile_theme == "anime-night"
                        ? require("../assets/images/theme/anime-night.jpg")
                        : userProfileDetails.profile_theme == "couple-rain"
                        ? require("../assets/images/theme/couple-rain.jpg")
                        : userProfileDetails.profile_theme == "flower-girl"
                        ? require("../assets/images/theme/flower-girl.jpg")
                        : userProfileDetails.profile_theme == "flower-autumn"
                        ? require("../assets/images/theme/flower-autumn.jpg")
                        : userProfileDetails.profile_theme == "couple-mordern"
                        ? require("../assets/images/theme/couple-mordern.jpg")
                        : userProfileDetails.profile_theme == "music-boy"
                        ? require("../assets/images/theme/music-boy.jpg")
                        : userProfileDetails.profile_theme == "couple-sunset"
                        ? require("../assets/images/theme/couple-sunset.jpg")
                        : userProfileDetails.profile_theme == "yellow-texture"
                        ? require("../assets/images/theme/yellow-texture.jpg")
                        : userProfileDetails.profile_theme == "anime"
                        ? require("../assets/images/theme/anime.jpg")
                        : userProfileDetails.profile_theme == "makeup"
                        ? require("../assets/images/theme/makeup.jpg")
                        : userProfileDetails.profile_theme == "flower-spring"
                        ? require("../assets/images/theme/flower-spring.jpg")
                        : userProfileDetails.profile_theme == "anime-desk"
                        ? require("../assets/images/theme/anime-desk.jpg")
                        : userProfileDetails.profile_theme == "jupiter"
                        ? require("../assets/images/theme/jupiter.jpg")
                        : userProfileDetails.profile_theme == "navratri-one"
                        ? require("../assets/images/theme/navratri-one.jpg")
                        : userProfileDetails.profile_theme == "ram-one"
                        ? require("../assets/images/theme/ram-one.jpg")
                        : userProfileDetails.profile_theme == "ram-five"
                        ? require("../assets/images/theme/ram-five.jpg")
                        : userProfileDetails.profile_theme == "student"
                        ? require("../assets/images/theme/student.jpg")
                        : userProfileDetails.profile_theme == "valentine-two"
                        ? require("../assets/images/theme/valentine-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-four"
                        ? require("../assets/images/theme/valentine-four.jpg")
                        : userProfileDetails.profile_theme == "holi-one"
                        ? require("../assets/images/theme/holi-one.jpg")
                        : userProfileDetails.profile_theme == "india-one"
                        ? require("../assets/images/theme/india-one.jpg")
                        : userProfileDetails.profile_theme == "ram-two"
                        ? require("../assets/images/theme/ram-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-three"
                        ? require("../assets/images/theme/valentine-three.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-two"
                        ? require("../assets/images/theme/ayodhya-two.jpg")
                        : userProfileDetails.profile_theme == "masjid-one"
                        ? require("../assets/images/theme/masjid-one.jpg")
                        : userProfileDetails.profile_theme == "ram-four"
                        ? require("../assets/images/theme/ram-four.jpg")
                        : userProfileDetails.profile_theme == "ram-three"
                        ? require("../assets/images/theme/ram-three.jpg")
                        : userProfileDetails.profile_theme == "valentine-one"
                        ? require("../assets/images/theme/valentine-one.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-one"
                        ? require("../assets/images/theme/ayodhya-one.jpg")
                        : require("../assets/images/theme/default.jpg")
                    }
                  />
                </View>
              ) : (
                <LottieView
                  source={
                    userProfileDetails.profile_animation == "mountain"
                      ? require("../assets/lottie/mountain.json")
                      : userProfileDetails.profile_animation == "night"
                      ? require("../assets/lottie/night.json")
                      : userProfileDetails.profile_animation == "train"
                      ? require("../assets/lottie/train.json")
                      : userProfileDetails.profile_animation == "road"
                      ? require("../assets/lottie/road-test.json")
                      : require("../assets/lottie/ocean.json")
                  }
                  autoPlay
                  loop
                  resizeMode="cover"
                />
              )}
            </View>
          )}
          {userProfileDetails && (
            <Tab.Navigator
              sceneContainerStyle={{ backgroundColor: "transparent" }}
              //initialRouteName="products"
              screenOptions={{
                swipeEnabled: false,
                tabBarLabelStyle: {
                  fontSize: 13,
                  fontFamily: "MontserratSemiBold",
                  color:
                    userProfileDetails.profile_animation != "default"
                      ? userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                        ? colors.white
                        : colors.black
                      : userProfileDetails.profile_theme == "purple" ||
                        userProfileDetails.profile_theme == "night-girl" ||
                        userProfileDetails.profile_theme == "couple-winter" ||
                        userProfileDetails.profile_theme == "planet" ||
                        userProfileDetails.profile_theme == "space" ||
                        userProfileDetails.profile_theme == "couple-umbrella" ||
                        userProfileDetails.profile_theme == "alone-night" ||
                        userProfileDetails.profile_theme == "feather" ||
                        userProfileDetails.profile_theme == "anime-night" ||
                        userProfileDetails.profile_theme == "flower-girl" ||
                        userProfileDetails.profile_theme == "couple-mordern" ||
                        userProfileDetails.profile_theme == "music-boy" ||
                        userProfileDetails.profile_theme == "couple-sunset" ||
                        userProfileDetails.profile_theme == "anime" ||
                        userProfileDetails.profile_theme == "ram-one" ||
                        userProfileDetails.profile_theme == "ram-five" ||
                        userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                      ? colors.white
                      : colors.black,
                },
                tabBarStyle: {
                  backgroundColor: "transparent",
                  elevation: 0,
                },
                tabBarIndicatorStyle: { backgroundColor: colors.white },
              }}
            >
              {userProfileDetails.store_tab && (
                <Tab.Screen
                  name="products"
                  component={StoreStack}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Products",
                  }}
                />
              )}
              {userProfileDetails.link_tab && (
                <Tab.Screen
                  name="links"
                  component={LinksScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Links",
                  }}
                />
              )}
              {userProfileDetails.affiliate_tab && (
                <Tab.Screen
                  name="affiliate"
                  component={ProductsScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Affiliate",
                  }}
                />
              )}
            </Tab.Navigator>
          )}
        </View>
      </View>
    );
  } else if (
    tabFirstLabel == "Affiliate" &&
    tabSecondLabel == "Store" &&
    tabThirdLabel == "Links"
  ) {
    return (
      <View style={styles.container}>
        <SEOSection
          title={
            userProfileDetails.firstname + " " + userProfileDetails.lastname
          }
          description={userProfileDetails.profile_bio}
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 10,
            height: (Dimensions.get("window").height / 10) * 10,
            position: "absolute",
            opacity: 1,
          }}
        >
          <Image
            style={styles.backgroundMain}
            source={require("../assets/images/mental-health.jpg")}
          />
        </View>
        <View style={styles.mainContainer}>
          {userProfileDetails && (
            <View style={styles.background}>
              {userProfileDetails.profile_animation == "default" ||
              userProfileDetails.profile_animation == null ||
              userProfileDetails.profile_animation == undefined ? (
                <View style={styles.imageBackground}>
                  <Image
                    style={styles.imageBackground}
                    source={
                      userProfileDetails.profile_theme == "purple"
                        ? require("../assets/images/theme/purple.jpg")
                        : userProfileDetails.profile_theme == "rainbow-pattern"
                        ? require("../assets/images/theme/rainbow-pattern.jpg")
                        : userProfileDetails.profile_theme == "sketch-pattern"
                        ? require("../assets/images/theme/sketch-pattern.jpg")
                        : userProfileDetails.profile_theme == "water-color"
                        ? require("../assets/images/theme/water-color.jpg")
                        : userProfileDetails.profile_theme == "night-girl"
                        ? require("../assets/images/theme/night-girl.jpg")
                        : userProfileDetails.profile_theme == "mandala"
                        ? require("../assets/images/theme/mandala.jpg")
                        : userProfileDetails.profile_theme == "couple-winter"
                        ? require("../assets/images/theme/couple-winter.jpg")
                        : userProfileDetails.profile_theme == "cloud"
                        ? require("../assets/images/theme/cloud.jpg")
                        : userProfileDetails.profile_theme == "planet"
                        ? require("../assets/images/theme/planet.jpg")
                        : userProfileDetails.profile_theme == "forest-cloudy"
                        ? require("../assets/images/theme/forest-cloudy.jpg")
                        : userProfileDetails.profile_theme == "rainbow-cloud"
                        ? require("../assets/images/theme/rainbow-cloud.jpg")
                        : userProfileDetails.profile_theme == "peach-flower"
                        ? require("../assets/images/theme/peach-flower.jpg")
                        : userProfileDetails.profile_theme == "wood-texture"
                        ? require("../assets/images/theme/wood-texture.jpg")
                        : userProfileDetails.profile_theme == "flower"
                        ? require("../assets/images/theme/flower.jpg")
                        : userProfileDetails.profile_theme == "space"
                        ? require("../assets/images/theme/space.jpg")
                        : userProfileDetails.profile_theme == "couple-umbrella"
                        ? require("../assets/images/theme/couple-umbrella.jpg")
                        : userProfileDetails.profile_theme == "alone-night"
                        ? require("../assets/images/theme/alone-night.jpg")
                        : userProfileDetails.profile_theme == "city-dark"
                        ? require("../assets/images/theme/city-dark.jpg")
                        : userProfileDetails.profile_theme == "pattern-light"
                        ? require("../assets/images/theme/pattern-light.jpg")
                        : userProfileDetails.profile_theme == "cloud-sunset"
                        ? require("../assets/images/theme/cloud-sunset.jpg")
                        : userProfileDetails.profile_theme == "leaves-spring"
                        ? require("../assets/images/theme/leaves-spring.jpg")
                        : userProfileDetails.profile_theme == "feather"
                        ? require("../assets/images/theme/feather.jpg")
                        : userProfileDetails.profile_theme == "anime-night"
                        ? require("../assets/images/theme/anime-night.jpg")
                        : userProfileDetails.profile_theme == "couple-rain"
                        ? require("../assets/images/theme/couple-rain.jpg")
                        : userProfileDetails.profile_theme == "flower-girl"
                        ? require("../assets/images/theme/flower-girl.jpg")
                        : userProfileDetails.profile_theme == "flower-autumn"
                        ? require("../assets/images/theme/flower-autumn.jpg")
                        : userProfileDetails.profile_theme == "couple-mordern"
                        ? require("../assets/images/theme/couple-mordern.jpg")
                        : userProfileDetails.profile_theme == "music-boy"
                        ? require("../assets/images/theme/music-boy.jpg")
                        : userProfileDetails.profile_theme == "couple-sunset"
                        ? require("../assets/images/theme/couple-sunset.jpg")
                        : userProfileDetails.profile_theme == "yellow-texture"
                        ? require("../assets/images/theme/yellow-texture.jpg")
                        : userProfileDetails.profile_theme == "anime"
                        ? require("../assets/images/theme/anime.jpg")
                        : userProfileDetails.profile_theme == "makeup"
                        ? require("../assets/images/theme/makeup.jpg")
                        : userProfileDetails.profile_theme == "flower-spring"
                        ? require("../assets/images/theme/flower-spring.jpg")
                        : userProfileDetails.profile_theme == "anime-desk"
                        ? require("../assets/images/theme/anime-desk.jpg")
                        : userProfileDetails.profile_theme == "jupiter"
                        ? require("../assets/images/theme/jupiter.jpg")
                        : userProfileDetails.profile_theme == "navratri-one"
                        ? require("../assets/images/theme/navratri-one.jpg")
                        : userProfileDetails.profile_theme == "ram-one"
                        ? require("../assets/images/theme/ram-one.jpg")
                        : userProfileDetails.profile_theme == "ram-five"
                        ? require("../assets/images/theme/ram-five.jpg")
                        : userProfileDetails.profile_theme == "student"
                        ? require("../assets/images/theme/student.jpg")
                        : userProfileDetails.profile_theme == "valentine-two"
                        ? require("../assets/images/theme/valentine-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-four"
                        ? require("../assets/images/theme/valentine-four.jpg")
                        : userProfileDetails.profile_theme == "holi-one"
                        ? require("../assets/images/theme/holi-one.jpg")
                        : userProfileDetails.profile_theme == "india-one"
                        ? require("../assets/images/theme/india-one.jpg")
                        : userProfileDetails.profile_theme == "ram-two"
                        ? require("../assets/images/theme/ram-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-three"
                        ? require("../assets/images/theme/valentine-three.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-two"
                        ? require("../assets/images/theme/ayodhya-two.jpg")
                        : userProfileDetails.profile_theme == "masjid-one"
                        ? require("../assets/images/theme/masjid-one.jpg")
                        : userProfileDetails.profile_theme == "ram-four"
                        ? require("../assets/images/theme/ram-four.jpg")
                        : userProfileDetails.profile_theme == "ram-three"
                        ? require("../assets/images/theme/ram-three.jpg")
                        : userProfileDetails.profile_theme == "valentine-one"
                        ? require("../assets/images/theme/valentine-one.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-one"
                        ? require("../assets/images/theme/ayodhya-one.jpg")
                        : require("../assets/images/theme/default.jpg")
                    }
                  />
                </View>
              ) : (
                <LottieView
                  source={
                    userProfileDetails.profile_animation == "mountain"
                      ? require("../assets/lottie/mountain.json")
                      : userProfileDetails.profile_animation == "night"
                      ? require("../assets/lottie/night.json")
                      : userProfileDetails.profile_animation == "train"
                      ? require("../assets/lottie/train.json")
                      : userProfileDetails.profile_animation == "road"
                      ? require("../assets/lottie/road-test.json")
                      : require("../assets/lottie/ocean.json")
                  }
                  autoPlay
                  loop
                  resizeMode="cover"
                />
              )}
            </View>
          )}
          {userProfileDetails && (
            <Tab.Navigator
              sceneContainerStyle={{ backgroundColor: "transparent" }}
              //initialRouteName="affiliate"
              screenOptions={{
                swipeEnabled: false,
                tabBarLabelStyle: {
                  fontSize: 13,
                  fontFamily: "MontserratSemiBold",
                  color:
                    userProfileDetails.profile_animation != "default"
                      ? userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                        ? colors.white
                        : colors.black
                      : userProfileDetails.profile_theme == "purple" ||
                        userProfileDetails.profile_theme == "night-girl" ||
                        userProfileDetails.profile_theme == "couple-winter" ||
                        userProfileDetails.profile_theme == "planet" ||
                        userProfileDetails.profile_theme == "space" ||
                        userProfileDetails.profile_theme == "couple-umbrella" ||
                        userProfileDetails.profile_theme == "alone-night" ||
                        userProfileDetails.profile_theme == "feather" ||
                        userProfileDetails.profile_theme == "anime-night" ||
                        userProfileDetails.profile_theme == "flower-girl" ||
                        userProfileDetails.profile_theme == "couple-mordern" ||
                        userProfileDetails.profile_theme == "music-boy" ||
                        userProfileDetails.profile_theme == "couple-sunset" ||
                        userProfileDetails.profile_theme == "anime" ||
                        userProfileDetails.profile_theme == "ram-one" ||
                        userProfileDetails.profile_theme == "ram-five" ||
                        userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                      ? colors.white
                      : colors.black,
                },
                tabBarStyle: {
                  backgroundColor: "transparent",
                  elevation: 0,
                },
                tabBarIndicatorStyle: { backgroundColor: colors.white },
              }}
            >
              {userProfileDetails.affiliate_tab && (
                <Tab.Screen
                  name="affiliate"
                  component={ProductsScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Affiliate",
                  }}
                />
              )}
              {userProfileDetails.store_tab && (
                <Tab.Screen
                  name="products"
                  component={StoreStack}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Products",
                  }}
                />
              )}
              {userProfileDetails.link_tab && (
                <Tab.Screen
                  name="links"
                  component={LinksScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Links",
                  }}
                />
              )}
            </Tab.Navigator>
          )}
        </View>
      </View>
    );
  } else if (
    tabFirstLabel == "Affiliate" &&
    tabSecondLabel == "Links" &&
    tabThirdLabel == "Store"
  ) {
    return (
      <View style={styles.container}>
        <SEOSection
          title={
            userProfileDetails.firstname + " " + userProfileDetails.lastname
          }
          description={userProfileDetails.profile_bio}
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 10,
            height: (Dimensions.get("window").height / 10) * 10,
            position: "absolute",
            opacity: 1,
          }}
        >
          <Image
            style={styles.backgroundMain}
            source={require("../assets/images/mental-health.jpg")}
          />
        </View>
        <View style={styles.mainContainer}>
          {userProfileDetails && (
            <View style={styles.background}>
              {userProfileDetails.profile_animation == "default" ||
              userProfileDetails.profile_animation == null ||
              userProfileDetails.profile_animation == undefined ? (
                <View style={styles.imageBackground}>
                  <Image
                    style={styles.imageBackground}
                    source={
                      userProfileDetails.profile_theme == "purple"
                        ? require("../assets/images/theme/purple.jpg")
                        : userProfileDetails.profile_theme == "rainbow-pattern"
                        ? require("../assets/images/theme/rainbow-pattern.jpg")
                        : userProfileDetails.profile_theme == "sketch-pattern"
                        ? require("../assets/images/theme/sketch-pattern.jpg")
                        : userProfileDetails.profile_theme == "water-color"
                        ? require("../assets/images/theme/water-color.jpg")
                        : userProfileDetails.profile_theme == "night-girl"
                        ? require("../assets/images/theme/night-girl.jpg")
                        : userProfileDetails.profile_theme == "mandala"
                        ? require("../assets/images/theme/mandala.jpg")
                        : userProfileDetails.profile_theme == "couple-winter"
                        ? require("../assets/images/theme/couple-winter.jpg")
                        : userProfileDetails.profile_theme == "cloud"
                        ? require("../assets/images/theme/cloud.jpg")
                        : userProfileDetails.profile_theme == "planet"
                        ? require("../assets/images/theme/planet.jpg")
                        : userProfileDetails.profile_theme == "forest-cloudy"
                        ? require("../assets/images/theme/forest-cloudy.jpg")
                        : userProfileDetails.profile_theme == "rainbow-cloud"
                        ? require("../assets/images/theme/rainbow-cloud.jpg")
                        : userProfileDetails.profile_theme == "peach-flower"
                        ? require("../assets/images/theme/peach-flower.jpg")
                        : userProfileDetails.profile_theme == "wood-texture"
                        ? require("../assets/images/theme/wood-texture.jpg")
                        : userProfileDetails.profile_theme == "flower"
                        ? require("../assets/images/theme/flower.jpg")
                        : userProfileDetails.profile_theme == "space"
                        ? require("../assets/images/theme/space.jpg")
                        : userProfileDetails.profile_theme == "couple-umbrella"
                        ? require("../assets/images/theme/couple-umbrella.jpg")
                        : userProfileDetails.profile_theme == "alone-night"
                        ? require("../assets/images/theme/alone-night.jpg")
                        : userProfileDetails.profile_theme == "city-dark"
                        ? require("../assets/images/theme/city-dark.jpg")
                        : userProfileDetails.profile_theme == "pattern-light"
                        ? require("../assets/images/theme/pattern-light.jpg")
                        : userProfileDetails.profile_theme == "cloud-sunset"
                        ? require("../assets/images/theme/cloud-sunset.jpg")
                        : userProfileDetails.profile_theme == "leaves-spring"
                        ? require("../assets/images/theme/leaves-spring.jpg")
                        : userProfileDetails.profile_theme == "feather"
                        ? require("../assets/images/theme/feather.jpg")
                        : userProfileDetails.profile_theme == "anime-night"
                        ? require("../assets/images/theme/anime-night.jpg")
                        : userProfileDetails.profile_theme == "couple-rain"
                        ? require("../assets/images/theme/couple-rain.jpg")
                        : userProfileDetails.profile_theme == "flower-girl"
                        ? require("../assets/images/theme/flower-girl.jpg")
                        : userProfileDetails.profile_theme == "flower-autumn"
                        ? require("../assets/images/theme/flower-autumn.jpg")
                        : userProfileDetails.profile_theme == "couple-mordern"
                        ? require("../assets/images/theme/couple-mordern.jpg")
                        : userProfileDetails.profile_theme == "music-boy"
                        ? require("../assets/images/theme/music-boy.jpg")
                        : userProfileDetails.profile_theme == "couple-sunset"
                        ? require("../assets/images/theme/couple-sunset.jpg")
                        : userProfileDetails.profile_theme == "yellow-texture"
                        ? require("../assets/images/theme/yellow-texture.jpg")
                        : userProfileDetails.profile_theme == "anime"
                        ? require("../assets/images/theme/anime.jpg")
                        : userProfileDetails.profile_theme == "makeup"
                        ? require("../assets/images/theme/makeup.jpg")
                        : userProfileDetails.profile_theme == "flower-spring"
                        ? require("../assets/images/theme/flower-spring.jpg")
                        : userProfileDetails.profile_theme == "anime-desk"
                        ? require("../assets/images/theme/anime-desk.jpg")
                        : userProfileDetails.profile_theme == "jupiter"
                        ? require("../assets/images/theme/jupiter.jpg")
                        : userProfileDetails.profile_theme == "navratri-one"
                        ? require("../assets/images/theme/navratri-one.jpg")
                        : userProfileDetails.profile_theme == "ram-one"
                        ? require("../assets/images/theme/ram-one.jpg")
                        : userProfileDetails.profile_theme == "ram-five"
                        ? require("../assets/images/theme/ram-five.jpg")
                        : userProfileDetails.profile_theme == "student"
                        ? require("../assets/images/theme/student.jpg")
                        : userProfileDetails.profile_theme == "valentine-two"
                        ? require("../assets/images/theme/valentine-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-four"
                        ? require("../assets/images/theme/valentine-four.jpg")
                        : userProfileDetails.profile_theme == "holi-one"
                        ? require("../assets/images/theme/holi-one.jpg")
                        : userProfileDetails.profile_theme == "india-one"
                        ? require("../assets/images/theme/india-one.jpg")
                        : userProfileDetails.profile_theme == "ram-two"
                        ? require("../assets/images/theme/ram-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-three"
                        ? require("../assets/images/theme/valentine-three.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-two"
                        ? require("../assets/images/theme/ayodhya-two.jpg")
                        : userProfileDetails.profile_theme == "masjid-one"
                        ? require("../assets/images/theme/masjid-one.jpg")
                        : userProfileDetails.profile_theme == "ram-four"
                        ? require("../assets/images/theme/ram-four.jpg")
                        : userProfileDetails.profile_theme == "ram-three"
                        ? require("../assets/images/theme/ram-three.jpg")
                        : userProfileDetails.profile_theme == "valentine-one"
                        ? require("../assets/images/theme/valentine-one.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-one"
                        ? require("../assets/images/theme/ayodhya-one.jpg")
                        : require("../assets/images/theme/default.jpg")
                    }
                  />
                </View>
              ) : (
                <LottieView
                  source={
                    userProfileDetails.profile_animation == "mountain"
                      ? require("../assets/lottie/mountain.json")
                      : userProfileDetails.profile_animation == "night"
                      ? require("../assets/lottie/night.json")
                      : userProfileDetails.profile_animation == "train"
                      ? require("../assets/lottie/train.json")
                      : userProfileDetails.profile_animation == "road"
                      ? require("../assets/lottie/road-test.json")
                      : require("../assets/lottie/ocean.json")
                  }
                  autoPlay
                  loop
                  resizeMode="cover"
                />
              )}
            </View>
          )}
          {userProfileDetails && (
            <Tab.Navigator
              sceneContainerStyle={{ backgroundColor: "transparent" }}
              //initialRouteName="affiliate"
              screenOptions={{
                swipeEnabled: false,
                tabBarLabelStyle: {
                  fontSize: 13,
                  fontFamily: "MontserratSemiBold",
                  color:
                    userProfileDetails.profile_animation != "default"
                      ? userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                        ? colors.white
                        : colors.black
                      : userProfileDetails.profile_theme == "purple" ||
                        userProfileDetails.profile_theme == "night-girl" ||
                        userProfileDetails.profile_theme == "couple-winter" ||
                        userProfileDetails.profile_theme == "planet" ||
                        userProfileDetails.profile_theme == "space" ||
                        userProfileDetails.profile_theme == "couple-umbrella" ||
                        userProfileDetails.profile_theme == "alone-night" ||
                        userProfileDetails.profile_theme == "feather" ||
                        userProfileDetails.profile_theme == "anime-night" ||
                        userProfileDetails.profile_theme == "flower-girl" ||
                        userProfileDetails.profile_theme == "couple-mordern" ||
                        userProfileDetails.profile_theme == "music-boy" ||
                        userProfileDetails.profile_theme == "couple-sunset" ||
                        userProfileDetails.profile_theme == "anime" ||
                        userProfileDetails.profile_theme == "ram-one" ||
                        userProfileDetails.profile_theme == "ram-five" ||
                        userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                      ? colors.white
                      : colors.black,
                },
                tabBarStyle: {
                  backgroundColor: "transparent",
                  elevation: 0,
                },
                tabBarIndicatorStyle: { backgroundColor: colors.white },
              }}
            >
              {userProfileDetails.affiliate_tab && (
                <Tab.Screen
                  name="affiliate"
                  component={ProductsScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Affiliate",
                  }}
                />
              )}
              {userProfileDetails.link_tab && (
                <Tab.Screen
                  name="links"
                  component={LinksScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Links",
                  }}
                />
              )}
              {userProfileDetails.store_tab && (
                <Tab.Screen
                  name="products"
                  component={StoreStack}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Products",
                  }}
                />
              )}
            </Tab.Navigator>
          )}
        </View>
      </View>
    );
  } else if (
    tabFirstLabel == "Store" &&
    tabSecondLabel == "Affiliate" &&
    tabThirdLabel == "Links"
  ) {
    return (
      <View style={styles.container}>
        <SEOSection
          title={
            userProfileDetails.firstname + " " + userProfileDetails.lastname
          }
          description={userProfileDetails.profile_bio}
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 10,
            height: (Dimensions.get("window").height / 10) * 10,
            position: "absolute",
            opacity: 1,
          }}
        >
          <Image
            style={styles.backgroundMain}
            source={require("../assets/images/mental-health.jpg")}
          />
        </View>
        <View style={styles.mainContainer}>
          {userProfileDetails && (
            <View style={styles.background}>
              {userProfileDetails.profile_animation == "default" ||
              userProfileDetails.profile_animation == null ||
              userProfileDetails.profile_animation == undefined ? (
                <View style={styles.imageBackground}>
                  <Image
                    style={styles.imageBackground}
                    source={
                      userProfileDetails.profile_theme == "purple"
                        ? require("../assets/images/theme/purple.jpg")
                        : userProfileDetails.profile_theme == "rainbow-pattern"
                        ? require("../assets/images/theme/rainbow-pattern.jpg")
                        : userProfileDetails.profile_theme == "sketch-pattern"
                        ? require("../assets/images/theme/sketch-pattern.jpg")
                        : userProfileDetails.profile_theme == "water-color"
                        ? require("../assets/images/theme/water-color.jpg")
                        : userProfileDetails.profile_theme == "night-girl"
                        ? require("../assets/images/theme/night-girl.jpg")
                        : userProfileDetails.profile_theme == "mandala"
                        ? require("../assets/images/theme/mandala.jpg")
                        : userProfileDetails.profile_theme == "couple-winter"
                        ? require("../assets/images/theme/couple-winter.jpg")
                        : userProfileDetails.profile_theme == "cloud"
                        ? require("../assets/images/theme/cloud.jpg")
                        : userProfileDetails.profile_theme == "planet"
                        ? require("../assets/images/theme/planet.jpg")
                        : userProfileDetails.profile_theme == "forest-cloudy"
                        ? require("../assets/images/theme/forest-cloudy.jpg")
                        : userProfileDetails.profile_theme == "rainbow-cloud"
                        ? require("../assets/images/theme/rainbow-cloud.jpg")
                        : userProfileDetails.profile_theme == "peach-flower"
                        ? require("../assets/images/theme/peach-flower.jpg")
                        : userProfileDetails.profile_theme == "wood-texture"
                        ? require("../assets/images/theme/wood-texture.jpg")
                        : userProfileDetails.profile_theme == "flower"
                        ? require("../assets/images/theme/flower.jpg")
                        : userProfileDetails.profile_theme == "space"
                        ? require("../assets/images/theme/space.jpg")
                        : userProfileDetails.profile_theme == "couple-umbrella"
                        ? require("../assets/images/theme/couple-umbrella.jpg")
                        : userProfileDetails.profile_theme == "alone-night"
                        ? require("../assets/images/theme/alone-night.jpg")
                        : userProfileDetails.profile_theme == "city-dark"
                        ? require("../assets/images/theme/city-dark.jpg")
                        : userProfileDetails.profile_theme == "pattern-light"
                        ? require("../assets/images/theme/pattern-light.jpg")
                        : userProfileDetails.profile_theme == "cloud-sunset"
                        ? require("../assets/images/theme/cloud-sunset.jpg")
                        : userProfileDetails.profile_theme == "leaves-spring"
                        ? require("../assets/images/theme/leaves-spring.jpg")
                        : userProfileDetails.profile_theme == "feather"
                        ? require("../assets/images/theme/feather.jpg")
                        : userProfileDetails.profile_theme == "anime-night"
                        ? require("../assets/images/theme/anime-night.jpg")
                        : userProfileDetails.profile_theme == "couple-rain"
                        ? require("../assets/images/theme/couple-rain.jpg")
                        : userProfileDetails.profile_theme == "flower-girl"
                        ? require("../assets/images/theme/flower-girl.jpg")
                        : userProfileDetails.profile_theme == "flower-autumn"
                        ? require("../assets/images/theme/flower-autumn.jpg")
                        : userProfileDetails.profile_theme == "couple-mordern"
                        ? require("../assets/images/theme/couple-mordern.jpg")
                        : userProfileDetails.profile_theme == "music-boy"
                        ? require("../assets/images/theme/music-boy.jpg")
                        : userProfileDetails.profile_theme == "couple-sunset"
                        ? require("../assets/images/theme/couple-sunset.jpg")
                        : userProfileDetails.profile_theme == "yellow-texture"
                        ? require("../assets/images/theme/yellow-texture.jpg")
                        : userProfileDetails.profile_theme == "anime"
                        ? require("../assets/images/theme/anime.jpg")
                        : userProfileDetails.profile_theme == "makeup"
                        ? require("../assets/images/theme/makeup.jpg")
                        : userProfileDetails.profile_theme == "flower-spring"
                        ? require("../assets/images/theme/flower-spring.jpg")
                        : userProfileDetails.profile_theme == "anime-desk"
                        ? require("../assets/images/theme/anime-desk.jpg")
                        : userProfileDetails.profile_theme == "jupiter"
                        ? require("../assets/images/theme/jupiter.jpg")
                        : userProfileDetails.profile_theme == "navratri-one"
                        ? require("../assets/images/theme/navratri-one.jpg")
                        : userProfileDetails.profile_theme == "ram-one"
                        ? require("../assets/images/theme/ram-one.jpg")
                        : userProfileDetails.profile_theme == "ram-five"
                        ? require("../assets/images/theme/ram-five.jpg")
                        : userProfileDetails.profile_theme == "student"
                        ? require("../assets/images/theme/student.jpg")
                        : userProfileDetails.profile_theme == "valentine-two"
                        ? require("../assets/images/theme/valentine-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-four"
                        ? require("../assets/images/theme/valentine-four.jpg")
                        : userProfileDetails.profile_theme == "holi-one"
                        ? require("../assets/images/theme/holi-one.jpg")
                        : userProfileDetails.profile_theme == "india-one"
                        ? require("../assets/images/theme/india-one.jpg")
                        : userProfileDetails.profile_theme == "ram-two"
                        ? require("../assets/images/theme/ram-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-three"
                        ? require("../assets/images/theme/valentine-three.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-two"
                        ? require("../assets/images/theme/ayodhya-two.jpg")
                        : userProfileDetails.profile_theme == "masjid-one"
                        ? require("../assets/images/theme/masjid-one.jpg")
                        : userProfileDetails.profile_theme == "ram-four"
                        ? require("../assets/images/theme/ram-four.jpg")
                        : userProfileDetails.profile_theme == "ram-three"
                        ? require("../assets/images/theme/ram-three.jpg")
                        : userProfileDetails.profile_theme == "valentine-one"
                        ? require("../assets/images/theme/valentine-one.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-one"
                        ? require("../assets/images/theme/ayodhya-one.jpg")
                        : require("../assets/images/theme/default.jpg")
                    }
                  />
                </View>
              ) : (
                <LottieView
                  source={
                    userProfileDetails.profile_animation == "mountain"
                      ? require("../assets/lottie/mountain.json")
                      : userProfileDetails.profile_animation == "night"
                      ? require("../assets/lottie/night.json")
                      : userProfileDetails.profile_animation == "train"
                      ? require("../assets/lottie/train.json")
                      : userProfileDetails.profile_animation == "road"
                      ? require("../assets/lottie/road-test.json")
                      : require("../assets/lottie/ocean.json")
                  }
                  autoPlay
                  loop
                  resizeMode="cover"
                />
              )}
            </View>
          )}
          {userProfileDetails && (
            <Tab.Navigator
              sceneContainerStyle={{ backgroundColor: "transparent" }}
              //initialRouteName="products"
              screenOptions={{
                swipeEnabled: false,
                tabBarLabelStyle: {
                  fontSize: 13,
                  fontFamily: "MontserratSemiBold",
                  color:
                    userProfileDetails.profile_animation != "default"
                      ? userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                        ? colors.white
                        : colors.black
                      : userProfileDetails.profile_theme == "purple" ||
                        userProfileDetails.profile_theme == "night-girl" ||
                        userProfileDetails.profile_theme == "couple-winter" ||
                        userProfileDetails.profile_theme == "planet" ||
                        userProfileDetails.profile_theme == "space" ||
                        userProfileDetails.profile_theme == "couple-umbrella" ||
                        userProfileDetails.profile_theme == "alone-night" ||
                        userProfileDetails.profile_theme == "feather" ||
                        userProfileDetails.profile_theme == "anime-night" ||
                        userProfileDetails.profile_theme == "flower-girl" ||
                        userProfileDetails.profile_theme == "couple-mordern" ||
                        userProfileDetails.profile_theme == "music-boy" ||
                        userProfileDetails.profile_theme == "couple-sunset" ||
                        userProfileDetails.profile_theme == "anime" ||
                        userProfileDetails.profile_theme == "ram-one" ||
                        userProfileDetails.profile_theme == "ram-five" ||
                        userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                      ? colors.white
                      : colors.black,
                },
                tabBarStyle: {
                  backgroundColor: "transparent",
                  elevation: 0,
                },
                tabBarIndicatorStyle: { backgroundColor: colors.white },
              }}
            >
              {userProfileDetails.store_tab && (
                <Tab.Screen
                  name="products"
                  component={StoreStack}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Products",
                  }}
                />
              )}
              {userProfileDetails.affiliate_tab && (
                <Tab.Screen
                  name="affiliate"
                  component={ProductsScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Affiliate",
                  }}
                />
              )}
              {userProfileDetails.link_tab && (
                <Tab.Screen
                  name="links"
                  component={LinksScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Links",
                  }}
                />
              )}
            </Tab.Navigator>
          )}
        </View>
      </View>
    );
  } else if (
    tabFirstLabel == "Links" &&
    tabSecondLabel == "Affiliate" &&
    tabThirdLabel == "Store"
  ) {
    return (
      <View style={styles.container}>
        <SEOSection
          title={
            userProfileDetails.firstname + " " + userProfileDetails.lastname
          }
          description={userProfileDetails.profile_bio}
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 10,
            height: (Dimensions.get("window").height / 10) * 10,
            position: "absolute",
            opacity: 1,
          }}
        >
          <Image
            style={styles.backgroundMain}
            source={require("../assets/images/mental-health.jpg")}
          />
        </View>
        <View style={styles.mainContainer}>
          {userProfileDetails && (
            <View style={styles.background}>
              {userProfileDetails.profile_animation == "default" ||
              userProfileDetails.profile_animation == null ||
              userProfileDetails.profile_animation == undefined ? (
                <View style={styles.imageBackground}>
                  <Image
                    style={styles.imageBackground}
                    source={
                      userProfileDetails.profile_theme == "purple"
                        ? require("../assets/images/theme/purple.jpg")
                        : userProfileDetails.profile_theme == "rainbow-pattern"
                        ? require("../assets/images/theme/rainbow-pattern.jpg")
                        : userProfileDetails.profile_theme == "sketch-pattern"
                        ? require("../assets/images/theme/sketch-pattern.jpg")
                        : userProfileDetails.profile_theme == "water-color"
                        ? require("../assets/images/theme/water-color.jpg")
                        : userProfileDetails.profile_theme == "night-girl"
                        ? require("../assets/images/theme/night-girl.jpg")
                        : userProfileDetails.profile_theme == "mandala"
                        ? require("../assets/images/theme/mandala.jpg")
                        : userProfileDetails.profile_theme == "couple-winter"
                        ? require("../assets/images/theme/couple-winter.jpg")
                        : userProfileDetails.profile_theme == "cloud"
                        ? require("../assets/images/theme/cloud.jpg")
                        : userProfileDetails.profile_theme == "planet"
                        ? require("../assets/images/theme/planet.jpg")
                        : userProfileDetails.profile_theme == "forest-cloudy"
                        ? require("../assets/images/theme/forest-cloudy.jpg")
                        : userProfileDetails.profile_theme == "rainbow-cloud"
                        ? require("../assets/images/theme/rainbow-cloud.jpg")
                        : userProfileDetails.profile_theme == "peach-flower"
                        ? require("../assets/images/theme/peach-flower.jpg")
                        : userProfileDetails.profile_theme == "wood-texture"
                        ? require("../assets/images/theme/wood-texture.jpg")
                        : userProfileDetails.profile_theme == "flower"
                        ? require("../assets/images/theme/flower.jpg")
                        : userProfileDetails.profile_theme == "space"
                        ? require("../assets/images/theme/space.jpg")
                        : userProfileDetails.profile_theme == "couple-umbrella"
                        ? require("../assets/images/theme/couple-umbrella.jpg")
                        : userProfileDetails.profile_theme == "alone-night"
                        ? require("../assets/images/theme/alone-night.jpg")
                        : userProfileDetails.profile_theme == "city-dark"
                        ? require("../assets/images/theme/city-dark.jpg")
                        : userProfileDetails.profile_theme == "pattern-light"
                        ? require("../assets/images/theme/pattern-light.jpg")
                        : userProfileDetails.profile_theme == "cloud-sunset"
                        ? require("../assets/images/theme/cloud-sunset.jpg")
                        : userProfileDetails.profile_theme == "leaves-spring"
                        ? require("../assets/images/theme/leaves-spring.jpg")
                        : userProfileDetails.profile_theme == "feather"
                        ? require("../assets/images/theme/feather.jpg")
                        : userProfileDetails.profile_theme == "anime-night"
                        ? require("../assets/images/theme/anime-night.jpg")
                        : userProfileDetails.profile_theme == "couple-rain"
                        ? require("../assets/images/theme/couple-rain.jpg")
                        : userProfileDetails.profile_theme == "flower-girl"
                        ? require("../assets/images/theme/flower-girl.jpg")
                        : userProfileDetails.profile_theme == "flower-autumn"
                        ? require("../assets/images/theme/flower-autumn.jpg")
                        : userProfileDetails.profile_theme == "couple-mordern"
                        ? require("../assets/images/theme/couple-mordern.jpg")
                        : userProfileDetails.profile_theme == "music-boy"
                        ? require("../assets/images/theme/music-boy.jpg")
                        : userProfileDetails.profile_theme == "couple-sunset"
                        ? require("../assets/images/theme/couple-sunset.jpg")
                        : userProfileDetails.profile_theme == "yellow-texture"
                        ? require("../assets/images/theme/yellow-texture.jpg")
                        : userProfileDetails.profile_theme == "anime"
                        ? require("../assets/images/theme/anime.jpg")
                        : userProfileDetails.profile_theme == "makeup"
                        ? require("../assets/images/theme/makeup.jpg")
                        : userProfileDetails.profile_theme == "flower-spring"
                        ? require("../assets/images/theme/flower-spring.jpg")
                        : userProfileDetails.profile_theme == "anime-desk"
                        ? require("../assets/images/theme/anime-desk.jpg")
                        : userProfileDetails.profile_theme == "jupiter"
                        ? require("../assets/images/theme/jupiter.jpg")
                        : userProfileDetails.profile_theme == "navratri-one"
                        ? require("../assets/images/theme/navratri-one.jpg")
                        : userProfileDetails.profile_theme == "ram-one"
                        ? require("../assets/images/theme/ram-one.jpg")
                        : userProfileDetails.profile_theme == "ram-five"
                        ? require("../assets/images/theme/ram-five.jpg")
                        : userProfileDetails.profile_theme == "student"
                        ? require("../assets/images/theme/student.jpg")
                        : userProfileDetails.profile_theme == "valentine-two"
                        ? require("../assets/images/theme/valentine-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-four"
                        ? require("../assets/images/theme/valentine-four.jpg")
                        : userProfileDetails.profile_theme == "holi-one"
                        ? require("../assets/images/theme/holi-one.jpg")
                        : userProfileDetails.profile_theme == "india-one"
                        ? require("../assets/images/theme/india-one.jpg")
                        : userProfileDetails.profile_theme == "ram-two"
                        ? require("../assets/images/theme/ram-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-three"
                        ? require("../assets/images/theme/valentine-three.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-two"
                        ? require("../assets/images/theme/ayodhya-two.jpg")
                        : userProfileDetails.profile_theme == "masjid-one"
                        ? require("../assets/images/theme/masjid-one.jpg")
                        : userProfileDetails.profile_theme == "ram-four"
                        ? require("../assets/images/theme/ram-four.jpg")
                        : userProfileDetails.profile_theme == "ram-three"
                        ? require("../assets/images/theme/ram-three.jpg")
                        : userProfileDetails.profile_theme == "valentine-one"
                        ? require("../assets/images/theme/valentine-one.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-one"
                        ? require("../assets/images/theme/ayodhya-one.jpg")
                        : require("../assets/images/theme/default.jpg")
                    }
                  />
                </View>
              ) : (
                <LottieView
                  source={
                    userProfileDetails.profile_animation == "mountain"
                      ? require("../assets/lottie/mountain.json")
                      : userProfileDetails.profile_animation == "night"
                      ? require("../assets/lottie/night.json")
                      : userProfileDetails.profile_animation == "train"
                      ? require("../assets/lottie/train.json")
                      : userProfileDetails.profile_animation == "road"
                      ? require("../assets/lottie/road-test.json")
                      : require("../assets/lottie/ocean.json")
                  }
                  autoPlay
                  loop
                  resizeMode="cover"
                />
              )}
            </View>
          )}
          {userProfileDetails && (
            <Tab.Navigator
              sceneContainerStyle={{ backgroundColor: "transparent" }}
              //initialRouteName="products"
              screenOptions={{
                swipeEnabled: false,
                tabBarLabelStyle: {
                  fontSize: 13,
                  fontFamily: "MontserratSemiBold",
                  color:
                    userProfileDetails.profile_animation != "default"
                      ? userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                        ? colors.white
                        : colors.black
                      : userProfileDetails.profile_theme == "purple" ||
                        userProfileDetails.profile_theme == "night-girl" ||
                        userProfileDetails.profile_theme == "couple-winter" ||
                        userProfileDetails.profile_theme == "planet" ||
                        userProfileDetails.profile_theme == "space" ||
                        userProfileDetails.profile_theme == "couple-umbrella" ||
                        userProfileDetails.profile_theme == "alone-night" ||
                        userProfileDetails.profile_theme == "feather" ||
                        userProfileDetails.profile_theme == "anime-night" ||
                        userProfileDetails.profile_theme == "flower-girl" ||
                        userProfileDetails.profile_theme == "couple-mordern" ||
                        userProfileDetails.profile_theme == "music-boy" ||
                        userProfileDetails.profile_theme == "couple-sunset" ||
                        userProfileDetails.profile_theme == "anime" ||
                        userProfileDetails.profile_theme == "ram-one" ||
                        userProfileDetails.profile_theme == "ram-five" ||
                        userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                      ? colors.white
                      : colors.black,
                },
                tabBarStyle: {
                  backgroundColor: "transparent",
                  elevation: 0,
                },
                tabBarIndicatorStyle: { backgroundColor: colors.white },
              }}
            >
              {userProfileDetails.link_tab && (
                <Tab.Screen
                  name="links"
                  component={LinksScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Links",
                  }}
                />
              )}
              {userProfileDetails.affiliate_tab && (
                <Tab.Screen
                  name="affiliate"
                  component={ProductsScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Affiliate",
                  }}
                />
              )}
              {userProfileDetails.store_tab && (
                <Tab.Screen
                  name="products"
                  component={StoreStack}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Products",
                  }}
                />
              )}
            </Tab.Navigator>
          )}
        </View>
      </View>
    );
  } else if (
    tabFirstLabel == "Links" &&
    tabSecondLabel == "Store" &&
    tabThirdLabel == "Affiliate"
  ) {
    return (
      <View style={styles.container}>
        <SEOSection
          title={
            userProfileDetails.firstname + " " + userProfileDetails.lastname
          }
          description={userProfileDetails.profile_bio}
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 10,
            height: (Dimensions.get("window").height / 10) * 10,
            position: "absolute",
            opacity: 1,
          }}
        >
          <Image
            style={styles.backgroundMain}
            source={require("../assets/images/mental-health.jpg")}
          />
        </View>
        <View style={styles.mainContainer}>
          {userProfileDetails && (
            <View style={styles.background}>
              {userProfileDetails.profile_animation == "default" ||
              userProfileDetails.profile_animation == null ||
              userProfileDetails.profile_animation == undefined ? (
                <View style={styles.imageBackground}>
                  <Image
                    style={styles.imageBackground}
                    source={
                      userProfileDetails.profile_theme == "purple"
                        ? require("../assets/images/theme/purple.jpg")
                        : userProfileDetails.profile_theme == "rainbow-pattern"
                        ? require("../assets/images/theme/rainbow-pattern.jpg")
                        : userProfileDetails.profile_theme == "sketch-pattern"
                        ? require("../assets/images/theme/sketch-pattern.jpg")
                        : userProfileDetails.profile_theme == "water-color"
                        ? require("../assets/images/theme/water-color.jpg")
                        : userProfileDetails.profile_theme == "night-girl"
                        ? require("../assets/images/theme/night-girl.jpg")
                        : userProfileDetails.profile_theme == "mandala"
                        ? require("../assets/images/theme/mandala.jpg")
                        : userProfileDetails.profile_theme == "couple-winter"
                        ? require("../assets/images/theme/couple-winter.jpg")
                        : userProfileDetails.profile_theme == "cloud"
                        ? require("../assets/images/theme/cloud.jpg")
                        : userProfileDetails.profile_theme == "planet"
                        ? require("../assets/images/theme/planet.jpg")
                        : userProfileDetails.profile_theme == "forest-cloudy"
                        ? require("../assets/images/theme/forest-cloudy.jpg")
                        : userProfileDetails.profile_theme == "rainbow-cloud"
                        ? require("../assets/images/theme/rainbow-cloud.jpg")
                        : userProfileDetails.profile_theme == "peach-flower"
                        ? require("../assets/images/theme/peach-flower.jpg")
                        : userProfileDetails.profile_theme == "wood-texture"
                        ? require("../assets/images/theme/wood-texture.jpg")
                        : userProfileDetails.profile_theme == "flower"
                        ? require("../assets/images/theme/flower.jpg")
                        : userProfileDetails.profile_theme == "space"
                        ? require("../assets/images/theme/space.jpg")
                        : userProfileDetails.profile_theme == "couple-umbrella"
                        ? require("../assets/images/theme/couple-umbrella.jpg")
                        : userProfileDetails.profile_theme == "alone-night"
                        ? require("../assets/images/theme/alone-night.jpg")
                        : userProfileDetails.profile_theme == "city-dark"
                        ? require("../assets/images/theme/city-dark.jpg")
                        : userProfileDetails.profile_theme == "pattern-light"
                        ? require("../assets/images/theme/pattern-light.jpg")
                        : userProfileDetails.profile_theme == "cloud-sunset"
                        ? require("../assets/images/theme/cloud-sunset.jpg")
                        : userProfileDetails.profile_theme == "leaves-spring"
                        ? require("../assets/images/theme/leaves-spring.jpg")
                        : userProfileDetails.profile_theme == "feather"
                        ? require("../assets/images/theme/feather.jpg")
                        : userProfileDetails.profile_theme == "anime-night"
                        ? require("../assets/images/theme/anime-night.jpg")
                        : userProfileDetails.profile_theme == "couple-rain"
                        ? require("../assets/images/theme/couple-rain.jpg")
                        : userProfileDetails.profile_theme == "flower-girl"
                        ? require("../assets/images/theme/flower-girl.jpg")
                        : userProfileDetails.profile_theme == "flower-autumn"
                        ? require("../assets/images/theme/flower-autumn.jpg")
                        : userProfileDetails.profile_theme == "couple-mordern"
                        ? require("../assets/images/theme/couple-mordern.jpg")
                        : userProfileDetails.profile_theme == "music-boy"
                        ? require("../assets/images/theme/music-boy.jpg")
                        : userProfileDetails.profile_theme == "couple-sunset"
                        ? require("../assets/images/theme/couple-sunset.jpg")
                        : userProfileDetails.profile_theme == "yellow-texture"
                        ? require("../assets/images/theme/yellow-texture.jpg")
                        : userProfileDetails.profile_theme == "anime"
                        ? require("../assets/images/theme/anime.jpg")
                        : userProfileDetails.profile_theme == "makeup"
                        ? require("../assets/images/theme/makeup.jpg")
                        : userProfileDetails.profile_theme == "flower-spring"
                        ? require("../assets/images/theme/flower-spring.jpg")
                        : userProfileDetails.profile_theme == "anime-desk"
                        ? require("../assets/images/theme/anime-desk.jpg")
                        : userProfileDetails.profile_theme == "jupiter"
                        ? require("../assets/images/theme/jupiter.jpg")
                        : userProfileDetails.profile_theme == "navratri-one"
                        ? require("../assets/images/theme/navratri-one.jpg")
                        : userProfileDetails.profile_theme == "ram-one"
                        ? require("../assets/images/theme/ram-one.jpg")
                        : userProfileDetails.profile_theme == "ram-five"
                        ? require("../assets/images/theme/ram-five.jpg")
                        : userProfileDetails.profile_theme == "student"
                        ? require("../assets/images/theme/student.jpg")
                        : userProfileDetails.profile_theme == "valentine-two"
                        ? require("../assets/images/theme/valentine-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-four"
                        ? require("../assets/images/theme/valentine-four.jpg")
                        : userProfileDetails.profile_theme == "holi-one"
                        ? require("../assets/images/theme/holi-one.jpg")
                        : userProfileDetails.profile_theme == "india-one"
                        ? require("../assets/images/theme/india-one.jpg")
                        : userProfileDetails.profile_theme == "ram-two"
                        ? require("../assets/images/theme/ram-two.jpg")
                        : userProfileDetails.profile_theme == "valentine-three"
                        ? require("../assets/images/theme/valentine-three.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-two"
                        ? require("../assets/images/theme/ayodhya-two.jpg")
                        : userProfileDetails.profile_theme == "masjid-one"
                        ? require("../assets/images/theme/masjid-one.jpg")
                        : userProfileDetails.profile_theme == "ram-four"
                        ? require("../assets/images/theme/ram-four.jpg")
                        : userProfileDetails.profile_theme == "ram-three"
                        ? require("../assets/images/theme/ram-three.jpg")
                        : userProfileDetails.profile_theme == "valentine-one"
                        ? require("../assets/images/theme/valentine-one.jpg")
                        : userProfileDetails.profile_theme == "ayodhya-one"
                        ? require("../assets/images/theme/ayodhya-one.jpg")
                        : require("../assets/images/theme/default.jpg")
                    }
                  />
                </View>
              ) : (
                <LottieView
                  source={
                    userProfileDetails.profile_animation == "mountain"
                      ? require("../assets/lottie/mountain.json")
                      : userProfileDetails.profile_animation == "night"
                      ? require("../assets/lottie/night.json")
                      : userProfileDetails.profile_animation == "train"
                      ? require("../assets/lottie/train.json")
                      : userProfileDetails.profile_animation == "road"
                      ? require("../assets/lottie/road-test.json")
                      : require("../assets/lottie/ocean.json")
                  }
                  autoPlay
                  loop
                  resizeMode="cover"
                />
              )}
            </View>
          )}
          {userProfileDetails && (
            <Tab.Navigator
              sceneContainerStyle={{ backgroundColor: "transparent" }}
              //initialRouteName="products"
              screenOptions={{
                swipeEnabled: false,
                tabBarLabelStyle: {
                  fontSize: 13,
                  fontFamily: "MontserratSemiBold",
                  color:
                    userProfileDetails.profile_animation != "default"
                      ? userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                        ? colors.white
                        : colors.black
                      : userProfileDetails.profile_theme == "purple" ||
                        userProfileDetails.profile_theme == "night-girl" ||
                        userProfileDetails.profile_theme == "couple-winter" ||
                        userProfileDetails.profile_theme == "planet" ||
                        userProfileDetails.profile_theme == "space" ||
                        userProfileDetails.profile_theme == "couple-umbrella" ||
                        userProfileDetails.profile_theme == "alone-night" ||
                        userProfileDetails.profile_theme == "feather" ||
                        userProfileDetails.profile_theme == "anime-night" ||
                        userProfileDetails.profile_theme == "flower-girl" ||
                        userProfileDetails.profile_theme == "couple-mordern" ||
                        userProfileDetails.profile_theme == "music-boy" ||
                        userProfileDetails.profile_theme == "couple-sunset" ||
                        userProfileDetails.profile_theme == "anime" ||
                        userProfileDetails.profile_theme == "ram-one" ||
                        userProfileDetails.profile_theme == "ram-five" ||
                        userProfileDetails.profile_animation == "night" ||
                        userProfileDetails.profile_animation == "ocean"
                      ? colors.white
                      : colors.black,
                },
                tabBarStyle: {
                  backgroundColor: "transparent",
                  elevation: 0,
                },
                tabBarIndicatorStyle: { backgroundColor: colors.white },
              }}
            >
              {userProfileDetails.link_tab && (
                <Tab.Screen
                  name="links"
                  component={LinksScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Links",
                  }}
                />
              )}
              {userProfileDetails.store_tab && (
                <Tab.Screen
                  name="products"
                  component={StoreStack}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Products",
                  }}
                />
              )}
              {userProfileDetails.affiliate_tab && (
                <Tab.Screen
                  name="affiliate"
                  component={ProductsScreen}
                  initialParams={{ id: route.params.id }}
                  options={{
                    tabBarLabel: "Affiliate",
                  }}
                />
              )}
              {!userProfileDetails.link_tab &&
                !userProfileDetails.affiliate_tab &&
                !userProfileDetails.store_tab && (
                  <Tab.Screen
                    name="inactive"
                    component={InactiveTab}
                    //initialParams={{ id: route.params.id }}
                    options={{
                      tabBarLabel: "Inactive Account",
                    }}
                  />
                )}
            </Tab.Navigator>
          )}
        </View>
      </View>
    );
  }
};

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: (Dimensions.get("window").width / 10) * 10,
      height: (Dimensions.get("window").height / 10) * 10,
      //backgroundColor: colors.peach,
      alignItems: "center",
      overflow: "hidden",
    },
    mainContainer: {
      width: (Dimensions.get("window").width / 10) * 3.5,
      height: (Dimensions.get("window").height / 10) * 10,
      justifyContent: "center",
      backgroundColor: colors.neomorph,
    },
    backgroundMain: {
      width: (Dimensions.get("window").width / 10) * 10,
      height: (Dimensions.get("window").height / 10) * 10,
      position: "absolute",
    },
    background: {
      width: (Dimensions.get("window").width / 10) * 3.5,
      position: "absolute",
      //backgroundColor: colors.red,
    },
    imageBackground: {
      width: (Dimensions.get("window").width / 10) * 3.5,
      height: (Dimensions.get("window").height / 10) * 10,
    },
  },
  {
    [DEVICE_SIZES.XS]: {
      mainContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
      background: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
      imageBackground: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
    },
  },
  {
    [DEVICE_SIZES.SM]: {
      mainContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
      background: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
      imageBackground: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
    },
  }
);

export default ProfileTab;
