const pageContent = [
  {
    id: 1,
    title: "",
    para: "If you have questions, feedback, or need assistance, please don't hesitate to reach out to our customer support team. We're here to ensure that your LinksApp experience is as smooth and rewarding as possible.",
  },
  {
    id: 2,
    title: "",
    para: "Thank you for choosing LinksApp for your link management needs. We're excited to be part of your online journey and provide you with the tools to enhance your digital presence.",
  },
];

module.exports = {
  pageContent: pageContent,
};
