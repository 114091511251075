import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  Modal,
  Dimensions,
  Image,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  ScrollView,
  Linking,
} from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import colorsList from "../constants/colors/colorsList.json";
import {
  EXPO_PUBLIC_API_URL,
  EXPO_PUBLIC_API_KEY,
  EXPO_PUBLIC_BASE_URL,
} from "@env";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";
import alert from "../components/webAlert";
import colors from "../constants/theme/colors";
import StoreCard from "../components/StoreProduct";
import * as yup from "yup";
import { Formik } from "formik";

const StoreCheckoutScreen = (props) => {
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState();
  const [userIdFinalImage, setUserIdFinalImage] = useState();
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [placingOrder, setPlacingOrder] = useState(false);
  const deviceSize = useDeviceSize();

  //console.log(props.route.params);
  const productId = props.route.params.item;
  const selectedSize = props.route.params.size;

  useEffect(() => {
    const fetchUserDetails = async (props) => {
      const productDetails = await getProductDetails(productId);
      if (productDetails.success) {
        setProductDetails(productDetails.productDetails);
        const productImage =
          EXPO_PUBLIC_BASE_URL +
          productDetails.productFolderPath +
          productDetails.productDetails.product_image;

        setUserIdFinalImage(productImage);
      } else {
        props.navigation.navigate("products");
      }
    };
    fetchUserDetails();
    setInterval(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getProductDetails = async (productId) => {
    //console.log(selectedCategory);
    try {
      const apiResult = await fetch(
        `${EXPO_PUBLIC_API_URL}users/getProductDetails?id=${productId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "ps-key": EXPO_PUBLIC_API_KEY,
          },
        }
      );
      const resData = await apiResult.json();
      //console.log(resData);
      return resData;
    } catch (error) {
      //console.log(error);
    }
  };

  const getColorCode = (colorName) => {
    const colorObject = colorsList.find((color) => color.city === colorName);
    return colorObject ? colorObject.code : "#000000";
  };

  const quantityHandler = (quan) => {
    if (quan == "add") {
      setQuantity((quantity) => quantity + 1);
    } else {
      if (quantity == 1) {
        props.navigation.goBack();
      }
      setQuantity((quantity) => quantity - 1);
    }
  };

  const placeOrderHandler = async (props) => {
    setPlacingOrder(true);
    // console.log(props);
    // console.log(productId.toString());
    let formData = new FormData();
    formData.append("product_id", productId);
    formData.append("product_quantity", quantity);
    formData.append("product_size", selectedSize);
    formData.append("customer_name", props.userName);
    formData.append("customer_address", props.userAddress);
    formData.append("customer_city", props.userCity);
    formData.append("customer_state", props.userState);
    formData.append("customer_pin", props.userPin);
    formData.append("customer_email", props.userEmail);
    formData.append("customer_phone", props.userPhone);

    try {
      const apiResult = await fetch(
        `${EXPO_PUBLIC_API_URL}users/createStoreOrder`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "ps-key": EXPO_PUBLIC_API_KEY,
          },
          body: formData,
        }
      );

      const resData = await apiResult.json();
      //console.log(resData);
      if (resData.success) {
        setPlacingOrder(false);
        setOrderCompleted(true);
      } else {
        setPlacingOrder(false);
      }
    } catch (error) {
      //console.log(error);
      setPlacingOrder(false);
      //crashlytics().recordError(error);
    }
  };

  if (loading) {
    if (deviceSize === "extralarge") {
      return (
        <View
          style={{
            flex: 1,
            width: (Dimensions.get("window").width / 10) * 3.5,
            height: (Dimensions.get("window").height / 10) * 10,
            backgroundColor: colors.storeLightGreen,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator color={colors.black} size={24} />
        </View>
      );
    } else {
      return (
        <View style={styles.container}>
          <ActivityIndicator color={colors.black} size={24} />
        </View>
      );
    }
  } else {
    if (deviceSize === "extralarge") {
      return (
        <SafeAreaView
          style={{
            flex: 1,
            width: (Dimensions.get("window").width / 10) * 3.5,
            backgroundColor: colors.storeLightGreen,
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={{
              userName: "",
              userAddress: "",
              userCity: "",
              userState: "",
              userPin: "",
              userPhone: "",
              userEmail: "",
            }}
            onSubmit={placeOrderHandler}
            validationSchema={yup.object().shape({
              userName: yup.string().required("Name is required"),
              userAddress: yup.string().required("Address is required"),
              userCity: yup.string().required("City is required"),
              userState: yup.string().required("State is required"),
              userPin: yup
                .string()
                .required("Pin is required")
                .min(6, ({ min }) => `Pin must be 6-digit long`)
                .max(6, ({ max }) => `Pin must be 6-digit long`),
              userPhone: yup
                .string()
                .min(10, ({ min }) => `Enter a valid phone number`)
                .max(10, ({ min }) => `Enter a valid phone number`)
                .required("Phone number is required"),
              userEmail: yup
                .string()
                .email("Please enter valid email id")
                .required("Email is required"),
            })}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              setFieldTouched,
              touched,
              isValid,
              validateOnMount = "true",
              isInitialValid = false,
              handleSubmit,
              setFieldValue,
              dirty,
            }) => (
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                  width: (Dimensions.get("window").width / 10) * 3.5,
                  //backgroundColor: "#ff0066",
                }}
              >
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3.5,
                    //height: (Dimensions.get("window").width / 10) * 4,
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 3.3,
                      //backgroundColor: colors.green,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <StoreCard margin={10} padding={10}>
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 3.05,
                          flexDirection: "row",
                          //backgroundColor: colors.peach,
                        }}
                      >
                        <View
                          style={{
                            width: (Dimensions.get("window").width / 10) * 0.8,
                            //backgroundColor: colors.coinBackground,
                            borderRadius: 5,
                            overflow: "hidden",
                          }}
                        >
                          <Image
                            style={{
                              width:
                                (Dimensions.get("window").width / 10) * 0.8,
                              height:
                                (Dimensions.get("window").width / 10) * 1.064,
                            }}
                            source={{
                              uri: userIdFinalImage,
                            }}
                          />
                        </View>
                        <View
                          style={{
                            width: (Dimensions.get("window").width / 10) * 2.25,
                            paddingHorizontal: 10,
                            //backgroundColor: colors.red,
                          }}
                        >
                          <View>
                            <Text
                              numberOfLines={1}
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 10,
                                color: colors.grey,
                              }}
                            >
                              {productDetails.product_brand}
                            </Text>
                            <Text
                              numberOfLines={1}
                              style={{
                                fontFamily: "MontserratSemiBold",
                                fontSize: 15,
                                color: colors.black,
                              }}
                            >
                              {productDetails.product_title}
                            </Text>
                            <Text
                              numberOfLines={1}
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 10,
                                color: colors.grey,
                              }}
                            >
                              {productDetails.product_description}
                            </Text>
                          </View>
                          <View
                            style={{
                              paddingVertical: 10,
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "MontserratSemiBold",
                                  color: colors.black,
                                  fontSize: 12,
                                  paddingRight: 5,
                                }}
                              >
                                Color:
                              </Text>
                              <View
                                style={{
                                  backgroundColor: getColorCode(
                                    productDetails.product_color.toString()
                                  ),
                                  width: 15,
                                  height: 15,
                                  borderRadius: 10,
                                  borderWidth: 0.5,
                                  borderColor: colors.black,
                                  //marginVertical: 10,
                                }}
                              ></View>
                            </View>
                            <View>
                              <Text
                                style={{
                                  fontFamily: "MontserratSemiBold",
                                  color: colors.black,
                                  fontSize: 12,
                                  paddingRight: 5,
                                }}
                              >
                                Size: {selectedSize}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              borderTopColor: colors.lightgrey,
                              borderTopWidth: 1,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingTop: 10,
                            }}
                          >
                            <View
                              style={{
                                //flexDirection: 'row',
                                alignItems: "flex-start",
                              }}
                            >
                              {productDetails.product_selling_price ? (
                                <Text
                                  style={{
                                    fontFamily: "MontserratSemiBold",
                                    fontSize: 14,
                                    color: colors.black,
                                  }}
                                >
                                  ₹
                                  {productDetails.product_selling_price *
                                    quantity}
                                </Text>
                              ) : (
                                <Text
                                  style={{
                                    fontFamily: "MontserratSemiBold",
                                    fontSize: 14,
                                    color: colors.black,
                                  }}
                                >
                                  ₹{productDetails.product_mrp * quantity}
                                </Text>
                              )}
                            </View>
                            <View
                              style={{
                                width: 100,
                                backgroundColor: colors.lightgrey,
                                borderRadius: 40,
                                paddingVertical: 5,
                                flexDirection: "row",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <TouchableOpacity
                                activeOpacity={0.9}
                                onPress={() => {
                                  quantityHandler("subtract");
                                }}
                              >
                                <Ionicons
                                  name="remove-circle"
                                  color={colors.black}
                                  size={28}
                                />
                              </TouchableOpacity>
                              <View>
                                <Text
                                  style={{
                                    fontFamily: "MontserratSemiBold",
                                    fontSize: 14,
                                    color: colors.black,
                                  }}
                                >
                                  {quantity}
                                </Text>
                              </View>
                              <TouchableOpacity
                                activeOpacity={0.9}
                                onPress={() => {
                                  quantityHandler("add");
                                }}
                              >
                                <Ionicons
                                  name="add-circle"
                                  color={colors.black}
                                  size={28}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </StoreCard>
                  </View>
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 3.2,
                      //backgroundColor: colors.green,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 3.2,
                        paddingVertical: 10,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          fontSize: 13,
                          color: colors.black,
                        }}
                      >
                        Shipping Address
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 3.2,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <StoreCard margin={10} padding={10}>
                      <View style={styles.xlcardMainContainer}>
                        <View style={styles.xlformTitleContainer}>
                          <View style={styles.xltitleTextContainer}>
                            <Text
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: colors.black,
                              }}
                            >
                              Name
                            </Text>
                          </View>
                          <View style={styles.xltextInputContainer}>
                            <View style={styles.xlinputContainer}>
                              <TextInput
                                //placeholder="https://www.facebook.com/example"
                                style={styles.textInput}
                                value={values.userName}
                                onChangeText={handleChange("userName")}
                                onBlur={() => setFieldTouched("userName")}
                                maxLength={1000}
                                //placeholderTextColor={colors.grey}
                                keyboardType="default"
                                //numberOfLines={4}
                              />
                            </View>
                          </View>
                          <View style={styles.xlerrorContainer}>
                            {touched.userName && errors.userName && (
                              <Text style={styles.xlerrorTextContainer}>
                                {errors.userName}
                              </Text>
                            )}
                          </View>
                        </View>
                        <View style={styles.xlformTitleContainer}>
                          <View style={styles.xltitleTextContainer}>
                            <Text
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: colors.black,
                              }}
                            >
                              Address
                            </Text>
                          </View>
                          <View style={styles.xltextInputContainer}>
                            <View style={styles.xlinputContainer}>
                              <TextInput
                                //placeholder="https://www.facebook.com/example"
                                style={styles.textInput}
                                value={values.userAddress}
                                onChangeText={handleChange("userAddress")}
                                onBlur={() => setFieldTouched("userAddress")}
                                maxLength={1000}
                                //placeholderTextColor={colors.grey}
                                keyboardType="default"
                                //numberOfLines={4}
                              />
                            </View>
                          </View>
                          <View style={styles.xlerrorContainer}>
                            {touched.userAddress && errors.userAddress && (
                              <Text style={styles.xlerrorTextContainer}>
                                {errors.userAddress}
                              </Text>
                            )}
                          </View>
                        </View>
                        <View style={styles.xlformTitleContainer}>
                          <View style={styles.xltitleTextContainer}>
                            <Text
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: colors.black,
                              }}
                            >
                              City
                            </Text>
                          </View>
                          <View style={styles.xltextInputContainer}>
                            <View style={styles.xlinputContainer}>
                              <TextInput
                                //placeholder={userAddress}
                                //placeholder="https://www.instagram.com/example"
                                style={styles.textInput}
                                value={values.userCity}
                                onChangeText={handleChange("userCity")}
                                onBlur={() => setFieldTouched("userCity")}
                                maxLength={1000}
                                //placeholderTextColor={colors.grey}
                                keyboardType="default"
                                //numberOfLines={4}
                              />
                            </View>
                          </View>
                          <View style={styles.xlerrorContainer}>
                            {touched.userCity && errors.userCity && (
                              <Text style={styles.xlerrorTextContainer}>
                                {errors.userCity}
                              </Text>
                            )}
                          </View>
                        </View>
                        <View style={styles.xlformTitleContainer}>
                          <View style={styles.xltitleTextContainer}>
                            <Text
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: colors.black,
                              }}
                            >
                              State
                            </Text>
                          </View>
                          <View style={styles.xltextInputContainer}>
                            <View style={styles.xlinputContainer}>
                              <TextInput
                                //placeholder="https://www.twitter.com/example"
                                style={styles.textInput}
                                value={values.userState}
                                onChangeText={handleChange("userState")}
                                onBlur={() => setFieldTouched("userState")}
                                maxLength={1000}
                                //placeholderTextColor={colors.grey}
                                keyboardType="default"
                              />
                            </View>
                          </View>
                          <View style={styles.xlerrorContainer}>
                            {touched.userState && errors.userState && (
                              <Text style={styles.xlerrorTextContainer}>
                                {errors.userState}
                              </Text>
                            )}
                          </View>
                        </View>
                        <View style={styles.xlformTitleContainer}>
                          <View style={styles.xltitleTextContainer}>
                            <Text
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: colors.black,
                              }}
                            >
                              Pin
                            </Text>
                          </View>
                          <View style={styles.xltextInputContainer}>
                            <View style={styles.xlinputContainer}>
                              <TextInput
                                //placeholder="https://www.youtube.com/example"
                                style={styles.textInput}
                                value={values.userPin}
                                onChangeText={handleChange("userPin")}
                                onBlur={() => setFieldTouched("userPin")}
                                maxLength={1000}
                                //placeholderTextColor={colors.grey}
                                keyboardType="default"
                              />
                            </View>
                          </View>
                          <View style={styles.xlerrorContainer}>
                            {touched.userPin && errors.userPin && (
                              <Text style={styles.xlerrorTextContainer}>
                                {errors.userPin}
                              </Text>
                            )}
                          </View>
                        </View>
                        <View style={styles.xlformTitleContainer}>
                          <View style={styles.xltitleTextContainer}>
                            <Text
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: colors.black,
                              }}
                            >
                              Phone
                            </Text>
                          </View>
                          <View style={styles.xltextInputContainer}>
                            <View style={styles.xlinputContainer}>
                              <TextInput
                                //placeholder="https://www.linkedin.com/example"
                                style={styles.textInput}
                                value={values.userPhone}
                                onChangeText={handleChange("userPhone")}
                                onBlur={() => setFieldTouched("userPhone")}
                                maxLength={1000}
                                //placeholderTextColor={colors.grey}
                                keyboardType="default"
                                //editable={false}
                              />
                            </View>
                          </View>
                          <View style={styles.xlerrorContainer}>
                            {touched.userPhone && errors.userPhone && (
                              <Text style={styles.xlerrorTextContainer}>
                                {errors.userPhone}
                              </Text>
                            )}
                          </View>
                        </View>
                        <View style={styles.xlformTitleContainer}>
                          <View style={styles.xltitleTextContainer}>
                            <Text
                              style={{
                                fontFamily: "MontserratRegular",
                                fontSize: 12,
                                color: colors.black,
                              }}
                            >
                              Email
                            </Text>
                          </View>
                          <View style={styles.xltextInputContainer}>
                            <View style={styles.xlinputContainer}>
                              <TextInput
                                //placeholder="https://www.snapchat.com/example"
                                style={styles.textInput}
                                value={values.userEmail}
                                onChangeText={handleChange("userEmail")}
                                onBlur={() => setFieldTouched("userEmail")}
                                maxLength={1000}
                                //placeholderTextColor={colors.grey}
                                keyboardType="default"
                              />
                            </View>
                          </View>
                          <View style={styles.xlerrorContainer}>
                            {touched.userEmail && errors.userEmail && (
                              <Text style={styles.xlerrorTextContainer}>
                                {errors.userEmail}
                              </Text>
                            )}
                          </View>
                        </View>
                      </View>
                    </StoreCard>
                  </View>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3.5,
                    //backgroundColor: colors.green,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 3.3,
                      paddingVertical: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 13,
                        color: colors.black,
                      }}
                    >
                      Order Summary
                    </Text>
                  </View>
                  <StoreCard padding={10} margin={10}>
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 2.8,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 7,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          fontSize: 13,
                          color: colors.black,
                        }}
                      >
                        MRP
                      </Text>
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          fontSize: 14,
                          color: colors.black,
                        }}
                      >
                        ₹{productDetails.product_mrp * quantity}
                      </Text>
                    </View>
                    {productDetails.product_selling_price && (
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 2.8,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingVertical: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "MontserratRegular",
                            fontSize: 13,
                            color: colors.black,
                          }}
                        >
                          Discount
                        </Text>
                        <Text
                          style={{
                            fontFamily: "MontserratSemiBold",
                            fontSize: 14,
                            color: colors.greenShadow,
                          }}
                        >
                          -₹
                          {productDetails.product_mrp * quantity -
                            productDetails.product_selling_price * quantity}
                        </Text>
                      </View>
                    )}
                    {productDetails.product_selling_price && (
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 2.8,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingVertical: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "MontserratRegular",
                            fontSize: 13,
                            color: colors.black,
                          }}
                        >
                          Sub Total
                        </Text>
                        <Text
                          style={{
                            fontFamily: "MontserratSemiBold",
                            fontSize: 14,
                            color: colors.black,
                          }}
                        >
                          ₹{productDetails.product_selling_price * quantity}
                        </Text>
                      </View>
                    )}
                    {productDetails.product_gst_rate > 0 && (
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 2.8,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingVertical: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "MontserratRegular",
                            fontSize: 13,
                            color: colors.black,
                          }}
                        >
                          Tax (included)
                        </Text>
                        {productDetails.product_selling_price ? (
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.black,
                            }}
                          >
                            ₹
                            {(productDetails.product_gst_rate / 100) *
                              productDetails.product_selling_price *
                              quantity}
                          </Text>
                        ) : (
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.black,
                            }}
                          >
                            ₹
                            {(productDetails.product_gst_rate / 100) *
                              productDetails.product_mrp *
                              quantity}
                          </Text>
                        )}
                      </View>
                    )}
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 2.8,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 7,
                        borderTopWidth: 2,
                        borderTopColor: colors.lightgrey,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          fontSize: 13,
                          color: colors.black,
                        }}
                      >
                        To Pay
                      </Text>
                      {productDetails.product_selling_price ? (
                        <Text
                          style={{
                            fontFamily: "MontserratSemiBold",
                            fontSize: 14,
                            color: colors.unverified,
                          }}
                        >
                          ₹{productDetails.product_selling_price * quantity}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontFamily: "MontserratSemiBold",
                            fontSize: 14,
                            color: colors.black,
                          }}
                        >
                          ₹{productDetails.product_mrp * quantity}
                        </Text>
                      )}
                    </View>
                  </StoreCard>
                  {placingOrder ? (
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 3.2,
                        height: (Dimensions.get("window").width / 10) * 0.4,
                        marginVertical: 10,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: colors.storeDarkGreen,
                        borderRadius: 10,
                        elevation: 5,
                        shadowColor: colors.greenShadow,
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                      }}
                    >
                      <ActivityIndicator size={24} color={colors.white} />
                    </View>
                  ) : (
                    <TouchableOpacity
                      activeOpacity={0.5}
                      disabled={!isValid}
                      onPress={handleSubmit}
                      style={{
                        width: (Dimensions.get("window").width / 10) * 3.2,
                        height: (Dimensions.get("window").width / 10) * 0.4,
                        marginVertical: 10,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: colors.storeDarkGreen,
                        borderRadius: 10,
                        elevation: 5,
                        shadowColor: colors.greenShadow,
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                      }}
                    >
                      <Text style={styles.buttonText}>Place Order</Text>
                    </TouchableOpacity>
                  )}
                  <View
                    style={{
                      paddingBottom: 70,
                    }}
                  ></View>
                </View>
              </ScrollView>
            )}
          </Formik>
          <Modal transparent={true} visible={orderCompleted}>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 10,
                height: (Dimensions.get("window").height / 10) * 10,
                backgroundColor: "transparent",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3.5,
                  height: (Dimensions.get("window").height / 10) * 10,
                  backgroundColor: colors.storeLightGreen,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StoreCard margin={10} padding={10}>
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 3,
                      backgroundColor: colors.storeLightGreen,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="checkmark-circle"
                      color={colors.greenShadow}
                      size={38}
                    />
                  </View>
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 3,
                      backgroundColor: colors.storeLightGreen,
                      alignItems: "center",
                      marginVertical: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "MontserratBold",
                        fontSize: 18,
                        textAlign: "center",
                        color: colors.black,
                      }}
                    >
                      Thank you for your order!
                    </Text>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 13,
                        textAlign: "center",
                        color: colors.grey,
                      }}
                    >
                      The order confirmation has been sent to your email
                      address.
                    </Text>
                  </View>
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 3,
                      backgroundColor: colors.storeLightGreen,
                      alignItems: "center",
                      paddingVertical: 10,
                      flexDirection: "row",
                      justifyContent: "center",
                      borderTopWidth: 1,
                      borderTopColor: colors.lightgrey,
                    }}
                  >
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 0.8,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratBold",
                          fontSize: 15,
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        ₹{productDetails.product_selling_price}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          fontSize: 12,
                          textAlign: "center",
                          color: colors.grey,
                        }}
                      >
                        Amount
                      </Text>
                    </View>
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 0.8,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratBold",
                          fontSize: 15,
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        {quantity}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          fontSize: 12,
                          textAlign: "center",
                          color: colors.grey,
                        }}
                      >
                        Quantity
                      </Text>
                    </View>
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 0.8,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: getColorCode(
                              productDetails.product_color.toString()
                            ),
                            width: 15,
                            height: 15,
                            borderRadius: 10,
                            borderWidth: 0.5,
                            borderColor: colors.black,
                            //marginVertical: 10,
                          }}
                        ></View>
                        <Text
                          style={{
                            fontFamily: "MontserratBold",
                            fontSize: 15,
                            textAlign: "center",
                            color: colors.black,
                          }}
                        >
                          {" "}
                          , {selectedSize}
                        </Text>
                      </View>
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          fontSize: 12,
                          textAlign: "center",
                          color: colors.grey,
                        }}
                      >
                        Variant
                      </Text>
                    </View>
                  </View>
                </StoreCard>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    backgroundColor: colors.storeLightGreen,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 30,
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratBold",
                      fontSize: 18,
                      textAlign: "center",
                      color: colors.black,
                    }}
                  >
                    Verify Your Order
                  </Text>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      fontSize: 13,
                      textAlign: "center",
                      color: colors.grey,
                    }}
                  >
                    To verify your order, connect with the seller.
                  </Text>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    //backgroundColor: colors.peach,
                    alignItems: "center",
                    marginVertical: 10,
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 0.8,
                      alignItems: "center",
                      justifyContent: "center",
                      //backgroundColor: colors.black
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        const whatsAppMsg =
                          "I just placed an order, " +
                          productDetails.product_title +
                          " - " +
                          productDetails.product_color.toUpperCase() +
                          "-" +
                          selectedSize +
                          " from your store. I want to confirm my order.";
                        let url =
                          "http://api.whatsapp.com/send?text=" +
                          whatsAppMsg +
                          "&phone=91" +
                          productDetails.user.mobile;
                        Linking.openURL(url);
                      }}
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 30,
                        backgroundColor: colors.greenShadow,
                        justifyContent: "center",
                        alignItems: "center",
                        elevation: 5,
                        shadowColor: colors.greenShadow,
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                      }}
                    >
                      <Ionicons
                        name="logo-whatsapp"
                        color={colors.white}
                        size={30}
                      />
                    </TouchableOpacity>
                  </View>
                  <TouchableOpacity
                    onPress={() => {
                      let url = "tel:" + productDetails.user.mobile;
                      Linking.openURL(url);
                    }}
                    style={{
                      width: (Dimensions.get("window").width / 10) * 0.8,
                      alignItems: "center",
                      justifyContent: "center",
                      //backgroundColor: colors.black
                    }}
                  >
                    <View
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 30,
                        backgroundColor: "#0E9DF3",
                        justifyContent: "center",
                        alignItems: "center",
                        elevation: 5,
                        shadowColor: colors.greenShadow,
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                      }}
                    >
                      <Ionicons
                        name="call-outline"
                        color={colors.white}
                        size={30}
                      />
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      let url = "mailto:" + productDetails.user.email;
                      Linking.openURL(url);
                    }}
                    style={{
                      width: (Dimensions.get("window").width / 10) * 0.8,
                      alignItems: "center",
                      justifyContent: "center",
                      //backgroundColor: colors.red
                    }}
                  >
                    <View
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 30,
                        backgroundColor: "#FF4122",
                        justifyContent: "center",
                        alignItems: "center",
                        elevation: 5,
                        shadowColor: "#0E9DF3",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                      }}
                    >
                      <Ionicons
                        name="mail-outline"
                        color={colors.white}
                        size={30}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
              {/* <StoreCard> */}
            </View>
          </Modal>
        </SafeAreaView>
      );
    } else {
      return (
        <SafeAreaView style={styles.container}>
          <Formik
            initialValues={{
              userName: "",
              userAddress: "",
              userCity: "",
              userState: "",
              userPin: "",
              userPhone: "",
              userEmail: "",
            }}
            onSubmit={placeOrderHandler}
            validationSchema={yup.object().shape({
              userName: yup.string().required("Name is required"),
              userAddress: yup.string().required("Address is required"),
              userCity: yup.string().required("City is required"),
              userState: yup.string().required("State is required"),
              userPin: yup
                .string()
                .required("Pin is required")
                .min(6, ({ min }) => `Pin must be 6-digit long`)
                .max(6, ({ max }) => `Pin must be 6-digit long`),
              userPhone: yup
                .string()
                .min(10, ({ min }) => `Enter a valid phone number`)
                .max(10, ({ min }) => `Enter a valid phone number`)
                .required("Phone number is required"),
              userEmail: yup
                .string()
                .email("Please enter valid email id")
                .required("Email is required"),
            })}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              setFieldTouched,
              touched,
              isValid,
              validateOnMount = "true",
              isInitialValid = false,
              handleSubmit,
              setFieldValue,
              dirty,
            }) => (
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                  width: (Dimensions.get("window").width / 10) * 9.5,
                }}
              >
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 9.5,
                    //backgroundColor: colors.green,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StoreCard margin={10} padding={10}>
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 8.5,
                        flexDirection: "row",
                        //backgroundColor: colors.peach,
                      }}
                    >
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 2.5,
                          //backgroundColor: colors.coinBackground,
                          borderRadius: 5,
                          overflow: "hidden",
                        }}
                      >
                        <Image
                          style={{
                            width: (Dimensions.get("window").width / 10) * 2.5,
                            height: (Dimensions.get("window").width / 10) * 3.3,
                          }}
                          source={{
                            uri: userIdFinalImage,
                          }}
                        />
                      </View>
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 6,
                          paddingHorizontal: 10,
                          //backgroundColor: colors.red,
                        }}
                      >
                        <View>
                          <Text
                            numberOfLines={1}
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 10,
                              color: colors.grey,
                            }}
                          >
                            {productDetails.product_brand}
                          </Text>
                          <Text
                            numberOfLines={1}
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 15,
                              color: colors.black,
                            }}
                          >
                            {productDetails.product_title}
                          </Text>
                          <Text
                            numberOfLines={1}
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 10,
                              color: colors.grey,
                            }}
                          >
                            {productDetails.product_description}
                          </Text>
                        </View>
                        <View
                          style={{
                            paddingVertical: 10,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "MontserratSemiBold",
                                color: colors.black,
                                fontSize: 12,
                                paddingRight: 5,
                              }}
                            >
                              Color:
                            </Text>
                            <View
                              style={{
                                backgroundColor: getColorCode(
                                  productDetails
                                    ? productDetails.product_color.toString()
                                    : "#000"
                                ),
                                width: 15,
                                height: 15,
                                borderRadius: 10,
                                borderWidth: 0.5,
                                borderColor: colors.black,
                                //marginVertical: 10,
                              }}
                            ></View>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontFamily: "MontserratSemiBold",
                                color: colors.black,
                                fontSize: 12,
                                paddingRight: 5,
                              }}
                            >
                              Size: {selectedSize}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            borderTopColor: colors.lightgrey,
                            borderTopWidth: 1,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingTop: 10,
                          }}
                        >
                          <View
                            style={{
                              //flexDirection: 'row',
                              alignItems: "flex-start",
                            }}
                          >
                            {productDetails.product_selling_price ? (
                              <Text
                                style={{
                                  fontFamily: "MontserratSemiBold",
                                  fontSize: 14,
                                  color: colors.black,
                                }}
                              >
                                ₹
                                {productDetails.product_selling_price *
                                  quantity}
                              </Text>
                            ) : (
                              <Text
                                style={{
                                  fontFamily: "MontserratSemiBold",
                                  fontSize: 14,
                                  color: colors.black,
                                }}
                              >
                                ₹{productDetails.product_mrp * quantity}
                              </Text>
                            )}
                          </View>
                          <View
                            style={{
                              width: 100,
                              backgroundColor: colors.lightgrey,
                              borderRadius: 40,
                              paddingVertical: 5,
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <TouchableOpacity
                              activeOpacity={0.9}
                              onPress={() => {
                                quantityHandler("subtract");
                              }}
                            >
                              <Ionicons
                                name="remove-circle"
                                color={colors.black}
                                size={28}
                              />
                            </TouchableOpacity>
                            <View>
                              <Text
                                style={{
                                  fontFamily: "MontserratSemiBold",
                                  fontSize: 14,
                                  color: colors.black,
                                }}
                              >
                                {quantity}
                              </Text>
                            </View>
                            <TouchableOpacity
                              activeOpacity={0.9}
                              onPress={() => {
                                quantityHandler("add");
                              }}
                            >
                              <Ionicons
                                name="add-circle"
                                color={colors.black}
                                size={28}
                              />
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </StoreCard>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 9.5,
                    //backgroundColor: colors.green,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 9,
                      paddingVertical: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 13,
                        color: colors.black,
                      }}
                    >
                      Shipping Address
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 9.5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StoreCard margin={10} padding={10}>
                    <View style={styles.cardMainContainer}>
                      <View style={styles.formTitleContainer}>
                        <View style={styles.titleTextContainer}>
                          <Text
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 12,
                              color: colors.black,
                            }}
                          >
                            Name
                          </Text>
                        </View>
                        <View style={styles.textInputContainer}>
                          <View style={styles.inputContainer}>
                            <TextInput
                              //placeholder="https://www.facebook.com/example"
                              style={styles.textInput}
                              value={values.userName}
                              onChangeText={handleChange("userName")}
                              onBlur={() => setFieldTouched("userName")}
                              maxLength={1000}
                              //placeholderTextColor={colors.grey}
                              keyboardType="default"
                              //numberOfLines={4}
                            />
                          </View>
                        </View>
                        <View style={styles.errorContainer}>
                          {touched.userName && errors.userName && (
                            <Text style={styles.errorTextContainer}>
                              {errors.userName}
                            </Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.formTitleContainer}>
                        <View style={styles.titleTextContainer}>
                          <Text
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 12,
                              color: colors.black,
                            }}
                          >
                            Address
                          </Text>
                        </View>
                        <View style={styles.textInputContainer}>
                          <View style={styles.inputContainer}>
                            <TextInput
                              //placeholder="https://www.facebook.com/example"
                              style={styles.textInput}
                              value={values.userAddress}
                              onChangeText={handleChange("userAddress")}
                              onBlur={() => setFieldTouched("userAddress")}
                              maxLength={1000}
                              //placeholderTextColor={colors.grey}
                              keyboardType="default"
                              //numberOfLines={4}
                            />
                          </View>
                        </View>
                        <View style={styles.errorContainer}>
                          {touched.userAddress && errors.userAddress && (
                            <Text style={styles.errorTextContainer}>
                              {errors.userAddress}
                            </Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.formTitleContainer}>
                        <View style={styles.titleTextContainer}>
                          <Text
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 12,
                              color: colors.black,
                            }}
                          >
                            City
                          </Text>
                        </View>
                        <View style={styles.textInputContainer}>
                          <View style={styles.inputContainer}>
                            <TextInput
                              //placeholder={userAddress}
                              //placeholder="https://www.instagram.com/example"
                              style={styles.textInput}
                              value={values.userCity}
                              onChangeText={handleChange("userCity")}
                              onBlur={() => setFieldTouched("userCity")}
                              maxLength={1000}
                              //placeholderTextColor={colors.grey}
                              keyboardType="default"
                              //numberOfLines={4}
                            />
                          </View>
                        </View>
                        <View style={styles.errorContainer}>
                          {touched.userCity && errors.userCity && (
                            <Text style={styles.errorTextContainer}>
                              {errors.userCity}
                            </Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.formTitleContainer}>
                        <View style={styles.titleTextContainer}>
                          <Text
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 12,
                              color: colors.black,
                            }}
                          >
                            State
                          </Text>
                        </View>
                        <View style={styles.textInputContainer}>
                          <View style={styles.inputContainer}>
                            <TextInput
                              //placeholder="https://www.twitter.com/example"
                              style={styles.textInput}
                              value={values.userState}
                              onChangeText={handleChange("userState")}
                              onBlur={() => setFieldTouched("userState")}
                              maxLength={1000}
                              //placeholderTextColor={colors.grey}
                              keyboardType="default"
                            />
                          </View>
                        </View>
                        <View style={styles.errorContainer}>
                          {touched.userState && errors.userState && (
                            <Text style={styles.errorTextContainer}>
                              {errors.userState}
                            </Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.formTitleContainer}>
                        <View style={styles.titleTextContainer}>
                          <Text
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 12,
                              color: colors.black,
                            }}
                          >
                            Pin
                          </Text>
                        </View>
                        <View style={styles.textInputContainer}>
                          <View style={styles.inputContainer}>
                            <TextInput
                              //placeholder="https://www.youtube.com/example"
                              style={styles.textInput}
                              value={values.userPin}
                              onChangeText={handleChange("userPin")}
                              onBlur={() => setFieldTouched("userPin")}
                              maxLength={1000}
                              //placeholderTextColor={colors.grey}
                              keyboardType="default"
                            />
                          </View>
                        </View>
                        <View style={styles.errorContainer}>
                          {touched.userPin && errors.userPin && (
                            <Text style={styles.errorTextContainer}>
                              {errors.userPin}
                            </Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.formTitleContainer}>
                        <View style={styles.titleTextContainer}>
                          <Text
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 12,
                              color: colors.black,
                            }}
                          >
                            Phone
                          </Text>
                        </View>
                        <View style={styles.textInputContainer}>
                          <View style={styles.inputContainer}>
                            <TextInput
                              //placeholder="https://www.linkedin.com/example"
                              style={styles.textInput}
                              value={values.userPhone}
                              onChangeText={handleChange("userPhone")}
                              onBlur={() => setFieldTouched("userPhone")}
                              maxLength={1000}
                              //placeholderTextColor={colors.grey}
                              keyboardType="default"
                              //editable={false}
                            />
                          </View>
                        </View>
                        <View style={styles.errorContainer}>
                          {touched.userPhone && errors.userPhone && (
                            <Text style={styles.errorTextContainer}>
                              {errors.userPhone}
                            </Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.formTitleContainer}>
                        <View style={styles.titleTextContainer}>
                          <Text
                            style={{
                              fontFamily: "MontserratRegular",
                              fontSize: 12,
                              color: colors.black,
                            }}
                          >
                            Email
                          </Text>
                        </View>
                        <View style={styles.textInputContainer}>
                          <View style={styles.inputContainer}>
                            <TextInput
                              //placeholder="https://www.snapchat.com/example"
                              style={styles.textInput}
                              value={values.userEmail}
                              onChangeText={handleChange("userEmail")}
                              onBlur={() => setFieldTouched("userEmail")}
                              maxLength={1000}
                              //placeholderTextColor={colors.grey}
                              keyboardType="default"
                            />
                          </View>
                        </View>
                        <View style={styles.errorContainer}>
                          {touched.userEmail && errors.userEmail && (
                            <Text style={styles.errorTextContainer}>
                              {errors.userEmail}
                            </Text>
                          )}
                        </View>
                      </View>
                    </View>
                  </StoreCard>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 9.5,
                    //backgroundColor: colors.green,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 9,
                      paddingVertical: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 13,
                        color: colors.black,
                      }}
                    >
                      Order Summary
                    </Text>
                  </View>
                  <StoreCard padding={10} margin={10}>
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 8,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 7,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          fontSize: 13,
                          color: colors.black,
                        }}
                      >
                        MRP
                      </Text>
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          fontSize: 14,
                          color: colors.black,
                        }}
                      >
                        ₹{productDetails.product_mrp * quantity}
                      </Text>
                    </View>
                    {productDetails.product_selling_price && (
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 8,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingVertical: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "MontserratRegular",
                            fontSize: 13,
                            color: colors.black,
                          }}
                        >
                          Discount
                        </Text>
                        <Text
                          style={{
                            fontFamily: "MontserratSemiBold",
                            fontSize: 14,
                            color: colors.greenShadow,
                          }}
                        >
                          -₹
                          {productDetails.product_mrp * quantity -
                            productDetails.product_selling_price * quantity}
                        </Text>
                      </View>
                    )}
                    {productDetails.product_selling_price && (
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 8,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingVertical: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "MontserratRegular",
                            fontSize: 13,
                            color: colors.black,
                          }}
                        >
                          Sub Total
                        </Text>
                        <Text
                          style={{
                            fontFamily: "MontserratSemiBold",
                            fontSize: 14,
                            color: colors.black,
                          }}
                        >
                          ₹{productDetails.product_selling_price}
                        </Text>
                      </View>
                    )}
                    {productDetails.product_gst_rate > 0 && (
                      <View
                        style={{
                          width: (Dimensions.get("window").width / 10) * 8,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          paddingVertical: 7,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "MontserratRegular",
                            fontSize: 13,
                            color: colors.black,
                          }}
                        >
                          Tax (included)
                        </Text>
                        {productDetails.product_selling_price ? (
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.black,
                            }}
                          >
                            ₹
                            {(productDetails.product_gst_rate / 100) *
                              productDetails.product_selling_price *
                              quantity}
                          </Text>
                        ) : (
                          <Text
                            style={{
                              fontFamily: "MontserratSemiBold",
                              fontSize: 14,
                              color: colors.black,
                            }}
                          >
                            ₹
                            {(productDetails.product_gst_rate / 100) *
                              productDetails.product_mrp *
                              quantity}
                          </Text>
                        )}
                      </View>
                    )}
                    <View
                      style={{
                        width: (Dimensions.get("window").width / 10) * 8,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingVertical: 7,
                        borderTopWidth: 2,
                        borderTopColor: colors.lightgrey,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          fontSize: 13,
                          color: colors.black,
                        }}
                      >
                        To Pay
                      </Text>
                      {productDetails.product_selling_price ? (
                        <Text
                          style={{
                            fontFamily: "MontserratSemiBold",
                            fontSize: 14,
                            color: colors.unverified,
                          }}
                        >
                          ₹{productDetails.product_selling_price * quantity}
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontFamily: "MontserratSemiBold",
                            fontSize: 14,
                            color: colors.black,
                          }}
                        >
                          ₹{productDetails.unverified}
                        </Text>
                      )}
                    </View>
                  </StoreCard>
                  {placingOrder ? (
                    <TouchableOpacity style={styles.buttonContainer}>
                      <ActivityIndicator size={25} color={colors.white} />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      activeOpacity={0.5}
                      disabled={!isValid}
                      onPress={handleSubmit}
                      style={styles.buttonContainer}
                    >
                      <Text style={styles.buttonText}>Place Order</Text>
                    </TouchableOpacity>
                  )}
                </View>
                <View
                  style={{
                    paddingBottom: 70,
                  }}
                ></View>
              </ScrollView>
            )}
          </Formik>
          <Modal visible={orderCompleted}>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 10,
                height: (Dimensions.get("window").height / 10) * 10,
                backgroundColor: colors.storeLightGreen,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StoreCard margin={10} padding={10}>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8,
                    backgroundColor: colors.storeLightGreen,
                    alignItems: "center",
                  }}
                >
                  <Ionicons
                    name="checkmark-circle"
                    color={colors.greenShadow}
                    size={38}
                  />
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8,
                    backgroundColor: colors.storeLightGreen,
                    alignItems: "center",
                    marginVertical: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratBold",
                      fontSize: 18,
                      textAlign: "center",
                      color: colors.black,
                    }}
                  >
                    Thank you for your order!
                  </Text>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      fontSize: 13,
                      textAlign: "center",
                      color: colors.grey,
                    }}
                  >
                    The order confirmation has been sent to your email address.
                  </Text>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8,
                    backgroundColor: colors.storeLightGreen,
                    alignItems: "center",
                    paddingVertical: 10,
                    flexDirection: "row",
                    justifyContent: "center",
                    borderTopWidth: 1,
                    borderTopColor: colors.lightgrey,
                  }}
                >
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 2.5,
                      //backgroundColor: colors.storeLightGreen,
                      alignItems: "center",
                      //marginVertical: 10,
                      //flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "MontserratBold",
                        fontSize: 15,
                        textAlign: "center",
                        color: colors.black,
                      }}
                    >
                      ₹{productDetails.product_selling_price * quantity}
                    </Text>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        textAlign: "center",
                        color: colors.grey,
                      }}
                    >
                      Amount
                    </Text>
                  </View>
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 2.5,
                      //backgroundColor: colors.storeLightGreen,
                      alignItems: "center",
                      //marginVertical: 10,
                      //flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "MontserratBold",
                        fontSize: 15,
                        textAlign: "center",
                        color: colors.black,
                      }}
                    >
                      {quantity}
                    </Text>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        textAlign: "center",
                        color: colors.grey,
                      }}
                    >
                      Quantity
                    </Text>
                  </View>
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 2.5,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: getColorCode(
                            productDetails
                              ? productDetails.product_color.toString()
                              : "#000"
                          ),
                          width: 15,
                          height: 15,
                          borderRadius: 10,
                          borderWidth: 0.5,
                          borderColor: colors.black,
                          //marginVertical: 10,
                        }}
                      ></View>
                      <Text
                        style={{
                          fontFamily: "MontserratBold",
                          fontSize: 15,
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        {" "}
                        , {selectedSize}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        textAlign: "center",
                        color: colors.grey,
                      }}
                    >
                      Variant
                    </Text>
                  </View>
                </View>
              </StoreCard>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8,
                  backgroundColor: colors.storeLightGreen,
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 30,
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratBold",
                    fontSize: 18,
                    textAlign: "center",
                    color: colors.black,
                  }}
                >
                  Verify Your Order
                </Text>
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    fontSize: 13,
                    textAlign: "center",
                    color: colors.grey,
                  }}
                >
                  To verify your order, connect with the seller.
                </Text>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8,
                  //backgroundColor: colors.peach,
                  alignItems: "center",
                  marginVertical: 10,
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 2.5,
                    alignItems: "center",
                    justifyContent: "center",
                    //backgroundColor: colors.black
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      const whatsAppMsg =
                          "I just placed an order, " +
                          productDetails.product_title +
                          " - " +
                          productDetails.product_color.toUpperCase() +
                          "-" +
                          selectedSize +
                          " from your store. I want to confirm my order.";
                        let url =
                          "http://api.whatsapp.com/send?text=" +
                          whatsAppMsg +
                          "&phone=91" +
                          productDetails.user.mobile;
                        Linking.openURL(url);
                    }}
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: 30,
                      backgroundColor: colors.greenShadow,
                      justifyContent: "center",
                      alignItems: "center",
                      elevation: 5,
                      shadowColor: colors.greenShadow,
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.25,
                      shadowRadius: 3.84,
                    }}
                  >
                    <Ionicons
                      name="logo-whatsapp"
                      color={colors.white}
                      size={30}
                    />
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    let url = "tel:" + productDetails.user.mobile;
                    Linking.openURL(url);
                  }}
                  style={{
                    width: (Dimensions.get("window").width / 10) * 2.5,
                    alignItems: "center",
                    justifyContent: "center",
                    //backgroundColor: colors.black
                  }}
                >
                  <View
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: 30,
                      backgroundColor: "#0E9DF3",
                      justifyContent: "center",
                      alignItems: "center",
                      elevation: 5,
                      shadowColor: colors.greenShadow,
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.25,
                      shadowRadius: 3.84,
                    }}
                  >
                    <Ionicons
                      name="call-outline"
                      color={colors.white}
                      size={30}
                    />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    let url = "mailto:" + productDetails.user.email;
                    Linking.openURL(url);
                  }}
                  style={{
                    width: (Dimensions.get("window").width / 10) * 2.5,
                    alignItems: "center",
                    justifyContent: "center",
                    //backgroundColor: colors.red
                  }}
                >
                  <View
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: 30,
                      backgroundColor: "#FF4122",
                      justifyContent: "center",
                      alignItems: "center",
                      elevation: 5,
                      shadowColor: "#0E9DF3",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.25,
                      shadowRadius: 3.84,
                    }}
                  >
                    <Ionicons
                      name="mail-outline"
                      color={colors.white}
                      size={30}
                    />
                  </View>
                </TouchableOpacity>
              </View>
              {/* <StoreCard> */}
            </View>
          </Modal>
        </SafeAreaView>
      );
    }
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: (Dimensions.get("window").width / 10) * 10,
    backgroundColor: colors.storeLightGreen,
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    width: (Dimensions.get("window").width / 10) * 9.5,
    //height: (Dimensions.get('window').height / 10) * 0.7,
    marginHorizontal: (Dimensions.get("window").width / 10) * 0.25,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    //backgroundColor: colors.black,
  },
  iconStyle: {
    justifyContent: "center",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    shadowColor: "#000",
  },
  buttonContainer: {
    width: (Dimensions.get("window").width / 10) * 9,
    height: (Dimensions.get("window").width / 10) * 1.5,
    marginVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.storeDarkGreen,
    borderRadius: 10,
    elevation: 5,
    shadowColor: colors.greenShadow,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  buttonText: {
    fontFamily: "MontserratSemiBold",
    fontSize: 17,
    color: colors.white,
  },
  saveButtonContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    backgroundColor: colors.peach,
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    borderRadius: 30,
    marginVertical: 10,
  },
  saveTextContaoner: {
    fontFamily: "Montserrat-SemiBold",
    color: colors.white,
    fontSize: 14,
  },
  inputContainer: {
    width: (Dimensions.get("window").width / 10) * 7.6,
  },
  textInputContainer: {
    backgroundColor: colors.lightgrey,
    fontSize: 15,
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.2,
    borderRadius: 10,
    width: (Dimensions.get("window").width / 10) * 8,
    //height: (Dimensions.get("window").height / 10) * 0.6,
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 12,
  },
  cardMainContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    justifyContent: "center",
    alignItems: "center",
  },
  formTitleContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    marginVertical: 5,
  },
  titleTextContainer: {
    paddingVertical: 2,
    fontSize: 15,
    paddingHorizontal: 10,
    borderRadius: 10,
    width: (Dimensions.get("window").width / 10) * 8,
  },
  errorContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.4,
    alignItems: "flex-start",
  },
  errorTextContainer: {
    fontSize: 10,
    color: colors.unverified,
    fontFamily: "MontserratRegular",
  },
  xltextInputContainer: {
    backgroundColor: colors.lightgrey,
    fontSize: 15,
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.1,
    borderRadius: 10,
    width: (Dimensions.get("window").width / 10) * 3,
    //height: (Dimensions.get("window").height / 10) * 0.6,
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 12,
  },
  xlinputContainer: {
    width: (Dimensions.get("window").width / 10) * 2.8,
    //alignItems: "center",
  },
  xlcardMainContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    justifyContent: "center",
    alignItems: "center",
  },
  xlformTitleContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 5,
  },
  xltitleTextContainer: {
    paddingVertical: 2,
    fontSize: 15,
    paddingHorizontal: 10,
    borderRadius: 10,
    width: (Dimensions.get("window").width / 10) * 3,
  },
  xlerrorContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.1,
    alignItems: "flex-start",
  },
  xlerrorTextContainer: {
    fontSize: 10,
    color: colors.unverified,
    fontFamily: "MontserratRegular",
  },
  textInput: {
    fontFamily: "MontserratSemiBold",
    color: colors.black,
    fontSize: 15,
  },
});

export default StoreCheckoutScreen;
