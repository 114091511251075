import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import colors from "../constants/theme/colors";
import MainHeader from "../components/mainHeader";
import PageBanner from "../components/pageBanner";
import FooterSection from "../components/footerSection";
import PlanSection from "../components/plansSection";
import CopyrightSection from "../components/copyrightSection";
import { pageContent } from "../constants/pages/about";
import LottieView from "react-native-web-lottie";
import WhatsAppChat from "../components/whatsAppChat";

const NotFoundScreen = (props) => {
  return (
    <View style={styles.container}>
      <MainHeader props={props} />
      <ScrollView>
        <View style={styles.lottiContainer}>
          <LottieView
            source={require("../assets/lottie/empty-link.json")}
            autoPlay
            loop
            resizeMode="cover"
          />
          <View style={{ alignItems: "center" }}>
            <Text style={styles.boldText}>404 Not Found</Text>
            <Text style={styles.text}>
              The page you are looking for is not available.
            </Text>
          </View>
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate('Drawer', { screen: "Home"});
            }}
            style={styles.buttonContainer}
          >
            <Text style={styles.buttonText}>Go to Homepage</Text>
          </TouchableOpacity>
        </View>
        <FooterSection props={props} />
        <CopyrightSection />
      </ScrollView>
      <WhatsAppChat />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: Dimensions.get("window").width,
  },
  scrollView: {
    width: (Dimensions.get("window").width / 10) * 10,
  },
  lottiContainer: {
    width: Dimensions.get("window").width,
    height: (Dimensions.get("window").height / 10) * 7,
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
  },
  boldText: {
    fontFamily: "MontserratBold",
    fontSize: 30,
  },
  text: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
  },
  buttonContainer: {
    backgroundColor: colors.peach,
    marginTop: 20,
    padding: 15,
    borderRadius: 5,
  },
  buttonText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.white,
  },
});

export default NotFoundScreen;
