import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
  Linking,
} from "react-native";
import colors from "../constants/theme/colors";
import { useFonts } from "expo-font";
import Collapsible from "react-native-collapsible";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  withRepeat,
} from "react-native-reanimated";
import MainHeader from "../components/mainHeader";
import MainBanner from "../components/mainBanner";
import FeatureSection from "../components/featureSection";
import HowSection from "../components/howSection";
import ScreenshotSection from "../components/screenshotSection";
import PlanSection from "../components/plansSection";
import FaqSection from "../components/faqSection";
import FooterSection from "../components/footerSection";
import { Helmet } from "react-helmet-async";
import CopyrightSection from "../components/copyrightSection";
import WhatsAppChat from "../components/whatsAppChat";

const HomeScreen = (props) => {
  const progress = useSharedValue(1);

  useEffect(() => {
    progress.value = withRepeat(withTiming(10, { duration: 1000 }), -1, true);
  }, []);

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Helmet>
        <html lang="en" />
        <title>LinksApp | All your links at one place</title>
        <meta
          name="title"
          content="LinksApp | All your links at one place"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="LinksApp is an Indian-made solution to streamline online connections. Customize and share your links effortlessly, embracing Atmanirbhar Bharat ethos."
        />
        <meta property="og:site_name" content="LinksApp" />
        <meta property="og:title" content="LinksApp" />
        <meta
          property="og:description"
          content="LinksApp is an Indian-made solution to streamline online connections. Customize and share your links effortlessly, embracing the Atmanirbhar Bharat ethos."
        />
        <meta property="og:url" content="https://linksapp.in" />
        <meta
          property="og:image"
          itemprop="image"
          content="https://api.linksapp.in/public/uploads/logo/icon.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:updated_time" content={Date.now()} />
        <meta name="keywords" content="LinksApp" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta itemprop="name" content="LinksApp - All your links in one app!" />
        <meta
          itemprop="description"
          content="LinksApp is an Indian-made solution to streamline online connections. Customize and share your links effortlessly, embracing the Atmanirbhar Bharat ethos."
        />
        <meta
          itemprop="image"
          content="https://api.linksapp.in/public/uploads/logo/icon.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="LinksApp - All your links in one app!"
        />
        <meta
          name="twitter:description"
          content="LinksApp is an Indian-made solution to streamline online connections. Customize and share your links effortlessly, embracing the Atmanirbhar Bharat ethos."
        />
        <meta
          name="twitter:image"
          content="https://api.linksapp.in/public/uploads/logo/icon.png"
        />
      </Helmet>
      <MainHeader props={props} />
      <ScrollView style={{ width: (Dimensions.get("window").width / 10) * 10 }}>
        <MainBanner />
        <FeatureSection />
        <HowSection />
        <ScreenshotSection />
        <PlanSection />
        <FaqSection />
        <FooterSection props={props} />
        <CopyrightSection />
      </ScrollView>
      <WhatsAppChat />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: Dimensions.get("window").width,
  },
  iconContainer: {
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.25,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.peach,
  },
  iconStyle: {
    justifyContent: "center",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    shadowColor: "#000",
  },
  logo: {
    width: 110,
    height: 45,
  },
  appLogo: {
    width: 150,
    height: 45,
    marginVertical: 5,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.peach,
  },
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    height: (Dimensions.get("window").height / 10) * 8,
    //paddingVertical: 70,
    //flexDirection: "row",
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mainBannerRightCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    //backgroundColor: colors.red,
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 35,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 14,
    color: colors.white,
  },
  featuresContainer: {
    width: Dimensions.get("window").width,
    alignItems: "center",
    marginVertical: 50,
    //backgroundColor: colors.white
  },
  headerTextBlack: {
    fontFamily: "MontserratSemiBold",
    fontSize: 30,
    color: colors.darkgrey,
    textAlign: "center",
  },
  featureHeaderContainer: {
    width: Dimensions.get("window").width,
  },
  featureTextContainer: {
    width: (Dimensions.get("window").width / 10) * 5,
    justifyContent: "center",
    alignItems: "center",
    //backgroundColor: colors.red,
    marginVertical: 20,
  },
  featuresText: {
    fontFamily: "MontserratRegular",
    fontSize: 14,
    color: colors.textcolor,
    textAlign: "center",
  },
  featureBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  leftBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "space-between",
    alignItems: "center",
  },
  middleBlock: {
    width: (Dimensions.get("window").width / 10) * 3.5,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
  },
  rightBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: (Dimensions.get("window").height / 10) * 6,
    //backgroundColor: colors.peach,
    justifyContent: "center",
    alignItems: "center",
  },
  featureBlock: {
    width: (Dimensions.get("window").width / 10) * 2.5,
    height: (Dimensions.get("window").height / 10) * 1.75,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    //paddingVertical: 50,
    borderRadius: 10,
  },
  featureHeaderText: {
    fontFamily: "MontserratBold",
    color: colors.darkgrey,
    fontSize: 15,
  },
  featureText: {
    fontFamily: "MontserratRegular",
    fontSize: 12,
    color: colors.textcolor,
  },
  iconMainContainer: {
    width: (Dimensions.get("window").width / 10) * 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  iconConatiner: {
    width: 50,
    height: 50,
    backgroundColor: colors.lightorange,
    padding: 5,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  stepContainer: {
    width: 30,
    height: 64,
    justifyContent: "center",
    alignItems: "center",
  },
  featuresTextContainer: {
    width: (Dimensions.get("window").width / 10) * 1.5,
  },
  howLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 3,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  howRightBlock: {
    width: (Dimensions.get("window").width / 10) * 3,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqBlockContainer: {
    width: (Dimensions.get("window").width / 10) * 8.5,
    height: (Dimensions.get("window").height / 10) * 6,
    marginVertical: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  faqLeftBlock: {
    width: (Dimensions.get("window").width / 10) * 4,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "center",
    alignItems: "center",
  },
  faqRightBlock: {
    width: (Dimensions.get("window").width / 10) * 4,
    height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  screenShotsContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    //height: (Dimensions.get("window").height / 10) * 6,
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    width: 100,
    height: 100,
    backgroundColor: colors.red,
  },
  carouselContainer: {
    marginBottom: 20,
    //backgroundColor: 'blue'
  },
  plansButtonContainer: {},
  buttonContainer: {
    width: (Dimensions.get("window").width / 10) * 2,
    height: 50,
    borderColor: colors.peach,
    borderWidth: 1,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
  },
  planBlock: {
    width: (Dimensions.get("window").width / 10) * 2,
    //height: (Dimensions.get("window").height / 10) * 1.75,
    //paddingVertical: 50,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: colors.darkgrey,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    borderRadius: 10,
    overflow: "hidden",
    //backgroundColor: colors.peach,
  },
  collapsibleMainContainer: {
    width: (Dimensions.get("window").width / 10) * 7.5,
    marginVertical: 10,
  },
  featureText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    color: colors.grey,
  },
});

export default HomeScreen;
