const pageContent = [
  {
   "id": 1,
   "title": "1. Introduction",
   "para": "Welcome to LinksApp, operated by Fusedlogic Innovations PVT LTD ('LinksApp,' 'we,' 'us,' or 'our'). By accessing or using the LinksApp mobile application ('App') and related services, you agree to be bound by these Terms and Conditions ('Terms'). Please read these Terms carefully before using the App. If you do not agree to these Terms, you may not access or use the App."
  },
  {
   "id": 2,
   "title": "2. Use of the App",
  },
  {
   "id": 3,
   "title": "a. Eligibility:",
   "para": "You must be at least 18 years old to use the App. By using the App, you represent and warrant that you are at least 18 years old."
  },
  {
   "id": 4,
   "title": "b. License:",
   "para": "LinksApp grants you a limited, non-exclusive, non-transferable license to use the App for your personal or commercial use, subject to these Terms."
  },
  {
   "id": 5,
   "title": "c. User Account:",
   "para": "You may be required to create an account to access certain features of the App. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account."
  },
  {
   "id": 6,
   "title": "d. Store Option:",
   "para": "LinksApp offers a store option that facilitates the connection between sellers and buyers. We do not engage in any monetary transactions between sellers and buyers, and we do not charge any commission on orders. Sellers are solely responsible for managing product listings, pricing, shipping, and order fulfillment."
  },
  {
   "id": 7,
   "para": "You must not assign or transfer your account to someone else, or use your account (or allow it to be used by anyone) in a way which in our reasonable opinion, causes damage to LinksApp or our reputation, or infringes another’s rights or applicable laws and regulations."
  },
  {
   "id": 8,
   "title": "e. Platform Fee:",
   "para": "LinksApp charges a platform fee for utilizing the store option. This fee covers the cost of maintaining the platform and providing support services to users. By using the store option, you agree to pay the applicable platform fee as determined by LinksApp."
  },
  {
   "id": 9,
   "title": "f. Buyer-Seller Interaction:",
   "para": "LinksApp acts as a facilitator for connecting sellers and buyers. We do not take responsibility for any issues arising from transactions as we do not  conduct online payment transactions on the App for the store feature. Any disputes, discrepancies, or concerns regarding orders should be addressed directly between the buyer and the seller. We provide only cash on delivery options. Apart from this payment option, it is the prerogative of the buyer and the seller as in how they want to make the payment."
  },
  {
   "id": 10,
   "title": "3. Privacy Policy"
  },
  {
   "id": 11,
   "title": "a. Collection of Information:",
   "para": "Your use of the App is subject to our Privacy Policy, which governs the collection, use, and disclosure of personal information obtained through the App. By using the App, you consent to the terms of our Privacy Policy."
  },
  {
   "id": 12,
   "title": "4. User Content",
  },
  {
   "id": 13,
   "title": "a. Submission:",
   "para": "You may have the opportunity to submit content, including but not limited to text, images, videos, and links, to the App ('User Content). You retain ownership of your User Content, but by submitting it to the App, you grant LinksApp a worldwide, non-exclusive, royalty-free, sublicensable license to use, reproduce, modify, adapt, publish, translate, distribute, and display your User Content in connection with the operation of the App."
  },
  {
   "id": 14,
   "title": "b. Responsibility:",
   "para": "You are solely responsible for your User Content and the consequences of posting or publishing it. By submitting User Content, you represent and warrant that you have the necessary rights, permissions, and licenses to do so."
  },
  {
   "id": 15,
   "title": "5. Intellectual Property",
  },
  {
   "id": 16,
   "title": "a. Ownership:",
   "para": "All content, trademarks, logos, and intellectual property displayed on the App are the property of LinksApp or its licensors. You may not use, reproduce, or distribute any content from the App without prior written permission from LinksApp."
  },
  {
   "id": 17,
   "para": "When you post content on your profile, you grant us a license to (i) use, publicly display, distribute, modify, adapt and create derivative works of such content; and (ii) use your name, image, voice, photograph, likeness and any other personal attributes in the content; on the Platform and in our marketing in all media (such as our social channels and any other advertising). This license is worldwide, royalty-free and perpetual, which means we can use your content anywhere in the world, without paying you fees, for as long as we like. You agree that you have all third party rights necessary to post the content on LinksApp and to grant us this license."
  },
  {
   "id": 18,
   "title": "b. User Content:",
   "para": "By posting, uploading, or sharing any content on the App, you grant LinksApp a non-exclusive, royalty-free, worldwide license to use, modify, adapt, reproduce, and distribute the content for promotional and marketing purposes."
  },
  {
   "id": 19,
   "para": "Please don’t share personal info that you don’t want visible to the world, and never post social security numbers, passport details or similar info that could cause harm in the wrong hands. You may only post personal info relating to others where you have their consent and have kept a record of it."
  },
  {
   "id": 20,
   "para": "We don’t have to monitor the accuracy, reliability or legality of your content, but we may choose to do so. We may modify, remove or restrict access to content at any time in line with these Terms or apply a sensitive content warning to content that we deem unsuitable for all audiences."
  },
  {
   "id": 21,
   "title": "6. Prohibited Conduct",
  },
  {
   "id": 22,
   "title": "a. You agree not to:",
   "para": "Violate any applicable laws or regulations; Use the App for any unlawful or fraudulent purpose; Impersonate any person or entity or falsely state or misrepresent your affiliation with any person or entity; Interfere with or disrupt the operation of the App or servers or networks connected to the App; Use any automated means, including bots, robots, or spiders, to access or use the App; Engage in any conduct that restricts or inhibits any other user from using or enjoying the App."
  },
  {
   "id": 23,
   "title": "7. Disclaimer of Warranties"
  },
  {
   "id": 24,
   "para": "The App is provided on an 'as is' and 'as available' basis without warranties of any kind, either express or implied. LinksApp does not warrant that the App will be uninterrupted or error-free, that defects will be corrected, or that the App is free of viruses or other harmful components."
  },
  {
   "id": 25,
   "title": "8. Limitation of Liability",
  },
  {
   "id": 26,
   "para": "In no event shall LinksApp be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, lost profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of the App."
  },
  {
   "id": 27,
   "title": "9. Governing Law"
  },
  {
   "id": 28,
   "para": "These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions."
  },
  {
   "id": 29,
   "title": "10. Changes to the Terms",
  },
  {
   "id": 30,
   "para": "LinksApp reserves the right to modify these Terms at any time. Changes will be effective immediately upon posting on the App. It is your responsibility to review these Terms periodically for updates."
  },
 ]

module.exports = {
  pageContent: pageContent,
};
