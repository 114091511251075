const pageContent = [
  {
    id: 1,
    title: 'Welcome to LinksApp: Your Ultimate Link Management Solution',
    para: "",
  },
  {
    id: 2,
    title: '',
    para: "Welcome to LinksApp, a homegrown digital solution that embodies the spirit of Atmanirbhar Bharat, empowering individuals, businesses, and creators to effortlessly manage their online presence and connections. Our journey is not just about link management; it's a testament to the innovation and self-reliance of Make in India.",
  },
  {
    id: 3,
    title: 'Our Atmanirbhar Vision:',
    para: "At LinksApp, we are committed to fostering a digital ecosystem that resonates with the ideals of Atmanirbhar Bharat – a self-reliant India. Our application is conceptualized, designed, and developed right here in India, bringing forth cutting-edge technology that speaks volumes about our nation's technological prowess.",
  },
  {
    id: 4,
    title: 'Our Vision:',
    para: "Our journey began with a vision to simplify the complexity of sharing multiple links across various platforms. As digital landscapes continue to evolve, individuals, content creators, businesses, and influencers face the challenge of maintaining a cohesive and organized online identity. At LinksApp, we're dedicated to transforming this challenge into an opportunity by providing a streamlined and comprehensive solution.",
  },
  {
    id: 5,
    title: 'Introducing LinksApp: Empower Your Links',
    para: "",
  },
  {
    id: 6,
    title: 'Effortless Link Management: ',
    para: "Say goodbye to link clutter. LinksApp allows you to create a personalized landing page that unifies all your links under one roof. Share your website, social media profiles, online store, blog, portfolio, and more with ease.",
  },
  {
    id: 7,
    title: 'Affiliate Integration:',
    para: "Going beyond traditional link management, LinksApp offers a unique product affiliate feature. Monetize your online presence by seamlessly integrating affiliate links and earning from products you recommend.",
  },
  {
    id: 8,
    title: 'Customization at its Finest:',
    para: "Express your individuality with LinksApp's range of customization options. Tailor your page to match your branding, choose themes, colors, and layouts that resonate with your unique style.",
  },
  {
    id: 9,
    title: 'Uncover Insights:',
    para: "Knowledge is power. LinksApp equips you with analytics that provide insights into link performance, click-through rates, and audience engagement. Make informed decisions to enhance your online strategy.",
  },
  {
    id: 10,
    title: 'Responsive and Accessible:',
    para: "LinksApp is designed to provide a seamless user experience across devices. Whether your audience is browsing on a desktop, tablet, or mobile phone, your LinksApp page will adapt beautifully.",
  },
  {
    id: 11,
    title: 'Join the LinksApp Community',
    para: "",
  },
  {
    id: 12,
    title: '',
    para: "As you embark on your journey with LinksApp, you're joining a community of trendsetters, visionaries, and individuals who recognize the value of streamlined link management. Experience the empowerment of sharing your story, resources, and recommendations with unparalleled convenience.",
  },
  {
    id: 13,
    title: 'Embrace the Power of LinksApp',
    para: "",
  },
  {
    id: 14,
    title: '',
    para: "Ready to simplify, monetize, and optimize your link sharing experience? Join LinksApp today and witness the transformation in how you present your digital identity. Embrace the future of link management, where every click is a step toward building meaningful connections. Sign up today and embark on a seamless linking experience designed in India, for the world.",
  },
  {
    id: 15,
    title: 'Your Links, Your Way, with LinksApp',
    para: "",
  },
];

module.exports = {
  pageContent: pageContent,
};
