import React from "react";
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Dimensions,
  Image,
  Text,
} from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import colors from "../constants/theme/colors";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";
import { useFonts } from "expo-font";

const MainHeader = ({ props }) => {
  const deviceSize = useDeviceSize();

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }

  if (deviceSize === "extralarge") {
    return (
      <View style={styles.iconContainer}>
        <TouchableOpacity
          style={{ padding: 5 }}
          onPress={() => {
            props.navigation.navigate("Home");
          }}
        >
          <Image
            style={styles.logo}
            source={require("../assets/logo/links-app-logo-light.png")}
          />
        </TouchableOpacity>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("Drawer", { screen: "About" });
            }}
            style={{ paddingHorizontal: 30 }}
          >
            <Text style={styles.headerFont}>About</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("Drawer", { screen: "Contact" });
            }}
            style={{ paddingHorizontal: 30 }}
          >
            <Text style={styles.headerFont}>Contact</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("Drawer", { screen: "Privacy" });
            }}
            style={{ paddingHorizontal: 30 }}
          >
            <Text style={styles.headerFont}>Privacy</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("Drawer", { screen: "Terms" });
            }}
            style={{ paddingHorizontal: 30 }}
          >
            <Text style={styles.headerFont}>Terms & Conditions</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("Drawer", { screen: "Refund" });
            }}
            style={{ paddingHorizontal: 30 }}
          >
            <Text style={styles.headerFont}>Refund Policy</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity onPress={() => {}}>
          <View style={styles.iconStyle}>
            {/* <FontAwesome name="bell" color={colors.white} size={25} /> */}
          </View>
        </TouchableOpacity>
      </View>
    );
  } else {
    return (
      <View style={styles.iconContainer}>
        <TouchableOpacity
          onPress={() => {
            props.navigation.toggleDrawer();
          }}
        >
          <View style={styles.iconStyle}>
            <MaterialCommunityIcons
              name="link-variant"
              color={colors.white}
              size={30}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ padding: 5 }}
          onPress={() => {
            props.navigation.navigate("Home");
          }}
        >
          <Image
            style={styles.logo}
            source={require("../assets/logo/links-app-logo-light.png")}
          />
        </TouchableOpacity>
        <TouchableOpacity>
          <MaterialCommunityIcons
            name="information"
            color={colors.peach}
            size={30}
          />
        </TouchableOpacity>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  iconContainer: {
    width: (Dimensions.get("window").width / 10) * 10,
    paddingHorizontal: (Dimensions.get("window").width / 10) * 0.25,
    paddingVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.peach,
  },
  iconStyle: {
    justifyContent: "center",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    shadowColor: "#000",
  },
  logo: {
    width: 110,
    height: 45,
  },
  headerFont: { fontFamily: "MontserratRegular", color: colors.white },
});

export default MainHeader;
