import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Dimensions,
  ActivityIndicator,
  FlatList,
  Linking,
  Modal,
  TouchableOpacity,
} from "react-native";
import SwipeableItem, {
  useSwipeableItemParams,
} from "react-native-swipeable-item";
import {
  EXPO_PUBLIC_API_URL,
  EXPO_PUBLIC_API_KEY,
  EXPO_PUBLIC_BASE_URL,
} from "@env";
import colors from "../constants/theme/colors";
import Collapsible from "react-native-collapsible";
import MaterialIcons from "@expo/vector-icons//MaterialIcons";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import Ionicons from "@expo/vector-icons//Ionicons";
const OVERSWIPE_DIST = 20;
import LottieView from "react-native-web-lottie";
import { Card, Button } from "ui-neumorphism";
import { overrideThemeVariables } from "ui-neumorphism";
import "ui-neumorphism/dist/index.css";
let stopFetchMore = true;
import { Helmet } from "react-helmet";
import SEOSection from "../components/SEOSection";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";

const LinksScreen = (props) => {
  const [userProfileDetails, setUserProfileDetails] = useState([]);
  const [snapPointsLeft, setSnapPointsLeft] = useState([600]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [collabpsableId, setCollapsableId] = useState(1);
  const [courierFolderPath, setCourierFolderPath] = useState();
  const [userFolderPath, setUserFolderPath] = useState();
  const [linkHistory, setLinkHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const linkRef = useRef([]);
  const [endList, setEndList] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const user_id = props.route.params.id;
  const styles = useStyles();
  const deviceSize = useDeviceSize();

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userDetails = await getuserDetails();
      //console.log(userDetails);
      if (userDetails.success) {
        setUserProfileDetails(userDetails.userDetails);
        setLinkHistory(userDetails.links);
        setCourierFolderPath(userDetails.courierFolderPath);
        setUserFolderPath(userDetails.userFolderPath);
      } else {
        props.navigation.navigate("Drawer");
      }
    };
    fetchUserDetails();
    setInterval(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getuserDetails = async (page) => {
    try {
      const apiResult = await fetch(
        `${EXPO_PUBLIC_API_URL}users/getProfileDetails?id=${user_id}&page=${page}&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "ps-key": EXPO_PUBLIC_API_KEY,
          },
        }
      );
      const resData = await apiResult.json();
      return resData;
    } catch (error) {
      props.navigation.navigate("Drawer");
      //console.log(error);
    }
  };

  const accordionHandler = (id) => {
    //console.log(id);
    setCollapsableId(id);
    setIsCollapsed(!isCollapsed);
  };

  const handleOnEndReached = async () => {
    setPage(page + 1);
    const newHistory = await getuserDetails(page + 1);
    if (newHistory) {
      setLinkHistory([...linkHistory, ...newHistory.links]);
    }
    // if (!stopFetchMore) {
    //   setPage(page + 1);
    //   const newHistory = await getuserDetails(page + 1);
    //   console.log('new links');
    //   console.log(newHistory);
    //   if (newHistory) {
    //     setLinkHistory([...userProfileDetails, ...newHistory.links]);
    //   }
    // }
    //stopFetchMore = true;
  };

  const openLinkHandler = async (props) => {
    await Linking.openURL(props);
  };

  const renderItem = ({ item, itemRefs, drag, isActive }) => (
    <View style={styles.linksContainer}>
      {userProfileDetails.meta_title ? (
        <SEOSection
          title={userProfileDetails.meta_title}
          description={
            userProfileDetails.meta_description
              ? userProfileDetails.meta_description
              : userProfileDetails.profile_bio
          }
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
      ) : (
        <SEOSection
          title={
            userProfileDetails.firstname + " " + userProfileDetails.lastname
          }
          description={userProfileDetails.profile_bio}
          apple={
            EXPO_PUBLIC_BASE_URL + "/profile/" + userProfileDetails.username
          }
          image={
            EXPO_PUBLIC_BASE_URL +
            userFolderPath +
            userProfileDetails.profile_image
          }
        />
      )}
      {userProfileDetails.profile_style == "border" ? (
        <View style={styles.borderMainContainer}>
          {item.link_type == "link" ? (
            <View style={styles.borderLinksContainer}>
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={async () => {
                  await openLinkHandler(item.link_url);
                }}
                style={styles.borderLinkContainer}
              >
                <View style={styles.linkImageContainer}>
                  <Image
                    crossorigin="anonymous"
                    style={styles.thumbnail}
                    source={{
                      uri:
                        EXPO_PUBLIC_BASE_URL +
                        courierFolderPath +
                        item.link_image,
                    }}
                  />
                </View>
                <View style={styles.linkDetailsContainer}>
                  <Text style={styles.linkTitleText}>{item.link_title}</Text>
                </View>
                <View style={styles.linkImageContainer}>
                  <Text></Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : item.link_type == "header" ? (
            <View style={styles.borderHeaderContainer}>
              <Text style={styles.linkHeaderText}>{item.link_title}</Text>
            </View>
          ) : (
            <View style={styles.borderOtherContainer}>
              <TouchableOpacity
                onPress={() => {
                  accordionHandler(item.link_id);
                }}
                style={styles.borderAppContainer}
              >
                <View style={styles.borderAppImageContainer}></View>
                <View>
                  <Text style={styles.borderAppTitle}>{item.link_title}</Text>
                </View>
                {collabpsableId == item.link_id ? (
                  <View style={styles.benefitArrow}>
                    {isCollapsed ? (
                      <MaterialIcons
                        name="keyboard-arrow-down"
                        color={colors.black}
                        size={21}
                      />
                    ) : (
                      <MaterialIcons
                        name="keyboard-arrow-up"
                        color={colors.black}
                        size={22}
                      />
                    )}
                  </View>
                ) : (
                  <View style={styles.benefitArrow}>
                    <MaterialIcons
                      name="keyboard-arrow-down"
                      color={colors.black}
                      size={22}
                    />
                  </View>
                )}
              </TouchableOpacity>
              {collabpsableId == item.link_id ? (
                <Collapsible collapsed={isCollapsed}>
                  <View style={styles.collapsibleContainer}>
                    {item.android_url && (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={async () => {
                          await openLinkHandler(item.android_url);
                        }}
                      >
                        <Image
                          style={styles.playstoreLogo}
                          source={require("../assets/images/playstore.png")}
                        />
                      </TouchableOpacity>
                    )}
                    {item.ios_url && (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={async () => {
                          await openLinkHandler(item.ios_url);
                        }}
                      >
                        <Image
                          style={styles.playstoreLogo}
                          source={require("../assets/images/appstore.png")}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </Collapsible>
              ) : (
                <Text></Text>
              )}
            </View>
          )}
        </View>
      ) : userProfileDetails.profile_style == "shadow" ? (
        <View style={styles.shadowMainContainer}>
          {item.link_type == "link" ? (
            <View style={styles.shadowLinkContainer}>
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={async () => {
                  await openLinkHandler(item.link_url);
                }}
                style={styles.shadowContainer}
              >
                <View style={styles.linkImageContainer}>
                  <Image
                    crossOrigin="anonymous"
                    style={styles.thumbnail}
                    source={{
                      uri:
                        EXPO_PUBLIC_BASE_URL +
                        courierFolderPath +
                        item.link_image,
                    }}
                  />
                </View>
                <View style={styles.shadowLinksTitleContainer}>
                  <Text style={styles.shadowLinksTitle}>{item.link_title}</Text>
                </View>
                <View style={styles.linkImageContainer}>
                  <Text> </Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : item.link_type == "header" ? (
            <View style={styles.shadowHeaderContainer}>
              <Text style={styles.shadowLinkTitleContainer}>
                {item.link_title}
              </Text>
            </View>
          ) : (
            <View style={styles.shadowAppContainer}>
              <TouchableOpacity
                onPress={() => {
                  accordionHandler(item.link_id);
                }}
                style={styles.shadowCollapsibleContainer}
              >
                <View style={styles.borderAppImageContainer}></View>
                <View>
                  <Text style={styles.shadowLinkTitle}>{item.link_title}</Text>
                </View>
                {collabpsableId == item.link_id ? (
                  <View style={styles.benefitArrow}>
                    {isCollapsed ? (
                      <MaterialIcons
                        name="keyboard-arrow-down"
                        color={colors.black}
                        size={21}
                      />
                    ) : (
                      <MaterialIcons
                        name="keyboard-arrow-up"
                        color={colors.black}
                        size={22}
                      />
                    )}
                  </View>
                ) : (
                  <View style={styles.benefitArrow}>
                    <MaterialIcons
                      name="keyboard-arrow-down"
                      color={colors.black}
                      size={22}
                    />
                  </View>
                )}
              </TouchableOpacity>
              {collabpsableId == item.link_id ? (
                <Collapsible collapsed={isCollapsed}>
                  <View style={styles.appLogoContainer}>
                    {item.android_url && (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={async () => {
                          await openLinkHandler(item.android_url);
                        }}
                      >
                        <Image
                          style={styles.playstoreLogo}
                          source={require("../assets/images/playstore.png")}
                        />
                      </TouchableOpacity>
                    )}
                    {item.ios_url && (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={async () => {
                          await openLinkHandler(item.ios_url);
                        }}
                      >
                        <Image
                          style={styles.playstoreLogo}
                          source={require("../assets/images/appstore.png")}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </Collapsible>
              ) : (
                <Text></Text>
              )}
            </View>
          )}
        </View>
      ) : userProfileDetails.profile_style == "neomorph" ? (
        <View style={styles.neomorphMainContainer}>
          {item.link_type == "link" ? (
            <Card style={{ borderRadius: 50 }}>
              <View style={styles.neomorphLinksContainer}>
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={async () => {
                    await openLinkHandler(item.link_url);
                  }}
                  style={styles.neomorphLinkContainer}
                >
                  <View style={styles.linkImageContainer}>
                    <Image
                      crossOrigin="anonymous"
                      style={styles.thumbnail}
                      source={{
                        uri:
                          EXPO_PUBLIC_BASE_URL +
                          courierFolderPath +
                          item.link_image,
                      }}
                    />
                  </View>
                  <View style={styles.neomorphTitleContainer}>
                    <Text style={styles.neomorphTitleText}>
                      {item.link_title}
                    </Text>
                  </View>
                  <View style={styles.linkImageContainer}>
                    <Text> </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </Card>
          ) : item.link_type == "header" ? (
            <View style={styles.neomorphHeaderContainer}>
              <Text style={styles.neomorphTitleText}>{item.link_title}</Text>
            </View>
          ) : (
            <Card style={{ borderRadius: 10 }}>
              <View style={styles.neomorphAppMainContainer}>
                <TouchableOpacity
                  onPress={() => {
                    accordionHandler(item.link_id);
                  }}
                  style={styles.neomorphAppContainer}
                >
                  <View style={styles.borderAppImageContainer}></View>
                  <View>
                    <Text style={styles.neomorphLinkTitle}>
                      {item.link_title}
                    </Text>
                  </View>
                  {collabpsableId == item.link_id ? (
                    <View style={styles.benefitArrow}>
                      {isCollapsed ? (
                        <MaterialIcons
                          name="keyboard-arrow-down"
                          color={colors.black}
                          size={21}
                        />
                      ) : (
                        <MaterialIcons
                          name="keyboard-arrow-up"
                          color={colors.black}
                          size={22}
                        />
                      )}
                    </View>
                  ) : (
                    <View style={styles.benefitArrow}>
                      <MaterialIcons
                        name="keyboard-arrow-down"
                        color={colors.black}
                        size={22}
                      />
                    </View>
                  )}
                </TouchableOpacity>
                {collabpsableId == item.link_id ? (
                  <Collapsible collapsed={isCollapsed}>
                    <View style={styles.neomorphCollapsibleContainer}>
                      {item.android_url && (
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={async () => {
                            await openLinkHandler(item.android_url);
                          }}
                        >
                          <Image
                            style={styles.playstoreLogo}
                            source={require("../assets/images/playstore.png")}
                          />
                        </TouchableOpacity>
                      )}
                      {item.ios_url && (
                        <TouchableOpacity
                          activeOpacity={0.7}
                          onPress={async () => {
                            await openLinkHandler(item.ios_url);
                          }}
                        >
                          <Image
                            style={styles.playstoreLogo}
                            source={require("../assets/images/appstore.png")}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                  </Collapsible>
                ) : (
                  <Text></Text>
                )}
              </View>
            </Card>
          )}
        </View>
      ) : (
        <View style={styles.defaultMainContainer}>
          {item.link_type == "link" ? (
            <View style={styles.defaultLinkMainContainer}>
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={async () => {
                  await openLinkHandler(item.link_url);
                }}
                style={styles.defaultLinkContainer}
              >
                <View style={styles.linkImageContainer}>
                  <Image
                    style={styles.thumbnail}
                    crossOrigin="anonymous"
                    source={{
                      uri:
                        EXPO_PUBLIC_BASE_URL +
                        courierFolderPath +
                        item.link_image,
                    }}
                  />
                </View>
                <View style={styles.defaultLinkTitle}>
                  <Text style={styles.defaultTitleText}>{item.link_title}</Text>
                </View>
                <View style={styles.linkImageContainer}>
                  <Text> </Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : item.link_type == "header" ? (
            <View style={styles.defaultHeaderContainer}>
              <Text style={styles.defaultHeaderTitle}>{item.link_title}</Text>
            </View>
          ) : (
            <View style={styles.defaultAppMainContainer}>
              <TouchableOpacity
                onPress={() => {
                  accordionHandler(item.link_id);
                }}
                style={styles.defaultAppContainer}
              >
                <View style={styles.borderAppImageContainer}></View>
                <View>
                  <Text style={styles.defaultAppTitleText}>
                    {item.link_title}
                  </Text>
                </View>
                {collabpsableId == item.link_id ? (
                  <View style={styles.benefitArrow}>
                    {isCollapsed ? (
                      <MaterialIcons
                        name="keyboard-arrow-down"
                        color={colors.black}
                        size={21}
                      />
                    ) : (
                      <MaterialIcons
                        name="keyboard-arrow-up"
                        color={colors.black}
                        size={22}
                      />
                    )}
                  </View>
                ) : (
                  <View style={styles.benefitArrow}>
                    <MaterialIcons
                      name="keyboard-arrow-down"
                      color={colors.black}
                      size={22}
                    />
                  </View>
                )}
              </TouchableOpacity>
              {collabpsableId == item.link_id ? (
                <Collapsible collapsed={isCollapsed}>
                  <View style={styles.defaultCollapsibleContainer}>
                    {item.android_url && (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={async () => {
                          await openLinkHandler(item.android_url);
                        }}
                      >
                        <Image
                          style={styles.playstoreLogo}
                          source={require("../assets/images/playstore.png")}
                        />
                      </TouchableOpacity>
                    )}
                    {item.ios_url && (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        onPress={async () => {
                          await openLinkHandler(item.ios_url);
                        }}
                      >
                        <Image
                          style={styles.playstoreLogo}
                          source={require("../assets/images/appstore.png")}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </Collapsible>
              ) : (
                <Text></Text>
              )}
            </View>
          )}
        </View>
      )}
    </View>
  );

  return (
    <View style={styles.container}>
      {loading || userProfileDetails == null ? (
        <View style={styles.mainActivityContainer}>
          <ActivityIndicator size="large" color={colors.peach} />
        </View>
      ) : (
        <FlatList
          data={linkHistory}
          renderItem={renderItem}
          keyExtractor={(item) => item._id}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={
            <View style={{ paddingBottom: 70 }}>
              <View style={styles.footerMainContainer}>
                {endList ? (
                  <View style={styles.endListContainer}></View>
                ) : (
                  <View>
                    {loadMore && (
                      <View style={styles.loadMoreContainer}>
                        <ActivityIndicator size={30} color={colors.peach} />
                      </View>
                    )}
                  </View>
                )}
              </View>
              {/* {linkHistory.length == 0 && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 4,
                  }}
                >
                  <View style={styles.footerLottieContainer}>
                    <LottieView
                      source={require("../assets/lottie/empty-link.json")}
                      autoPlay
                      loop
                    />
                  </View>
                  <View style={styles.footerEmptyContainer}>
                    <Text style={styles.footerEmptyText}>No Link Found!</Text>
                  </View>
                </View>
              )} */}
              <View style={styles.footerLogoMain}>
                {userProfileDetails.profile_logo == false ||
                new Date(userProfileDetails.plan_validity) < new Date() ? (
                  <View style={styles.footerLogoContainer}>
                    <View style={styles.footerPower}>
                      <Text style={styles.footerPowerText}>Powered by</Text>
                    </View>
                    <TouchableOpacity onPress={()=>{
                      props.navigation.navigate("Drawer", { screen: "Home" });
                    }} style={styles.footerLogo}>
                      <Image
                        style={styles.applogo}
                        source={require("../assets/logo/links-app-logo-border.png")}
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <Text></Text>
                )}
              </View>
            </View>
          }
          onEndReachedThreshold={0.01}
          onEndReached={handleOnEndReached}
          onScrollBeginDrag={() => {
            //console.log("on drag");
            stopFetchMore = false;
          }}
          ListHeaderComponent={
            <View style={styles.bodyScrollView}>
              {loading ||
              userProfileDetails == null ||
              userProfileDetails == undefined ? (
                <View style={styles.mainActivityContainer}>
                  <ActivityIndicator size="large" color={colors.peach} />
                </View>
              ) : (
                <View style={styles.profileContainer}>
                  <View style={styles.profileContainer}>
                    <View style={styles.profileImageContainer}>
                      <TouchableOpacity
                        activeOpacity={0.9}
                        onPress={() => {
                          setModalVisible(true);
                        }}
                        style={styles.mapContainer}
                      >
                        <View
                          style={{
                            position: "absolute",
                            justifyContent: "center",
                            alignItems: "center",
                            //backgroundColor: colors.black
                          }}
                        >
                          <ActivityIndicator size={30} color={colors.peach} />
                        </View>
                        {userProfileDetails.profile_image && (
                          <Image
                            crossOrigin="anonymous"
                            source={{
                              uri:
                                EXPO_PUBLIC_BASE_URL +
                                userFolderPath +
                                userProfileDetails.profile_image,
                            }}
                            style={styles.avatar}
                          />
                        )}
                      </TouchableOpacity>
                    </View>
                    <View style={styles.bioContainer}>
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          textAlign: "center",
                          fontSize: 15,
                          color:
                            userProfileDetails.profile_animation != "default"
                              ? userProfileDetails.profile_animation ==
                                  "night" ||
                                userProfileDetails.profile_animation == "ocean"
                                ? colors.white
                                : colors.black
                              : userProfileDetails.profile_theme == "purple" ||
                                userProfileDetails.profile_theme ==
                                  "night-girl" ||
                                userProfileDetails.profile_theme ==
                                  "couple-winter" ||
                                userProfileDetails.profile_theme == "planet" ||
                                userProfileDetails.profile_theme == "space" ||
                                userProfileDetails.profile_theme ==
                                  "couple-umbrella" ||
                                userProfileDetails.profile_theme ==
                                  "alone-night" ||
                                userProfileDetails.profile_theme == "feather" ||
                                userProfileDetails.profile_theme ==
                                  "anime-night" ||
                                userProfileDetails.profile_theme ==
                                  "flower-girl" ||
                                userProfileDetails.profile_theme ==
                                  "couple-mordern" ||
                                userProfileDetails.profile_theme ==
                                  "music-boy" ||
                                userProfileDetails.profile_theme ==
                                  "couple-sunset" ||
                                userProfileDetails.profile_theme == "anime" ||
                                userProfileDetails.profile_theme == "ram-one" ||
                                userProfileDetails.profile_theme ==
                                  "ram-five" ||
                                userProfileDetails.profile_animation ==
                                  "night" ||
                                userProfileDetails.profile_animation == "ocean"
                              ? colors.white
                              : colors.black,
                        }}
                      >
                        {userProfileDetails.profile_title}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          fontSize: 13,
                          textAlign: "center",
                          color:
                            userProfileDetails.profile_animation != "default"
                              ? userProfileDetails.profile_animation ==
                                  "night" ||
                                userProfileDetails.profile_animation == "ocean"
                                ? colors.white
                                : colors.black
                              : userProfileDetails.profile_theme == "purple" ||
                                userProfileDetails.profile_theme ==
                                  "night-girl" ||
                                userProfileDetails.profile_theme ==
                                  "couple-winter" ||
                                userProfileDetails.profile_theme == "planet" ||
                                userProfileDetails.profile_theme == "space" ||
                                userProfileDetails.profile_theme ==
                                  "couple-umbrella" ||
                                userProfileDetails.profile_theme ==
                                  "alone-night" ||
                                userProfileDetails.profile_theme == "feather" ||
                                userProfileDetails.profile_theme ==
                                  "anime-night" ||
                                userProfileDetails.profile_theme ==
                                  "flower-girl" ||
                                userProfileDetails.profile_theme ==
                                  "couple-mordern" ||
                                userProfileDetails.profile_theme ==
                                  "music-boy" ||
                                userProfileDetails.profile_theme ==
                                  "couple-sunset" ||
                                userProfileDetails.profile_theme == "anime" ||
                                userProfileDetails.profile_theme == "ram-one" ||
                                userProfileDetails.profile_theme ==
                                  "ram-five" ||
                                userProfileDetails.profile_animation ==
                                  "night" ||
                                userProfileDetails.profile_animation == "ocean"
                              ? colors.white
                              : colors.black,
                        }}
                      >
                        {userProfileDetails.profile_bio}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.socialMainContainer}>
                    {userProfileDetails.facebook_profile == null ||
                    userProfileDetails.facebook_profile == "" ? null : (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        style={styles.socialButtonContainer}
                        onPress={async () => {
                          await openLinkHandler(
                            userProfileDetails.facebook_profile
                          );
                        }}
                      >
                        <Image
                          style={styles.thumbnail}
                          source={require("../assets/images/facebook-color.png")}
                        />
                      </TouchableOpacity>
                    )}
                    {userProfileDetails.instagram_profile == null ||
                    userProfileDetails.instagram_profile == "" ? null : (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        style={styles.socialButtonContainer}
                        onPress={async () => {
                          await openLinkHandler(
                            userProfileDetails.instagram_profile
                          );
                        }}
                      >
                        <Image
                          style={styles.thumbnail}
                          source={require("../assets/images/instagram-color.png")}
                        />
                      </TouchableOpacity>
                    )}
                    {userProfileDetails.twitter_profile == null ||
                    userProfileDetails.twitter_profile == "" ? null : (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        style={styles.socialButtonContainer}
                        onPress={async () => {
                          await openLinkHandler(
                            userProfileDetails.twitter_profile
                          );
                        }}
                      >
                        <Image
                          style={styles.thumbnail}
                          source={require("../assets/images/twitter-color.png")}
                        />
                      </TouchableOpacity>
                    )}
                    {userProfileDetails.app_download_link == null ||
                    userProfileDetails.app_download_link == "" ? null : (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        style={styles.socialButtonContainer}
                        onPress={async () => {
                          await openLinkHandler(
                            userProfileDetails.app_download_link
                          );
                        }}
                      >
                        <Image
                          style={styles.thumbnail}
                          source={require("../assets/images/snapchat-color.png")}
                        />
                      </TouchableOpacity>
                    )}
                    {userProfileDetails.youtube_profile == null ||
                    userProfileDetails.youtube_profile == "" ? null : (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        style={styles.socialButtonContainer}
                        onPress={async () => {
                          await openLinkHandler(
                            userProfileDetails.youtube_profile
                          );
                        }}
                      >
                        <Image
                          style={styles.thumbnail}
                          source={require("../assets/images/youtube-color.png")}
                        />
                      </TouchableOpacity>
                    )}
                    {userProfileDetails.linkedin_profile == null ||
                    userProfileDetails.linkedin_profile == "" ? null : (
                      <TouchableOpacity
                        activeOpacity={0.7}
                        style={styles.socialButtonContainer}
                        onPress={async () => {
                          await openLinkHandler(
                            userProfileDetails.linkedin_profile
                          );
                        }}
                      >
                        <Image
                          style={styles.thumbnail}
                          source={require("../assets/images/linkedin-color.png")}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              )}
              <Modal
                animationType="fade"
                transparent={true}
                visible={modalVisible}
              >
                <TouchableOpacity
                  activeOpacity={0.9}
                  onPress={() => {
                    setModalVisible(false);
                  }}
                  style={styles.modalMainContainer}
                >
                  <View style={styles.modalImageContainer}>
                    <View style={styles.imageContainer}>
                      <Image
                        crossOrigin="anonymous"
                        source={{
                          uri:
                            EXPO_PUBLIC_BASE_URL +
                            userFolderPath +
                            userProfileDetails.profile_image,
                        }}
                        style={styles.modalImage}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              </Modal>
            </View>
          }
        />
      )}
    </View>
  );
};

export default LinksScreen;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: (Dimensions.get("window").width / 10) * 3.5,
      //height: "100%",
      alignItems: "center",
    },
    profileContainer: {
      width: (Dimensions.get("window").width / 10) * 3.5,
      justifyContent: "center",
      alignItems: "center",
      //backgroundColor: colors.black
    },
    profileImageContainer: {
      width: (Dimensions.get("window").width / 10) * 3.5,
      justifyContent: "center",
      alignItems: "center",
      elevation: 5,
      shadowColor: colors.peach,
      //backgroundColor: colors.white
    },
    thumbnail: {
      width: (Dimensions.get("window").width / 10) * 0.3,
      height: (Dimensions.get("window").width / 10) * 0.3,
      borderRadius: (Dimensions.get("window").width / 10) * 1,
      overflow: "hidden",
    },
    linksContainer: {
      marginTop: 10,
      width: (Dimensions.get("window").width / 10) * 10,
      justifyContent: "center",
      alignItems: "center",
    },
    applogo: {
      width: 110,
      height: 45,
    },
    avatar: {
      width: (Dimensions.get("window").width / 10) * 1.2,
      height: (Dimensions.get("window").width / 10) * 1.2,
      borderRadius: (Dimensions.get("window").width / 10) * 1.3,
      backgroundColor: colors.white,
    },
    mapContainer: {
      width: (Dimensions.get("window").width / 10) * 1.25,
      height: (Dimensions.get("window").width / 10) * 1.25,
      backgroundColor: colors.white,
      justifyContent: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 600,
      overflow: "hidden",
      elevation: 15,
      shadowColor: colors.red,
      marginVertical: 20,
    },
    bodyScrollView: {
      //width: (Dimensions.get("window").width / 10) * 4,
      justifyContent: "center",
      alignItems: "center",
      //backgroundColor: colors.green
    },
    benefitArrow: {
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 5,
      width: 30,
      height: 30,
    },
    playstoreLogo: {
      width: 120,
      height: 36,
    },
    borderMainContainer: {
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    borderLinksContainer: {
      borderRadius: 50,
      backgroundColor: colors.white,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: 4,
      borderWidth: 1,
      borderColor: colors.black,
    },
    borderLinkContainer: {
      width: (Dimensions.get("window").width / 10) * 2.9,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    linkImageContainer: {
      width: (Dimensions.get("window").width / 10) * 0.3,
    },
    linkDetailsContainer: {
      width: (Dimensions.get("window").width / 10) * 2.3,
      justifyContent: "center",
      alignItems: "center",
    },
    linkTitleText: {
      fontFamily: "MontserratSemiBold",
      fontSize: 13,
      color: colors.black,
    },
    borderHeaderContainer: {
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: 7,
    },
    linkHeaderText: {
      fontFamily: "MontserratSemiBold",
      fontSize: 14,
      color: colors.black,
      textShadowColor: colors.white,
      textShadowRadius: 3,
    },
    borderOtherContainer: {
      borderRadius: 10,
      backgroundColor: colors.white,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 7,
      borderWidth: 1,
      borderColor: colors.black,
    },
    borderAppContainer: {
      width: (Dimensions.get("window").width / 10) * 3,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    borderAppImageContainer: {
      height: (Dimensions.get("window").width / 10) * 0.3,
    },
    borderAppTitle: {
      fontFamily: "MontserratSemiBold",
      fontSize: 14,
      color: colors.black,
    },
    collapsibleContainer: {
      flexDirection: "row",
      justifyContent: "space-around",
      width: (Dimensions.get("window").width / 10) * 3,
      paddingVertical: 5,
    },
    shadowMainContainer: {
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    shadowLinkContainer: {
      shadowRadius: 10,
      shadowColor: colors.darkgrey,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.1,
      borderRadius: 50,
      backgroundColor: colors.white,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: 7,
      elevation: 5,
    },
    shadowContainer: {
      width: (Dimensions.get("window").width / 10) * 2.9,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    shadowLinksTitleContainer: {
      width: (Dimensions.get("window").width / 10) * 2.3,
      justifyContent: "center",
      alignItems: "center",
    },
    shadowLinksTitle: {
      fontFamily: "MontserratSemiBold",
      fontSize: 13,
      color: colors.black,
    },
    shadowLinksShare: {
      fontFamily: "MontserratSemiBold",
      fontSize: 13,
      color: colors.black,
    },
    shadowHeaderContainer: {
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: 7,
    },
    shadowLinkTitleContainer: {
      fontFamily: "MontserratSemiBold",
      fontSize: 14,
      color: colors.black,
      textShadowColor: colors.white,
      textShadowRadius: 3,
    },
    shadowAppContainer: {
      shadowRadius: 5,
      shadowColor: colors.darkgrey,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.1,
      borderRadius: 10,
      backgroundColor: colors.white,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 7,
    },
    shadowCollapsibleContainer: {
      width: (Dimensions.get("window").width / 10) * 3,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    shadowLinkTitle: {
      fontFamily: "MontserratSemiBold",
      fontSize: 14,
      color: colors.black,
    },
    appLogoContainer: {
      flexDirection: "row",
      justifyContent: "space-around",
      width: (Dimensions.get("window").width / 10) * 3,
      paddingVertical: 5,
    },
    neomorphMainContainer: {
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    neomorphLinksContainer: {
      shadowRadius: 10,
      shadowColor: colors.darkgrey,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.1,
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: 7,
      elevation: 5,
    },
    neomorphLinkContainer: {
      width: (Dimensions.get("window").width / 10) * 2.9,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    neomorphLinksImageContainer: {
      width: (Dimensions.get("window").width / 10) * 1,
    },
    neomorphTitleContainer: {
      width: (Dimensions.get("window").width / 10) * 2.3,
      justifyContent: "center",
      alignItems: "center",
    },
    neomorphTitleText: {
      fontFamily: "MontserratSemiBold",
      fontSize: 13,
      color: colors.black,
    },
    neomorphHeaderContainer: {
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: 7,
    },
    neomorphAppMainContainer: {
      borderRadius: 10,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 7,
    },
    neomorphAppContainer: {
      width: (Dimensions.get("window").width / 10) * 3,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    neomorphLinkTitle: {
      fontFamily: "MontserratSemiBold",
      fontSize: 14,
      color: colors.black,
    },
    neomorphCollapsibleContainer: {
      flexDirection: "row",
      justifyContent: "space-around",
      width: (Dimensions.get("window").width / 10) * 3,
      paddingVertical: 5,
    },
    defaultMainContainer: {
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    defaultLinkMainContainer: {
      borderRadius: 50,
      backgroundColor: colors.white,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: 7,
    },
    defaultLinkContainer: {
      width: (Dimensions.get("window").width / 10) * 2.9,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    defaultLinkTitle: {
      width: (Dimensions.get("window").width / 10) * 2.3,
      justifyContent: "center",
      alignItems: "center",
    },
    defaultTitleText: {
      fontFamily: "MontserratSemiBold",
      fontSize: 13,
      color: colors.black,
    },
    defaultHeaderContainer: {
      borderRadius: 50,
      width: (Dimensions.get("window").width / 10) * 3,
      //height: (Dimensions.get("window").width / 10) * 0.3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    defaultHeaderTitle: {
      fontFamily: "MontserratSemiBold",
      fontSize: 14,
      color: colors.black,
      textShadowColor: colors.white,
      textShadowRadius: 3,
    },
    defaultAppMainContainer: {
      borderRadius: 10,
      backgroundColor: colors.white,
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
      paddingVertical: 7,
    },
    defaultAppContainer: {
      width: (Dimensions.get("window").width / 10) * 3,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    defaultAppTitleText: {
      fontFamily: "MontserratSemiBold",
      fontSize: 14,
      color: colors.black,
    },
    defaultCollapsibleContainer: {
      flexDirection: "row",
      justifyContent: "space-around",
      width: (Dimensions.get("window").width / 10) * 3,
      paddingVertical: 5,
    },
    mainActivityContainer: {
      width: (Dimensions.get("window").width / 10) * 4,
      height: (Dimensions.get("window").width / 10) * 4,
      alignItems: "center",
      justifyContent: "center",
    },
    footerMainContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    endListContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: (Dimensions.get("window").height / 10) * 0.5,
    },
    loadMoreContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: (Dimensions.get("window").height / 10) * 0.5,
    },
    footerLottieContainer: {
      width: (Dimensions.get("window").width / 10) * 4,
      height: (Dimensions.get("window").width / 10) * 4,
      opacity: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    footerEmptyContainer: {
      width: (Dimensions.get("window").width / 10) * 4,
      height: (Dimensions.get("window").width / 10) * 5,
      opacity: 1,
      alignItems: "center",
    },
    footerEmptyText: {
      fontFamily: "MontserratSemiBold",
      fontSize: 13,
      color: colors.black,
    },
    footerLogoMain: {
      alignItems: "center",
      width: (Dimensions.get("window").width / 10) * 10,
    },
    footerLogoContainer: {
      marginTop: 10,
      alignItems: "center",
      width: (Dimensions.get("window").width / 10) * 10,
    },
    footerPower: {
      alignItems: "center",
      width: (Dimensions.get("window").width / 10) * 10,
    },
    footerPowerText: {
      fontFamily: "MontserratRegular",
      color: colors.black,
      fontSize: 11,
    },
    footerLogo: {
      width: (Dimensions.get("window").width / 10) * 10,
      alignItems: "center",
    },
    shareContainer: {
      position: "absolute",
      right: 0,
      top: 0,
      width: 40,
      height: 40,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colors.lightorange,
      borderRadius: 20,
      borderColor: colors.white,
      borderWidth: 1,
    },
    bioContainer: {
      width: (Dimensions.get("window").width / 10) * 3,
      justifyContent: "center",
      alignItems: "center",
    },
    socialMainContainer: {
      width: (Dimensions.get("window").width / 10) * 10,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      paddingVertical: 10,
    },
    socialButtonContainer: {
      width: (Dimensions.get("window").width / 10) * 0.3,
      marginHorizontal: 5,
    },
    modalImage: {
      width: (Dimensions.get("window").width / 10) * 2.6,
      height: (Dimensions.get("window").width / 10) * 2.6,
      borderRadius: (Dimensions.get("window").width / 10) * 2,
      overflow: "hidden",
    },
    modalMainContainer: {
      width: (Dimensions.get("window").width / 10) * 10,
      height: (Dimensions.get("window").height / 10) * 10,
      backgroundColor: colors.black,
      opacity: 0.99,
      justifyContent: "center",
      alignItems: "center",
      zIndex: 10000,
    },
    modalImageContainer: {
      width: (Dimensions.get("window").width / 10) * 2.7,
      height: (Dimensions.get("window").width / 10) * 2.7,
      justifyContent: "space-around",
      backgroundColor: colors.white,
      alignItems: "center",
      borderRadius: (Dimensions.get("window").width / 10) * 4,
      zIndex: 10000,
    },
    imageContainer: {
      width: (Dimensions.get("window").width / 10) * 2.7,
      height: (Dimensions.get("window").width / 10) * 2.7,
      alignItems: "center",
      justifyContent: "center",
    },
  },
  {
    [DEVICE_SIZES.XS]: {
      container: {
        flex: 1,
        width: (Dimensions.get("window").width / 10) * 10,
        height: "100%",
        alignItems: "center",
      },
      profileContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        justifyContent: "center",
        alignItems: "center",
      },
      profileImageContainer: {
        width: (Dimensions.get("window").width / 10) * 8,
        justifyContent: "center",
        alignItems: "center",
        elevation: 5,
        shadowColor: colors.peach,
      },
      thumbnail: {
        width: (Dimensions.get("window").width / 10) * 1,
        height: (Dimensions.get("window").width / 10) * 1,
        borderRadius: (Dimensions.get("window").width / 10) * 1,
        overflow: "hidden",
      },
      linksContainer: {
        marginTop: 10,
        width: (Dimensions.get("window").width / 10) * 10,
        justifyContent: "center",
        alignItems: "center",
      },
      applogo: {
        width: 110,
        height: 45,
      },
      avatar: {
        width: (Dimensions.get("window").width / 10) * 3.2,
        height: (Dimensions.get("window").width / 10) * 3.2,
        borderRadius: 600,
        backgroundColor: colors.white,
      },
      mapContainer: {
        width: (Dimensions.get("window").width / 10) * 3.4,
        height: (Dimensions.get("window").width / 10) * 3.4,
        backgroundColor: colors.white,
        justifyContent: "center",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 600,
        overflow: "hidden",
        elevation: 15,
        shadowColor: colors.red,
        marginVertical: 20,
      },
      bodyScrollView: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
      benefitArrow: {
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 5,
        width: 30,
        height: 30,
      },
      playstoreLogo: {
        width: 120,
        height: 36,
      },
      borderMainContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      borderLinksContainer: {
        borderRadius: 50,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
        borderWidth: 1,
        borderColor: colors.black,
      },
      borderLinkContainer: {
        width: (Dimensions.get("window").width / 10) * 8.4,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      linkImageContainer: {
        width: (Dimensions.get("window").width / 10) * 1,
      },
      linkDetailsContainer: {
        width: (Dimensions.get("window").width / 10) * 5.8,
        justifyContent: "center",
        alignItems: "center",
      },
      linkTitleText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      borderHeaderContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 9,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      linkHeaderText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
        textShadowColor: colors.white,
        textShadowRadius: 3,
      },
      borderOtherContainer: {
        borderRadius: 10,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 7,
        borderWidth: 1,
        borderColor: colors.black,
      },
      borderAppContainer: {
        width: (Dimensions.get("window").width / 10) * 8.5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      borderAppImageContainer: {
        height: (Dimensions.get("window").width / 10) * 0.7,
      },
      neomorphLinksImageContainer: {
        width: (Dimensions.get("window").width / 10) * 1,
      },
      borderAppTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      collapsibleContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: (Dimensions.get("window").width / 10) * 8,
        paddingVertical: 5,
      },
      shadowMainContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      shadowLinkContainer: {
        shadowRadius: 10,
        shadowColor: colors.darkgrey,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
        borderRadius: 50,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
        elevation: 5,
      },
      shadowContainer: {
        width: (Dimensions.get("window").width / 10) * 8.4,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      shadowLinksTitleContainer: {
        width: (Dimensions.get("window").width / 10) * 5.8,
        justifyContent: "center",
        alignItems: "center",
      },
      shadowLinksTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      shadowLinksShare: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      shadowHeaderContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 9,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      shadowLinkTitleContainer: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
        textShadowColor: colors.white,
        textShadowRadius: 3,
      },
      shadowAppContainer: {
        shadowRadius: 5,
        shadowColor: colors.darkgrey,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
        borderRadius: 10,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 7,
      },
      shadowCollapsibleContainer: {
        width: (Dimensions.get("window").width / 10) * 8,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      shadowLinkTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      appLogoContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: (Dimensions.get("window").width / 10) * 8,
        paddingVertical: 5,
      },
      neomorphMainContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      neomorphLinksContainer: {
        shadowRadius: 10,
        shadowColor: colors.darkgrey,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
        elevation: 5,
      },
      neomorphLinkContainer: {
        width: (Dimensions.get("window").width / 10) * 8.4,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      neomorphTitleContainer: {
        width: (Dimensions.get("window").width / 10) * 5.8,
        justifyContent: "center",
        alignItems: "center",
      },
      neomorphTitleText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      neomorphHeaderContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 3,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      neomorphAppMainContainer: {
        borderRadius: 10,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 7,
      },
      neomorphAppContainer: {
        width: (Dimensions.get("window").width / 10) * 8.5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      neomorphLinkTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      neomorphCollapsibleContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: (Dimensions.get("window").width / 10) * 8,
        paddingVertical: 5,
      },
      defaultMainContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      defaultLinkMainContainer: {
        borderRadius: 50,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      defaultLinkContainer: {
        width: (Dimensions.get("window").width / 10) * 8.4,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      defaultLinkTitle: {
        width: (Dimensions.get("window").width / 10) * 5.8,
        justifyContent: "center",
        alignItems: "center",
      },
      defaultTitleText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      defaultHeaderContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 9,
        //height: (Dimensions.get("window").width / 10) * 0.3,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      defaultHeaderTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
        textShadowColor: colors.white,
        textShadowRadius: 3,
      },
      defaultAppMainContainer: {
        borderRadius: 10,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 7,
      },
      defaultAppContainer: {
        width: (Dimensions.get("window").width / 10) * 8.5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      defaultAppTitleText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      defaultCollapsibleContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: (Dimensions.get("window").width / 10) * 8,
        paddingVertical: 5,
      },
      mainActivityContainer: {
        width: (Dimensions.get("window").width / 10) * 4,
        height: (Dimensions.get("window").width / 10) * 4,
        alignItems: "center",
        justifyContent: "center",
      },
      footerMainContainer: {
        justifyContent: "center",
        alignItems: "center",
      },
      endListContainer: {
        justifyContent: "center",
        alignItems: "center",
        height: (Dimensions.get("window").height / 10) * 0.5,
      },
      loadMoreContainer: {
        justifyContent: "center",
        alignItems: "center",
        height: (Dimensions.get("window").height / 10) * 0.5,
      },
      footerLottieContainer: {
        width: (Dimensions.get("window").width / 10) * 4,
        height: (Dimensions.get("window").width / 10) * 4,
        opacity: 1,
        justifyContent: "center",
        alignItems: "center",
      },
      footerEmptyContainer: {
        width: (Dimensions.get("window").width / 10) * 4,
        height: (Dimensions.get("window").width / 10) * 5,
        opacity: 1,
        alignItems: "center",
      },
      footerEmptyText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      footerLogoMain: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      footerLogoContainer: {
        marginTop: 10,
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      footerPower: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      footerPowerText: {
        fontFamily: "MontserratRegular",
        color: colors.black,
        fontSize: 11,
      },
      footerLogo: {
        width: (Dimensions.get("window").width / 10) * 10,
        alignItems: "center",
      },
      shareContainer: {
        position: "absolute",
        right: 0,
        top: 0,
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.lightorange,
        borderRadius: 20,
        borderColor: colors.white,
        borderWidth: 1,
      },
      bioContainer: {
        width: (Dimensions.get("window").width / 10) * 8,
        justifyContent: "center",
        alignItems: "center",
      },
      socialMainContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        paddingVertical: 10,
      },
      socialButtonContainer: {
        width: (Dimensions.get("window").width / 10) * 1,
      },
      modalMainContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        height: (Dimensions.get("window").height / 10) * 10,
        backgroundColor: colors.black,
        opacity: 0.99,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10000,
      },
      modalImageContainer: {
        width: (Dimensions.get("window").width / 10) * 8,
        height: (Dimensions.get("window").width / 10) * 8,
        justifyContent: "space-around",
        backgroundColor: colors.white,
        alignItems: "center",
        borderRadius: (Dimensions.get("window").width / 10) * 4,
        zIndex: 10000,
      },
      imageContainer: {
        width: (Dimensions.get("window").width / 10) * 7.8,
        height: (Dimensions.get("window").width / 10) * 7.8,
        alignItems: "center",
        justifyContent: "center",
      },
      modalImage: {
        width: (Dimensions.get("window").width / 10) * 7.8,
        height: (Dimensions.get("window").width / 10) * 7.8,
        borderRadius: (Dimensions.get("window").width / 10) * 4,
        overflow: "hidden",
      },
    },
  },
  {
    [DEVICE_SIZES.SM]: {
      container: {
        flex: 1,
        width: (Dimensions.get("window").width / 10) * 10,
        height: "100%",
        alignItems: "center",
      },
      profileContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        justifyContent: "center",
        alignItems: "center",
      },
      profileImageContainer: {
        width: (Dimensions.get("window").width / 10) * 8,
        justifyContent: "center",
        alignItems: "center",
        elevation: 5,
        shadowColor: colors.peach,
      },
      thumbnail: {
        width: (Dimensions.get("window").width / 10) * 1,
        height: (Dimensions.get("window").width / 10) * 1,
        borderRadius: (Dimensions.get("window").width / 10) * 1,
        overflow: "hidden",
      },
      linksContainer: {
        marginTop: 10,
        width: (Dimensions.get("window").width / 10) * 10,
        justifyContent: "center",
        alignItems: "center",
      },
      applogo: {
        width: 110,
        height: 45,
      },
      avatar: {
        width: (Dimensions.get("window").width / 10) * 3.2,
        height: (Dimensions.get("window").width / 10) * 3.2,
        borderRadius: 600,
        backgroundColor: colors.white,
      },
      mapContainer: {
        width: (Dimensions.get("window").width / 10) * 3.4,
        height: (Dimensions.get("window").width / 10) * 3.4,
        backgroundColor: colors.white,
        justifyContent: "center",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 600,
        overflow: "hidden",
        elevation: 15,
        shadowColor: colors.red,
        marginVertical: 20,
      },
      bodyScrollView: {
        width: (Dimensions.get("window").width / 10) * 10,
      },
      benefitArrow: {
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 5,
        width: 30,
        height: 30,
      },
      playstoreLogo: {
        width: 120,
        height: 36,
      },
      borderMainContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      borderLinksContainer: {
        borderRadius: 50,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
        borderWidth: 1,
        borderColor: colors.black,
      },
      borderLinkContainer: {
        width: (Dimensions.get("window").width / 10) * 8.4,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      linkImageContainer: {
        width: (Dimensions.get("window").width / 10) * 1,
      },
      linkDetailsContainer: {
        width: (Dimensions.get("window").width / 10) * 5.8,
        justifyContent: "center",
        alignItems: "center",
      },
      linkTitleText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      borderHeaderContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 3,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      linkHeaderText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
        textShadowColor: colors.white,
        textShadowRadius: 3,
      },
      borderOtherContainer: {
        borderRadius: 10,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 7,
        borderWidth: 1,
        borderColor: colors.black,
      },
      borderAppContainer: {
        width: (Dimensions.get("window").width / 10) * 8.5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      borderAppImageContainer: {
        height: (Dimensions.get("window").width / 10) * 0.7,
      },
      neomorphLinksImageContainer: {
        width: (Dimensions.get("window").width / 10) * 1,
      },
      borderAppTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      collapsibleContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: (Dimensions.get("window").width / 10) * 8,
        paddingVertical: 5,
      },
      shadowMainContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      shadowLinkContainer: {
        shadowRadius: 10,
        shadowColor: colors.darkgrey,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
        borderRadius: 50,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
        elevation: 5,
      },
      shadowContainer: {
        width: (Dimensions.get("window").width / 10) * 8.4,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      shadowLinksTitleContainer: {
        width: (Dimensions.get("window").width / 10) * 5.8,
        justifyContent: "center",
        alignItems: "center",
      },
      shadowLinksTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      shadowLinksShare: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      shadowHeaderContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 3,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      shadowLinkTitleContainer: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
        textShadowColor: colors.white,
        textShadowRadius: 3,
      },
      shadowAppContainer: {
        shadowRadius: 5,
        shadowColor: colors.darkgrey,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
        borderRadius: 10,
        backgroundColor: colors.white,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 7,
      },
      shadowCollapsibleContainer: {
        width: (Dimensions.get("window").width / 10) * 8,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      shadowLinkTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      appLogoContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: (Dimensions.get("window").width / 10) * 8,
        paddingVertical: 5,
      },
      neomorphMainContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      neomorphLinksContainer: {
        shadowRadius: 10,
        shadowColor: colors.darkgrey,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowOpacity: 0.1,
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
        elevation: 5,
      },
      neomorphLinkContainer: {
        width: (Dimensions.get("window").width / 10) * 8.4,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      neomorphTitleContainer: {
        width: (Dimensions.get("window").width / 10) * 5.8,
        justifyContent: "center",
        alignItems: "center",
      },
      neomorphTitleText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      neomorphHeaderContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 3,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      neomorphAppMainContainer: {
        borderRadius: 10,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 7,
      },
      neomorphAppContainer: {
        width: (Dimensions.get("window").width / 10) * 8.5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      neomorphLinkTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      neomorphCollapsibleContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: (Dimensions.get("window").width / 10) * 8,
        paddingVertical: 5,
      },
      defaultMainContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      defaultLinkMainContainer: {
        borderRadius: 50,
        backgroundColor: colors.lightorange,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      defaultLinkContainer: {
        width: (Dimensions.get("window").width / 10) * 8.4,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      defaultLinkTitle: {
        width: (Dimensions.get("window").width / 10) * 5,
        justifyContent: "center",
        alignItems: "center",
      },
      defaultTitleText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      defaultHeaderContainer: {
        borderRadius: 50,
        width: (Dimensions.get("window").width / 10) * 3,
        //height: (Dimensions.get("window").width / 10) * 0.3,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingVertical: 7,
      },
      defaultHeaderTitle: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
        textShadowColor: colors.white,
        textShadowRadius: 3,
      },
      defaultAppMainContainer: {
        borderRadius: 10,
        backgroundColor: colors.lightorange,
        width: (Dimensions.get("window").width / 10) * 8.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 7,
      },
      defaultAppContainer: {
        width: (Dimensions.get("window").width / 10) * 8.5,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      defaultAppTitleText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 14,
        color: colors.black,
      },
      defaultCollapsibleContainer: {
        flexDirection: "row",
        justifyContent: "space-around",
        width: (Dimensions.get("window").width / 10) * 8,
        paddingVertical: 5,
      },
      mainActivityContainer: {
        width: (Dimensions.get("window").width / 10) * 4,
        height: (Dimensions.get("window").width / 10) * 4,
        alignItems: "center",
        justifyContent: "center",
      },
      footerMainContainer: {
        justifyContent: "center",
        alignItems: "center",
      },
      endListContainer: {
        justifyContent: "center",
        alignItems: "center",
        height: (Dimensions.get("window").height / 10) * 0.5,
      },
      loadMoreContainer: {
        justifyContent: "center",
        alignItems: "center",
        height: (Dimensions.get("window").height / 10) * 0.5,
      },
      footerLottieContainer: {
        width: (Dimensions.get("window").width / 10) * 4,
        height: (Dimensions.get("window").width / 10) * 4,
        opacity: 1,
        justifyContent: "center",
        alignItems: "center",
      },
      footerEmptyContainer: {
        width: (Dimensions.get("window").width / 10) * 4,
        height: (Dimensions.get("window").width / 10) * 5,
        opacity: 1,
        alignItems: "center",
      },
      footerEmptyText: {
        fontFamily: "MontserratSemiBold",
        fontSize: 13,
        color: colors.black,
      },
      footerLogoMain: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      footerLogoContainer: {
        marginTop: 10,
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      footerPower: {
        alignItems: "center",
        width: (Dimensions.get("window").width / 10) * 10,
      },
      footerPowerText: {
        fontFamily: "MontserratRegular",
        color: colors.black,
        fontSize: 11,
      },
      footerLogo: {
        width: (Dimensions.get("window").width / 10) * 10,
        alignItems: "center",
      },
      shareContainer: {
        position: "absolute",
        right: 0,
        top: 0,
        width: 40,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.lightorange,
        borderRadius: 20,
        borderColor: colors.white,
        borderWidth: 1,
      },
      bioContainer: {
        width: (Dimensions.get("window").width / 10) * 8,
        justifyContent: "center",
        alignItems: "center",
      },
      // bioTextTitle: {
      //   fontFamily: "MontserratSemiBold",
      //   color: colors.black,
      //   fontSize: 15,
      //   textShadowColor: colors.white,
      //   textShadowRadius: 3,
      // },
      // bioText: {
      //   fontFamily: "MontserratRegular",
      //   color: colors.black,
      //   fontSize: 13,
      //   textShadowColor: colors.white,
      //   textShadowRadius: 3,
      // },
      socialMainContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        paddingVertical: 10,
      },
      socialButtonContainer: {
        width: (Dimensions.get("window").width / 10) * 0.7,
        //marginHorizontal: 5,
      },
      modalImage: {
        width: (Dimensions.get("window").width / 10) * 7.5,
        height: (Dimensions.get("window").width / 10) * 7.5,
        borderRadius: (Dimensions.get("window").width / 10) * 4,
        overflow: "hidden",
      },
      modalMainContainer: {
        width: (Dimensions.get("window").width / 10) * 10,
        height: (Dimensions.get("window").height / 10) * 10,
        backgroundColor: colors.black,
        opacity: 0.99,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10000,
      },
      modalImageContainer: {
        width: (Dimensions.get("window").width / 10) * 8,
        height: (Dimensions.get("window").width / 10) * 8,
        justifyContent: "space-around",
        backgroundColor: colors.white,
        alignItems: "center",
        borderRadius: (Dimensions.get("window").width / 10) * 4,
        zIndex: 10000,
      },
      imageContainer: {
        width: (Dimensions.get("window").width / 10) * 7.8,
        height: (Dimensions.get("window").width / 10) * 7.8,
        alignItems: "center",
        justifyContent: "center",
      },
      modalImage: {
        width: (Dimensions.get("window").width / 10) * 7.8,
        height: (Dimensions.get("window").width / 10) * 7.8,
        borderRadius: (Dimensions.get("window").width / 10) * 4,
        overflow: "hidden",
      },
    },
  }
);
