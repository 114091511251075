const pageContent = [
  {
    id: 1,
    title: 'Refund Policy: Your LinksApp Experience',
    para: "At LinksApp, we are committed to providing a seamless and exceptional user experience for everyone who chooses to embark on their link-sharing journey with us. As part of this commitment, we'd like to outline our refund policy to ensure clarity and transparency.",
  },
  {
    id: 2,
    title: 'No Refunds Policy:',
    para: "We understand that circumstances can vary, and we value your trust in our platform. However, it's important to note that we do not offer refunds for our plans and subscriptions. This policy is in place to maintain consistency and fairness across all users.    ",
  },
  {
    id: 3,
    title: 'Your Satisfaction Matters:',
    para: "While we do not provide refunds, we are dedicated to addressing any concerns or issues you may encounter. If you experience any difficulties, encounter technical challenges, or have inquiries about our plans, features, or functionalities, our support team is here to assist you.",
  },
];

module.exports = {
  pageContent: pageContent,
};
