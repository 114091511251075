import React from "react";
import { View, Text, StyleSheet } from "react-native";

const TestScreen = (props) => {
    //console.log(props.route.params);
  return (
    <View style={styles.container}>
      <Text>Test Screen</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'transparent'
  },
});

export default TestScreen;
