import React from "react";
import { View, StyleSheet, TouchableOpacity, Linking } from "react-native";
import colors from "../constants/theme/colors";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

const WhatsAppChat = (props) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          Linking.openURL("https://wa.me/917645029741");
        }}
        style={styles.whatsappContainer}
      >
        <MaterialCommunityIcons
          name="whatsapp"
          color={colors.white}
          size={40}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 20,
    zIndex: 1000000,
    right: 20,
  },
  whatsappContainer: {
    width: 50,
    height: 50,
    backgroundColor: "#5ED076",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default WhatsAppChat;
