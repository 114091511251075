import "react-native-gesture-handler";
import * as React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import * as Linking from "expo-linking";
import MyTabs from "./navigattion/ProfileTabNavigation";
import { HelmetProvider } from "react-helmet-async";
import NotFoundScreen from "./screens/NotFoundScreen";
import DrawerNavigation from "./navigattion/DrawerNavigation";

const Tab = createMaterialTopTabNavigator();
const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

// const config = {
//   screens: {
//     Drawer: {
//       screens: {
//         Home: "",
//         About: "about",
//         Contact: "contact",
//         Privacy: "privacy",
//         Terms: "terms-conditions",
//         Refund: "refund",
//       },
//     },
//     Profile: {
//       path: "profile/:id",
//       screens: {
//         links: {
//           path: "links",
//         },
//         affiliate: {
//           path: "affiliate",
//         },
//         products: {
//           path: "products",
//         },
//       },
//     },
//     NotFound: "*",
//   },
// };

// const config = {
//   screens: {
//     Drawer: {
//       screens: {
//         Home: "",
//         About: "about",
//         Contact: "contact",
//         Privacy: "privacy",
//         Terms: "terms-conditions",
//         Refund: "refund",
//       },
//     },
//     Profile: {
//       path: "profile/:id",
//       screens: {
//         links: {
//           path: "links",
//         },
//         affiliate: {
//           path: "affiliate",
//         },
//         products: {
//           path: "products",
//           screens: {
//             all: {
//               path: "",
//             },
//             details: {
//               path: "details/:item",
//               // parse: {
//               //   item: (item) => item.replace(/^@/, '')
//               // },
//             },
//             checkout: {
//               path: "checkout/:item",
//             },
//           },
//         },
//       },
//     },
//     NotFound: "*",
//   },
// };

const config = {
  screens: {
    Drawer: {
      screens: {
        Home: "",
        About: "about",
        Contact: "contact",
        Privacy: "privacy",
        Terms: "terms-conditions",
        Refund: "refund",
      },
    },
    Profile: {
      path: "profile/:id",
      screens: {
        links: {
          path: "links",
        },
        affiliate: {
          path: "affiliate",
        },
        products: {
          path: "products",
          screens: {
            all: {
              path: "all",
            },
            details: {
              path: "details/:item",
              // parse: {
              //   item: (item) => item.replace(/^@/, '')
              // },
            },
            checkout: {
              path: "checkout/:item",
            },
          },
        },
        inactive: {
          path: "inactive",
        },
      },
    },
    NotFound: "*",
  },
};

const linking = {
  config,
};

const App = () => {
  return (
    <HelmetProvider>
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
          }}
          initialRouteName="Drawer"
        >
          <Stack.Screen name="Drawer" component={DrawerNavigation} />
          <Stack.Screen name="Profile" component={MyTabs} />
          <Stack.Screen name="NotFound" component={NotFoundScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </HelmetProvider>
  );
};

export default App;
