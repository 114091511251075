import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  Linking,
} from "react-native";
import colors from "../constants/theme/colors";

const CopyrightSection = (props) => {
  return (
    <TouchableOpacity
      onPress={() => {
        Linking.openURL(
          "https://fusedlogicinnovations.com"
        );
      }}
      style={styles.container}
    >
      <Text style={styles.copyrightText}>
        Copyrights © 2023. Designed with ❤️ by FusedLogic Innovations Pvt. Ltd.
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: Dimensions.get("window").width,
    backgroundColor: "#6d36e2",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  copyrightText: {
    fontFamily: "MontserratRegular",
    color: colors.white,
    textAlign: "center",
  },
});

export default CopyrightSection;
