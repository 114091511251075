import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  SafeAreaView,
  Image,
  ScrollView,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";

import Ionicons from "react-native-vector-icons/Ionicons";
import { API_URL, API_KEY, BASE_URL } from "@env";
import colorsList from "../constants/colors/colorsList.json";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
// import AwesomeAlert from 'react-native-awesome-alerts';
import colors from "../constants/theme/colors";
import {
  EXPO_PUBLIC_API_URL,
  EXPO_PUBLIC_API_KEY,
  EXPO_PUBLIC_BASE_URL,
} from "@env";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";
import alert from "../components/webAlert";

const ProductDetailsScreen = (props) => {
  const sizes = [50, 100, 150, 200, 250];
  const [selectedSize, setSelectedSize] = useState();
  const [selectedSizeIndex, setSelectedSizeIndex] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState();
  const [userIdFinalImage, setUserIdFinalImage] = useState();
  const deviceSize = useDeviceSize();

  //console.log(props.route.params);

  const productId = props.route.params.item;

  useEffect(() => {
    const fetchUserDetails = async (props) => {
      const productDetails = await getProductDetails(productId);
      if (productDetails.success) {
        setProductDetails(productDetails.productDetails);
        const productImage =
          EXPO_PUBLIC_BASE_URL +
          productDetails.productFolderPath +
          productDetails.productDetails.product_image;

        setUserIdFinalImage(productImage);

      } else {
        props.navigation.navigate("products");
      }
    };
    fetchUserDetails();
    setInterval(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getColorCode = (colorName) => {
    const colorObject = colorsList.find((color) => color.city === colorName);
    return colorObject ? colorObject.code : "#000000";
  };

  const selectedSizeHandler = (index, size) => {
    // console.log(index);
    // console.log(size);
    setSelectedSize(size);
    setSelectedSizeIndex(index);
  };

  const getProductDetails = async (productId) => {
    //console.log(selectedCategory);
    try {
      const apiResult = await fetch(
        `${EXPO_PUBLIC_API_URL}users/getProductDetails?id=${productId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            "ps-key": EXPO_PUBLIC_API_KEY,
          },
        }
      );
      const resData = await apiResult.json();
      //console.log(resData);
      return resData;
    } catch (error) {
      //console.log(error);
    }
  };

  if (loading) {
    if (deviceSize === "extralarge") {
      return (
        <View
          style={{
            flex: 1,
            width: (Dimensions.get("window").width / 10) * 3.5,
            height: (Dimensions.get("window").height / 10) * 10,
            backgroundColor: colors.storeLightGreen,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator color={colors.black} size={24} />
        </View>
      );
    } else {
      return (
        <View style={styles.container}>
          <ActivityIndicator color={colors.black} size={24} />
        </View>
      );
    }
  } else {
    if (deviceSize === "extralarge") {
      return (
        <SafeAreaView
          style={{
            flex: 1,
            width: (Dimensions.get("window").width / 10) * 3.5,
            backgroundColor: colors.storeLightGreen,
            alignItems: "center",
          }}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled
            style={{
              width: (Dimensions.get("window").width / 10) * 3.5,
              height: (Dimensions.get("window").width / 10) * 4,
            }}
          >
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 3.5,
                height: (Dimensions.get("window").width / 10) * 4,
                alignItems: "center",
              }}
            >
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3.5,
                  height: (Dimensions.get("window").width / 10) * 4,
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 0.5,
                    height: (Dimensions.get("window").width / 10) * 4,
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <View style={{ alignItems: "center" }}>
                    <View
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          color: colors.black,
                          fontSize: 12,
                        }}
                      >
                        Color
                      </Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: getColorCode(
                          productDetails.product_color ? productDetails.product_color.toString() : '#000000'
                        ),
                        width: 30,
                        height: 30,
                        borderRadius: 20,
                        borderWidth: 0.5,
                        borderColor: colors.black,
                        //marginVertical: 10,
                      }}
                    ></View>
                    <View
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          color: colors.black,
                          fontSize: 12,
                        }}
                      >
                        Size
                      </Text>
                    </View>
                    <ScrollView horizontal>
                      <FlatList
                        data={productDetails.product_sizes}
                        keyExtractor={(item, index) => item}
                        renderItem={({ item, index }) => (
                          <TouchableOpacity
                            onPress={() => {
                              selectedSizeHandler(index, item);
                            }}
                          >
                            {index == selectedSizeIndex ? (
                              <View
                                style={{
                                  backgroundColor: colors.peach,
                                  margin: 5,
                                  paddingHorizontal: 5,
                                  paddingVertical: 5,
                                  borderRadius: 5,
                                  borderWidth: 0.5,
                                  borderColor: colors.grey,
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    color: colors.white,
                                    fontSize: 12,
                                    fontFamily: "MontserratRegular",
                                  }}
                                >
                                  {item}
                                </Text>
                              </View>
                            ) : (
                              <View
                                style={{
                                  //backgroundColor: colors.red,
                                  margin: 5,
                                  paddingHorizontal: 5,
                                  paddingVertical: 5,
                                  borderRadius: 5,
                                  borderWidth: 0.5,
                                  borderColor: colors.grey,
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    color: colors.black,
                                    fontSize: 12,
                                    fontFamily: "MontserratRegular",
                                  }}
                                >
                                  {item}
                                </Text>
                              </View>
                            )}
                          </TouchableOpacity>
                        )}
                      />
                    </ScrollView>
                  </View>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    height: (Dimensions.get("window").width / 10) * 4,
                  }}
                >
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 3,
                      height: (Dimensions.get("window").width / 10) * 4,
                      backgroundColor: colors.lightgrey,
                      borderBottomLeftRadius: 50,
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View style={{ position: "absolute" }}>
                      <ActivityIndicator size={20} color={colors.black} />
                      {/* <Text>{userIdFinalImage}</Text> */}
                    </View>
                    <Image
                      style={{
                        width: (Dimensions.get("window").width / 10) * 3,
                        height: (Dimensions.get("window").width / 10) * 4,
                      }}
                      source={{
                        uri: userIdFinalImage,
                      }}
                    />
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3,
                  marginVertical: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    fontSize: 12,
                    color: colors.grey,
                  }}
                >
                  {productDetails.product_brand}
                </Text>
                <Text
                  style={{
                    fontFamily: "MontserratBold",
                    fontSize: 20,
                    color: colors.black,
                  }}
                >
                  {productDetails.product_title}
                </Text>
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    fontSize: 13,
                    color: colors.grey,
                    paddingVertical: 5,
                  }}
                >
                  {productDetails.product_description}
                </Text>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3,
                  alignItems: "flex-start",
                }}
              >
                {productDetails.product_selling_price ? (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <View style={{ justifyContent: "center" }}>
                      <Text
                        style={{
                          fontFamily: "MontserratBold",
                          color: colors.black,
                          fontSize: 20,
                        }}
                      >
                        ₹{productDetails.product_selling_price}
                      </Text>
                    </View>
                    <View style={{ marginLeft: 5, justifyContent: "center" }}>
                      <Text
                        style={{
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                          fontFamily: "MontserratRegular",
                          color: colors.grey,
                          fontSize: 13,
                        }}
                      >
                        ₹{productDetails.product_mrp}
                      </Text>
                    </View>
                    <View
                      style={{
                        justifyContent: "center",
                        marginLeft: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          color: colors.green,
                          fontSize: 11,
                        }}
                      >
                        (inc. of all taxes)
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ marginLeft: 5, justifyContent: "center" }}>
                        <Text
                          style={{
                            fontFamily: "MontserratBold",
                            color: colors.black,
                            fontSize: 20,
                          }}
                        >
                          ₹{productDetails.product_mrp}
                        </Text>
                      </View>
                      <View
                        style={{
                          justifyContent: "center",
                          marginLeft: 5,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "MontserratRegular",
                            color: colors.green,
                            fontSize: 11,
                          }}
                        >
                          (inc. of all taxes)
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3,
                  marginTop: 20,
                  marginBottom: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 14,
                    color: colors.black,
                  }}
                >
                  Product Details
                </Text>
              </View>
              {productDetails.product_brand && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Brand
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_brand}
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_material && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Material
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_material}
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_color && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Color
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_color ? productDetails.product_color.toUpperCase() : ''}
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_sku && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      SKU
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_sku}
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_weight && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Weight
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_weight} gms
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_length && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Length
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_length} cm
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_width && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Width
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_width} cm
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_height && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Height
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_height} cm
                    </Text>
                  </View>
                </View>
              )}
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3,
                  marginTop: 20,
                  marginBottom: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 14,
                    color: colors.black,
                  }}
                >
                  Seller Details
                </Text>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3,
                  marginVertical: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottomWidth: 0.5,
                  borderBottomColor: colors.lightorange,
                  paddingVertical: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    Seller Name
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontFamily: "MontserratSemiBold",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    {productDetails.product_brand}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3,
                  marginVertical: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottomWidth: 0.5,
                  borderBottomColor: colors.lightorange,
                  paddingVertical: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    Country of Origin
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontFamily: "MontserratSemiBold",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    India
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 3,
                  marginVertical: 10,
                  //backgroundColor: colors.peach,
                  alignItems: "flex-start",
                  //flexDirection: 'row',
                  justifyContent: "space-between",
                  //   borderBottomWidth: 0.5,
                  //   borderBottomColor: colors.lightorange,
                  paddingVertical: 5,
                }}
              >
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratSemiBold",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    About Seller
                  </Text>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 3,
                    alignItems: "center",
                    paddingVertical: 5,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      fontSize: 12,
                      color: colors.black,
                      textAlign: "center",
                    }}
                  >
                    {productDetails.user.store_description}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ paddingBottom: 900 }}></View>
          </ScrollView>
          <View
            style={{
              width: (Dimensions.get("window").width / 10) * 3.5,
              height: (Dimensions.get("window").width / 10) * 0.5,
              position: "absolute",
              bottom: 0,
              backgroundColor: colors.storeLightGreen,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              elevation: 10,
              shadowColor: colors.greenShadow,
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
            }}
          >
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 1.5,
                height: (Dimensions.get("window").width / 10) * 0.2,
                backgroundColor: colors.storeLightGreen,
                paddingHorizontal: 40,
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  fontFamily: "MontserratRegular",
                  fontSize: 12,
                  color: colors.black,
                }}
              >
                Total Price
              </Text>
              {productDetails.product_selling_price ? (
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 17,
                    color: colors.black,
                  }}
                >
                  ₹{productDetails.product_selling_price}
                </Text>
              ) : (
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 17,
                    color: colors.black,
                  }}
                >
                  ₹{productDetails.product_mrp}
                </Text>
              )}
            </View>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 2,
                height: (Dimensions.get("window").width / 10) * 0.2,
                backgroundColor: colors.storeLightGreen,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={() => {
                  if (selectedSize) {
                    props.navigation.navigate("checkout", {
                      item: productId,
                      size: selectedSize
                    });
                  } else {
                    alert("Select a size", "Please tap on a size to select");
                  }
                }}
                style={{
                  width: (Dimensions.get("window").width / 10) * 1.5,
                  height: (Dimensions.get("window").width / 10) * 0.4,
                  borderRadius: 10,
                  backgroundColor: colors.peach,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 16,
                    color: colors.white,
                  }}
                >
                  Buy Now
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          {/* <AwesomeAlert
              show={showAlert}
              showProgress={false}
              title="Select Size!"
              message="Please select a size to continue. Tap on the size to select it!"
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showCancelButton={false}
              showConfirmButton={true}
              confirmText="Okay"
              confirmButtonColor={colors.peach}
              onConfirmPressed={() => {
                setShowAlert(false);
              }}
              titleStyle={{
                fontFamily: 'MontserratSemiBold',
              }}
              messageStyle={{
                fontFamily: 'Montserrat-Light',
                textAlign: 'center',
              }}
              confirmButtonTextStyle={{
                fontFamily: 'MontserratBold',
              }}
            /> */}
        </SafeAreaView>
      );
    } else {
      return (
        <SafeAreaView style={styles.container}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled
            style={{
              width: (Dimensions.get("window").width / 10) * 10,
              height: (Dimensions.get("window").width / 10) * 10.6,
            }}
          >
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 10,
                height: (Dimensions.get("window").width / 10) * 10.6,
                alignItems: "center",
              }}
            >
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 10,
                  height: (Dimensions.get("window").width / 10) * 10.6,
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 2,
                    height: (Dimensions.get("window").width / 10) * 10.6,
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <View style={{ alignItems: "center" }}>
                    <View
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          color: colors.black,
                          fontSize: 12,
                        }}
                      >
                        Color
                      </Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: getColorCode(
                          productDetails.product_color ? productDetails.product_color.toString() : '#000000'
                        ),
                        width: 30,
                        height: 30,
                        borderRadius: 20,
                        borderWidth: 0.5,
                        borderColor: colors.black,
                        //marginVertical: 10,
                      }}
                    ></View>
                    <View
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratSemiBold",
                          color: colors.black,
                          fontSize: 12,
                        }}
                      >
                        Size
                      </Text>
                    </View>
                    <ScrollView horizontal>
                      <FlatList
                        data={productDetails.product_sizes}
                        keyExtractor={(item, index) => item}
                        renderItem={({ item, index }) => (
                          <TouchableOpacity
                            onPress={() => {
                              selectedSizeHandler(index, item);
                            }}
                          >
                            {index == selectedSizeIndex ? (
                              <View
                                style={{
                                  backgroundColor: colors.peach,
                                  margin: 5,
                                  paddingHorizontal: 5,
                                  paddingVertical: 5,
                                  borderRadius: 5,
                                  borderWidth: 0.5,
                                  borderColor: colors.grey,
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    color: colors.white,
                                    fontSize: 12,
                                    fontFamily: "MontserratRegular",
                                  }}
                                >
                                  {item}
                                </Text>
                              </View>
                            ) : (
                              <View
                                style={{
                                  //backgroundColor: colors.red,
                                  margin: 5,
                                  paddingHorizontal: 5,
                                  paddingVertical: 5,
                                  borderRadius: 5,
                                  borderWidth: 0.5,
                                  borderColor: colors.grey,
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    color: colors.black,
                                    fontSize: 12,
                                    fontFamily: "MontserratRegular",
                                  }}
                                >
                                  {item}
                                </Text>
                              </View>
                            )}
                          </TouchableOpacity>
                        )}
                      />
                    </ScrollView>
                  </View>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8,
                    height: (Dimensions.get("window").width / 10) * 10.6,
                  }}
                >
                  <View
                    style={{
                      width: (Dimensions.get("window").width / 10) * 8,
                      height: (Dimensions.get("window").width / 10) * 10.6,
                      backgroundColor: colors.lightgrey,
                      borderBottomLeftRadius: 50,
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View style={{ position: "absolute" }}>
                      <ActivityIndicator size={20} color={colors.black} />
                      {/* <Text>{userIdFinalImage}</Text> */}
                    </View>
                    <Image
                      style={{
                        width: (Dimensions.get("window").width / 10) * 8,
                        height: (Dimensions.get("window").width / 10) * 10.6,
                      }}
                      source={{
                        uri: userIdFinalImage,
                      }}
                    />
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8.5,
                  marginVertical: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    fontSize: 12,
                    color: colors.grey,
                  }}
                >
                  {productDetails.product_brand}
                </Text>
                <Text
                  style={{
                    fontFamily: "MontserratBold",
                    fontSize: 20,
                    color: colors.black,
                  }}
                >
                  {productDetails.product_title}
                </Text>
                <Text
                  style={{
                    fontFamily: "MontserratRegular",
                    fontSize: 13,
                    color: colors.grey,
                    paddingVertical: 5,
                  }}
                >
                  {productDetails.product_description}
                </Text>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8.5,
                  alignItems: "flex-start",
                }}
              >
                {productDetails.product_selling_price ? (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <View style={{ justifyContent: "center" }}>
                      <Text
                        style={{
                          fontFamily: "MontserratBold",
                          color: colors.black,
                          fontSize: 20,
                        }}
                      >
                        ₹{productDetails.product_selling_price}
                      </Text>
                    </View>
                    <View style={{ marginLeft: 5, justifyContent: "center" }}>
                      <Text
                        style={{
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                          fontFamily: "MontserratRegular",
                          color: colors.grey,
                          fontSize: 13,
                        }}
                      >
                        ₹{productDetails.product_mrp}
                      </Text>
                    </View>
                    <View
                      style={{
                        justifyContent: "center",
                        marginLeft: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "MontserratRegular",
                          color: colors.green,
                          fontSize: 11,
                        }}
                      >
                        (inc. of all taxes)
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ marginLeft: 5, justifyContent: "center" }}>
                        <Text
                          style={{
                            fontFamily: "MontserratBold",
                            color: colors.black,
                            fontSize: 20,
                          }}
                        >
                          ₹{productDetails.product_mrp}
                        </Text>
                      </View>
                      <View
                        style={{
                          justifyContent: "center",
                          marginLeft: 5,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "MontserratRegular",
                            color: colors.green,
                            fontSize: 11,
                          }}
                        >
                          (inc. of all taxes)
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8.5,
                  marginTop: 20,
                  marginBottom: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 14,
                    color: colors.black,
                  }}
                >
                  Product Details
                </Text>
              </View>
              {productDetails.product_brand && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Brand
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_brand}
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_material && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Material
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_material}
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_color && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Color
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_color ? productDetails.product_color.toUpperCase() : ''}
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_sku && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      SKU
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_sku}
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_weight && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Weight
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_weight} gms
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_length && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Length
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_length} cm
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_width && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Width
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_width} cm
                    </Text>
                  </View>
                </View>
              )}
              {productDetails.product_height && (
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    marginVertical: 10,
                    //backgroundColor: colors.black,
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 0.5,
                    borderBottomColor: colors.lightorange,
                    paddingVertical: 5,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratRegular",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      Height
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontFamily: "MontserratSemiBold",
                        fontSize: 12,
                        color: colors.black,
                      }}
                    >
                      {productDetails.product_height} cm
                    </Text>
                  </View>
                </View>
              )}
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8.5,
                  marginTop: 20,
                  marginBottom: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 14,
                    color: colors.black,
                  }}
                >
                  Seller Details
                </Text>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8.5,
                  marginVertical: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottomWidth: 0.5,
                  borderBottomColor: colors.lightorange,
                  paddingVertical: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    Seller Name
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontFamily: "MontserratSemiBold",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    {productDetails.product_brand}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8.5,
                  marginVertical: 10,
                  //backgroundColor: colors.black,
                  alignItems: "flex-start",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottomWidth: 0.5,
                  borderBottomColor: colors.lightorange,
                  paddingVertical: 5,
                }}
              >
                <View>
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    Country of Origin
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontFamily: "MontserratSemiBold",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    India
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: (Dimensions.get("window").width / 10) * 8.5,
                  marginVertical: 10,
                  //backgroundColor: colors.peach,
                  alignItems: "flex-start",
                  //flexDirection: 'row',
                  justifyContent: "space-between",
                  //   borderBottomWidth: 0.5,
                  //   borderBottomColor: colors.lightorange,
                  paddingVertical: 5,
                }}
              >
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratSemiBold",
                      fontSize: 12,
                      color: colors.black,
                    }}
                  >
                    About Seller
                  </Text>
                </View>
                <View
                  style={{
                    width: (Dimensions.get("window").width / 10) * 8.5,
                    alignItems: "center",
                    paddingVertical: 5,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "MontserratRegular",
                      fontSize: 12,
                      color: colors.black,
                      textAlign: "center",
                    }}
                  >
                    {productDetails.user.store_description}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ paddingBottom: 1000 }}></View>
          </ScrollView>
          <View
            style={{
              width: (Dimensions.get("window").width / 10) * 10,
              height: (Dimensions.get("window").width / 10) * 2.5,
              position: "absolute",
              bottom: 40,
              backgroundColor: colors.storeLightGreen,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              elevation: 10,
              shadowColor: colors.greenShadow,
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
            }}
          >
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 3,
                height: (Dimensions.get("window").width / 10) * 2.5,
                backgroundColor: colors.storeLightGreen,
                paddingHorizontal: 20,
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  fontFamily: "MontserratRegular",
                  fontSize: 12,
                  color: colors.black,
                }}
              >
                Total Price
              </Text>
              {productDetails.product_selling_price ? (
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 17,
                    color: colors.black,
                  }}
                >
                  ₹{productDetails.product_selling_price}
                </Text>
              ) : (
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 17,
                    color: colors.black,
                  }}
                >
                  ₹{productDetails.product_mrp}
                </Text>
              )}
            </View>
            <View
              style={{
                width: (Dimensions.get("window").width / 10) * 7,
                height: (Dimensions.get("window").width / 10) * 2.5,
                backgroundColor: colors.storeLightGreen,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={() => {
                  if (selectedSize) {
                    props.navigation.navigate("checkout", {
                      item: productId,
                      size: selectedSize
                    });
                  } else {
                    alert("Select a size", "Please tap on a size to select");
                  }
                }}
                style={{
                  width: (Dimensions.get("window").width / 10) * 6,
                  height: (Dimensions.get("window").width / 10) * 1.5,
                  borderRadius: 10,
                  backgroundColor: colors.peach,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "MontserratSemiBold",
                    fontSize: 16,
                    color: colors.white,
                  }}
                >
                  Buy Now
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          {/* <AwesomeAlert
              show={showAlert}
              showProgress={false}
              title="Select Size!"
              message="Please select a size to continue. Tap on the size to select it!"
              closeOnTouchOutside={false}
              closeOnHardwareBackPress={false}
              showCancelButton={false}
              showConfirmButton={true}
              confirmText="Okay"
              confirmButtonColor={colors.peach}
              onConfirmPressed={() => {
                setShowAlert(false);
              }}
              titleStyle={{
                fontFamily: 'MontserratSemiBold',
              }}
              messageStyle={{
                fontFamily: 'Montserrat-Light',
                textAlign: 'center',
              }}
              confirmButtonTextStyle={{
                fontFamily: 'MontserratBold',
              }}
            /> */}
        </SafeAreaView>
      );
    }
  }
};

const styles = StyleSheet.create({
  container: {
    width: (Dimensions.get("window").width / 10) * 10,
    height: (Dimensions.get("window").height / 10) * 10,
    backgroundColor: colors.storeLightGreen,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ProductDetailsScreen;
