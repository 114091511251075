import React from "react";
import { Helmet } from "react-helmet-async";

const SEOSection = (props) => {
  return (
    <Helmet>
      <html lang="en" />
      <title>{props.title} - LinksApp</title>
      <meta name="title" content={props.title} data-react-helmet="true" />
      <meta name="description" content={props.description} />
      <meta property="og:site_name" content={props.title} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:url" content={props.apple} />
      <meta property="og:image" itemprop="image" content={props.image} />
      <meta property="og:type" content="website" />
      <meta property="og:updated_time" content={new Date()} />
      <meta name="keywords" content="LinksApp" />
      <meta name="robots" content="index, follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta itemprop="name" content={props.title} />
      <meta
        itemprop="description"
        content={props.description}
      />
      <meta
        itemprop="image"
        content={props.image}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={props.title}
      />
      <meta
        name="twitter:description"
        content={props.description}
      />
      <meta
        name="twitter:image"
        content={props.image}
      />
    </Helmet>
  );
};

export default SEOSection;
