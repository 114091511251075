import React from 'react';
import {View, StyleSheet, Dimensions} from 'react-native';
import colors from '../constants/theme/colors';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";

const StoreCard = props => {
  const deviceSize = useDeviceSize();
  
  if (deviceSize === 'extralarge') {
    return (
      <View
        style={{
          width: (Dimensions.get('window').width / 10) * 3.2,
          marginHorizontal: (Dimensions.get('window').width / 10) * 0.5,
          marginVertical: props.margin,
          paddingVertical: props.padding,
          alignItems: 'center',
          backgroundColor: colors.white,
          elevation: 5,
          shadowColor: colors.greenShadow,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          borderRadius: 15,
          justifyContent: 'space-between',
        }}>
        {props.children}
      </View>
    );
  } else {
    return (
      <View
        style={{
          width: (Dimensions.get('window').width / 10) * 9,
          marginHorizontal: (Dimensions.get('window').width / 10) * 0.5,
          marginVertical: props.margin,
          paddingVertical: props.padding,
          alignItems: 'center',
          backgroundColor: colors.white,
          elevation: 5,
          shadowColor: colors.greenShadow,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          borderRadius: 15,
          justifyContent: 'space-between',
        }}>
        {props.children}
      </View>
    );
  }
};

export default StoreCard;
