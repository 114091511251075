const pageContent = [
  {
   "id": 1,
   "title": "1. Introduction",
  },
  {
   "id": 2,
   "para": "Welcome to LinksApp, operated by Fusedlogic Innovations PVT LTD ('LinksApp,' 'we,' 'us,' or 'our), is committed to protecting the privacy of our users ('User' or 'Users'). This Privacy Policy governs the collection, use, and disclosure of personal information obtained through the LinksApp mobile application ('App') and related services. By using the App, you agree to the terms of this Privacy Policy."
  },
  {
   "id": 3,
   "title": "2. Information We Collect",
  },
  {
   "id": 4,
   "title": "a. Personal Information:",
   "para": "When you register for an account or use certain features of the App, we may collect personal information such as your name, email address, contact information, and payment details."
  },
  {
   "id": 5,
   "title": "b. Social Links and Affiliate Information:",
   "para": "We collect information about the social media platforms and affiliate programs you link to your LinksApp account, including usernames, URLs, and affiliate product details."
  },
  {
   "id": 6,
   "title": "c. Usage Data:",
   "para": "We may automatically collect information about your use of the App, including log data, device information, IP address, and browsing history."
  },
  {
   "id": 7,
   "title": "3. How We Use Your Information:",
  },
  {
   "id": 8,
   "title": "a. Providing Services:",
   "para": "We use your information to provide and maintain the App, personalize your experience, and improve our products and services."
  },
  {
   "id": 9,
   "title": "b. Communication:",
   "para": "We may use your email address to send you administrative communications, updates, newsletters, and marketing materials."
  },
  {
   "id": 10,
   "title": "c. Affiliate Marketing:",
   "para": "Your affiliate information is used to facilitate affiliate marketing activities and track affiliate referrals and earnings."
  },
  {
   "id": 11,
   "title": "4. Data Sharing and Disclosure",
  },
  {
   "id": 12,
   "title": "a. Third-Party Service Providers:",
   "para": "We may share your information with third-party service providers who assist us in operating the App, conducting business activities, and delivering services."
  },
  {
   "id": 13,
   "title": "b. Legal Compliance:",
   "para": "We may disclose your information in response to legal requirements, court orders, or government requests, or to protect the rights, property, or safety of LinksApp, our users, or others."
  },
  {
   "id": 14,
   "title": "5. Data Retention",
  },
  {
   "id": 15,
   "para": "We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law."
  },
  {
   "id": 16,
   "title": "6. Security",
  },
  {
   "id": 17,
   "para": "We take appropriate measures to protect the security of your personal information, but no method of transmission over the internet or electronic storage is 100% secure."
  },
  {
   "id": 18,
   "title": "7. Your Rights and Choices",
  },
  {
   "id": 19,
   "title": "a. Access and Correction:",
   "para": "You may access and update your personal information through your account settings."
  },
  {
   "id": 20,
   "title": "b. Opt-Out:",
   "para": "You can opt-out of receiving marketing communications by following the instructions provided in the emails or contacting us directly."
  },
  {
   "id": 21,
   "title": "8. Children's Privacy"
  },
  {
   "id": 22,
   "para": "LinksApp is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe your child has provided personal information without your consent, please contact us to have the information removed."
  },
  {
   "id": 23,
   "title": "9. Changes to this Privacy Policy",
  },
  {
   "id": 24,
   "para": "We reserve the right to modify this Privacy Policy at any time. Changes will be effective immediately upon posting on the App/Website. It is your responsibility to review this Privacy Policy periodically for updates."
  },
 ]
  
  module.exports = {
    pageContent: pageContent,
  };
  