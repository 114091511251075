import React, { useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Dimensions,
  Image,
  ScrollView,
  Button,
  useWindowDimensions,
} from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import colors from "../constants/theme/colors";
import { useFonts } from "expo-font";
import LottieView from "react-native-web-lottie";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";

const PageBanner = (props) => {
  const deviceSize = useDeviceSize();

  const [loaded] = useFonts({
    MontserratRegular: require("../assets/fonts/Montserrat-Regular.ttf"),
    MontserratBold: require("../assets/fonts/Montserrat-Bold.ttf"),
    MontserratSemiBold: require("../assets/fonts/Montserrat-SemiBold.ttf"),
  });

  if (!loaded) {
    return null;
  }

  if (deviceSize === "extralarge") {
    return (
      <View style={styles.mainBanner}>
        <View style={styles.bannerContainer}>
          <View style={styles.headetTextContainer}>
            <Text style={styles.headerText}>{props.pageTitle}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              width: 200,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderBottomWidth: 0.5,
                borderColor: colors.white,
                width: 100,
              }}
            ></View>
            <MaterialCommunityIcons
              name="link-variant"
              size={30}
              color={colors.white}
            />
            <View
              style={{
                borderBottomWidth: 1,
                borderColor: colors.white,
                width: 100,
              }}
            ></View>
          </View>
        </View>
        <View style={styles.lottiContainer}>
          <View
            style={{
              width: Dimensions.get("window").width,
              height: (Dimensions.get("window").height / 10) * 0.25,
              backgroundColor: colors.peach,
              zIndex: 11111,
            }}
          ></View>
          <View
            style={{
              width: Dimensions.get("window").width,
              //height: (Dimensions.get("window").height / 10) * 1,
              backgroundColor: colors.white,
              marginTop: -10,
            }}
          >
            <LottieView
              source={require("../assets/lottie/wave.json")}
              autoPlay
              loop
              resizeMode="cover"
            />
          </View>
          <View
            style={{
              width: Dimensions.get("window").width,
              height: (Dimensions.get("window").height / 10) * 3,
              backgroundColor: colors.white,
            }}
          ></View>
        </View>
        <View style={styles.textContainer}>
          {props.pageContent.map((item) => {
            return (
              <Text key={item.id} style={styles.bodyTextStyle}>
                {item.title && (
                  <Text
                    style={{
                      fontFamily: "MontserratBold",
                      paddingRight: 5,
                      textAlign: "left",
                    }}
                  >
                    {item.title}
                  </Text>
                )}
                {item.para}
              </Text>
            );
          })}
        </View>
      </View>
    );
  } else {
    return (
      <View style={stylesSM.mainBanner}>
        <View style={stylesSM.bannerContainer}>
          <View style={stylesSM.headetTextContainer}>
            <Text style={stylesSM.headerText}>{props.pageTitle}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              width: 200,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                borderBottomWidth: 0.5,
                borderColor: colors.white,
                width: 100,
              }}
            ></View>
            <MaterialCommunityIcons
              name="link-variant"
              size={30}
              color={colors.white}
            />
            <View
              style={{
                borderBottomWidth: 1,
                borderColor: colors.white,
                width: 100,
              }}
            ></View>
          </View>
        </View>
        <View style={stylesSM.lottiContainer}>
          <View
            style={{
              width: Dimensions.get("window").width,
              //height: (Dimensions.get("window").height / 10) * 1,
              backgroundColor: colors.white,
              marginTop: -10,
            }}
          ></View>
          <LottieView
            source={require("../assets/lottie/wave.json")}
            autoPlay
            loop
            resizeMode="cover"
          />
          <View
            style={{
              width: Dimensions.get("window").width,
              height: (Dimensions.get("window").height / 10) * 1,
              backgroundColor: colors.white,
            }}
          ></View>
        </View>
        <View style={stylesSM.textContainer}>
          {props.pageContent.map((item) => {
            return (
              <Text key={item.id} style={styles.bodyTextStyle}>
                {item.title && (
                  <Text
                    style={{ fontFamily: "MontserratBold", paddingRight: 5 }}
                  >
                    {item.title}
                  </Text>
                )}
                {item.para}
              </Text>
            );
          })}
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    alignItems: "center",
  },
  bannerContainer: {
    width: Dimensions.get("window").width,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    //height: (Dimensions.get("window").height / 10) * 2,
    backgroundColor: colors.peach,
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mainBannerRightCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 35,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 14,
    color: colors.white,
  },
  lottiContainer: {
    width: Dimensions.get("window").width,
    height: (Dimensions.get("window").height / 10) * 2,
    //paddingVertical: -10,
    backgroundColor: colors.white,
  },
  textContainer: {
    width: (Dimensions.get("window").width / 10) * 6,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingBottom: 50,
    paddingTop: 10,
  },
  bodyTextStyle: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    textAlign: "justify",
    letterSpacing: 0.2,
    color: colors.darkgrey,
    marginVertical: 5,
  },
});

const stylesSM = StyleSheet.create({
  mainBanner: {
    width: (Dimensions.get("window").width / 10) * 10,
    alignItems: "center",
  },
  bannerContainer: {
    width: Dimensions.get("window").width,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    height: (Dimensions.get("window").height / 10) * 2,
    backgroundColor: colors.peach,
  },
  mainBannerLeftCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  mainBannerRightCoulumn: {
    width: (Dimensions.get("window").width / 10) * 5,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  maineBannerLeftText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    padding: 10,
  },
  maineBannerRigntText: {
    width: (Dimensions.get("window").width / 10) * 4,
    alignItems: "center",
    padding: 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 25,
    color: colors.white,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 16,
    color: colors.white,
    textAlign: "center",
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  headetTextContainer: {},
  bodyTextContainer: {
    marginVertical: 10,
  },
  downloadContainer: {
    width: (Dimensions.get("window").width / 10) * 3,
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  appStoreContainer: {
    width: (Dimensions.get("window").width / 10) * 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 5,
    padding: 10,
  },
  downloadHText: {
    fontFamily: "MontserratRegular",
    fontSize: 11,
    color: colors.white,
  },
  downloadBText: {
    fontFamily: "MontserratBold",
    fontSize: 14,
    color: colors.white,
  },
  lottiContainer: {
    width: Dimensions.get("window").width,
    height: (Dimensions.get("window").height / 10) * 0.5,
  },
  textContainer: {
    width: (Dimensions.get("window").width / 10) * 8,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 70,
    paddingTop: 50,
  },
  bodyTextStyle: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    textAlign: "justify",
    letterSpacing: 0.2,
    color: colors.darkgrey,
    marginVertical: 5,
  },
});

export default PageBanner;
