import React from "react";
import { View, Text, StyleSheet, Dimensions, ScrollView } from "react-native";
import colors from "../constants/theme/colors";
import MainHeader from "../components/mainHeader";
import PageBanner from "../components/pageBanner";
import FooterSection from "../components/footerSection";
import PlanSection from "../components/plansSection";
import CopyrightSection from "../components/copyrightSection";
import { pageContent } from "../constants/pages/contact";
import WhatsAppChat from "../components/whatsAppChat";

const ContactScreen = (props) => {
  return (
    <View style={styles.container}>
      <MainHeader props={props} />
      <ScrollView>
        <PageBanner pageTitle="Contact Us" pageContent={pageContent} />
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: (Dimensions.get("window").width / 10) * 10,
            paddingVertical: 20,
          }}
        >
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: (Dimensions.get("window").width / 10) * 10,
            }}
          >
            <Text style={styles.headerText}>Contact Details</Text>
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: (Dimensions.get("window").width / 10) * 10,
              paddingVertical: 20,
            }}
          >
            <Text style={styles.bodyText}>
              <Text style={{ fontFamily: "MontserratBold", paddingRight: 5 }}>
                Address:
              </Text>{" "}
              B303, Vartak Heights, RJ Nagar, Phoolpada Road, Virar, Palghar, Maharashtra - 401305
            </Text>
            <Text style={styles.bodyText}>
              <Text style={{ fontFamily: "MontserratBold", paddingRight: 5 }}>
                Email:
              </Text>{" "}
              help@linksapp.in
            </Text>
            <Text style={styles.bodyText}>
              <Text style={{ fontFamily: "MontserratBold", paddingRight: 5 }}>
                Contact:
              </Text>{" "}
              +91-7645029741
            </Text>
          </View>
        </View>
        <FooterSection props={props} />
        <CopyrightSection />
      </ScrollView>
      <WhatsAppChat />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    width: Dimensions.get("window").width,
  },
  scrollView: {
    width: (Dimensions.get("window").width / 10) * 10,
  },
  headerText: {
    fontFamily: "MontserratBold",
    fontSize: 20,
    color: colors.textcolor,
    textAlign: "center",
  },
  bodyText: {
    fontFamily: "MontserratRegular",
    fontSize: 13,
    color: colors.textcolor,
    textAlign: "center",
  },
});

export default ContactScreen;
