import { Text, View, StyleSheet, Dimensions } from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { Helmet } from "react-helmet";
import HomeScreen from "../screens/HomeScreen";
import AboutScreen from "../screens/AboutScreen";
import ContactScreen from "../screens/ContactScreen";
import PrivacyScreen from "../screens/PrivacyScreen";
import TermsScreen from "../screens/TermsScreen";
import RefundScreen from "../screens/RefundScreen";
import { createDrawerNavigator } from "@react-navigation/drawer";
import colors from "../constants/theme/colors";

const DrawerNavigation = ({ route }) => {
  const Drawer = createDrawerNavigator();
  //console.log(route.params);
  return (
    <View
      style={{
        width: Dimensions.get("window").width,
        backgroundColor: "transparent",
        flex: 1,
      }}
    >
      <Drawer.Navigator
        screenOptions={{
          drawerActiveTintColor: colors.peach,
          drawerLabelStyle: {
            fontFamily: "MontserratSemiBold",
          },
          headerShown: false,
        }}
      >
        <Drawer.Screen name="Home" options={{drawerLabel: 'Home'}} component={HomeScreen} />
        <Drawer.Screen name="About" options={{drawerLabel: 'About Us'}} component={AboutScreen} />
        <Drawer.Screen name="Contact" options={{drawerLabel: 'Contact Us'}} component={ContactScreen} />
        <Drawer.Screen name="Privacy" options={{drawerLabel: 'Privacy Policy'}} component={PrivacyScreen} />
        <Drawer.Screen name="Terms" options={{drawerLabel: 'Terms & Conditions'}} component={TermsScreen} />
        <Drawer.Screen name="Refund" options={{drawerLabel: 'Refund Policy'}} component={RefundScreen} />
      </Drawer.Navigator>
    </View>
  );
};

export default DrawerNavigation;
