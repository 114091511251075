import React from "react";
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import colors from "../constants/theme/colors";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  useDeviceSize,
} from "rn-responsive-styles";

const FooterSection = ({ props }) => {
  const deviceSize = useDeviceSize();

  if (deviceSize === "extralarge") {
    return (
      <View
        style={{
          width: (Dimensions.get("window").width / 10) * 10,
          backgroundColor: colors.peach,
          justifyContent: "space-around",
          alignItems: "center",
          paddingVertical: 50,
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 2,
            //backgroundColor: colors.peach,
          }}
        >
          <View style={{ padding: 5 }}>
            <Image
              style={styles.logo}
              source={require("../assets/logo/links-app-logo-light.png")}
            />
          </View>
          <View
            style={{
              width: (Dimensions.get("window").width / 10) * 2,
              paddingVertical: 10,
            }}
          >
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
              }}
            >
              LinksApp is your Indian-made solution to streamline online
              connections. Customize and share your links effortlessly,
              embracing Atmanirbhar Bharat's ethos. Unveil a world where link
              management meets affiliate empowerment, turning recommendations
              into revenue. Join us in shaping a digital landscape that's both
              innovative and self-reliant. Your links, your influence, your
              growth – all powered by LinksApp.
            </Text>
          </View>
          <View
            style={{
              width: (Dimensions.get("window").width / 10) * 2,
              flexDirection: "row",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.facebook.com/linksapp.in");
              }}
              style={styles.iconStyle}
            >
              <MaterialCommunityIcons
                name="facebook"
                color={colors.white}
                size={30}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://instagram.com/linksapp.in");
              }}
              style={styles.iconStyle}
            >
              <MaterialCommunityIcons
                name="instagram"
                color={colors.white}
                size={30}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.linkedin.com/company/linksapp");
              }}
              style={styles.iconStyle}
            >
              <MaterialCommunityIcons
                name="linkedin"
                color={colors.white}
                size={30}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.youtube.com/@linksapp");
              }}
              style={styles.iconStyle}
            >
              <MaterialCommunityIcons
                name="youtube"
                color={colors.white}
                size={30}
              />
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 1.5,
            backgroundColor: colors.peach,
          }}
        >
          <Text
            style={{
              color: colors.white,
              fontFamily: "MontserratBold",
              fontSize: 17,
            }}
          >
            Useful Links
          </Text>
          <View style={{ marginVertical: 20 }}>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "About" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                }}
              >
                About Us
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "Contact" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                }}
              >
                Contact Us
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "Privacy" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                }}
              >
                Privacy Policy
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "Terms" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                }}
              >
                Terms & Conditions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "Refund" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                }}
              >
                Refund Policy
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 2,
            backgroundColor: colors.peach,
          }}
        >
          <Text
            style={{
              color: colors.white,
              fontFamily: "MontserratBold",
              fontSize: 17,
            }}
          >
            Download Links
          </Text>
          <View style={{ marginVertical: 20 }}>
          <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  "https://apps.apple.com/in/app/linksapp/id6466167153"
                );
              }}
            >
              <Image
                style={styles.appLogo}
                source={require("../assets/images/appstore.png")}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  "https://play.google.com/store/apps/details?id=com.linksapp"
                );
              }}
            >
              <Image
                style={styles.appLogo}
                source={require("../assets/images/playstore.png")}
              />
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 2,
            backgroundColor: colors.peach,
          }}
        >
          <Text
            style={{
              color: colors.white,
              fontFamily: "MontserratBold",
              fontSize: 17,
            }}
          >
            Contact Us
          </Text>
          <View
            style={{
              marginVertical: 20,
              width: (Dimensions.get("window").width / 10) * 2,
            }}
          >
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
              }}
            >
              B303, Vartak Heights, RJ Nagar, Phoolpada Road, Virar, Palghar,
              Maharashtra
            </Text>
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
              }}
            >
              Pin: 401305
            </Text>
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
              }}
            >
              help@linksapp.in
            </Text>
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
              }}
            >
              Call: +91-7645029741
            </Text>
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
              }}
            >
              WA: +91-7645029741
            </Text>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.footerMainContainer}>
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 8,
            justifyContent: "center",
            alignItems: "center",
            //backgroundColor: colors.peach,
          }}
        >
          <View style={{ padding: 5 }}>
            <Image
              style={styles.logo}
              source={require("../assets/logo/links-app-logo-light.png")}
            />
          </View>
          <View
            style={{
              width: (Dimensions.get("window").width / 10) * 8,
              paddingVertical: 10,
            }}
          >
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                textAlign: "center",
                fontSize: 13,
              }}
            >
              LinksApp is your Indian-made solution to streamline online
              connections. Customize and share your links effortlessly,
              embracing Atmanirbhar Bharat's ethos. Unveil a world where link
              management meets affiliate empowerment, turning recommendations
              into revenue. Join us in shaping a digital landscape that's both
              innovative and self-reliant. Your links, your influence, your
              growth – all powered by LinksApp.
            </Text>
          </View>
          <View
            style={{
              width: (Dimensions.get("window").width / 10) * 8,
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.facebook.com/linksapp.in");
              }}
              style={styles.iconStyle}
            >
              <MaterialCommunityIcons
                name="facebook"
                color={colors.white}
                size={30}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.instagram.com/linksapp.in");
              }}
              style={styles.iconStyle}
            >
              <MaterialCommunityIcons
                name="instagram"
                color={colors.white}
                size={30}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.linkedin.com/company/linksapp");
              }}
              style={styles.iconStyle}
            >
              <MaterialCommunityIcons
                name="linkedin"
                color={colors.white}
                size={30}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://www.youtube.com/@linksapp");
              }}
              style={styles.iconStyle}
            >
              <MaterialCommunityIcons
                name="youtube"
                color={colors.white}
                size={30}
              />
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 8,
            backgroundColor: colors.peach,
            alignItems: "center",
            marginVertical: 10,
          }}
        >
          <Text
            style={{
              color: colors.white,
              fontFamily: "MontserratBold",
              fontSize: 17,
              textAlign: "center",
            }}
          >
            Useful Links
          </Text>
          <View style={{ marginVertical: 20, alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "About" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                  textAlign: "center",
                }}
              >
                About Us
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "Contact" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                  textAlign: "center",
                }}
              >
                Contact Us
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "Privacy" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                  textAlign: "center",
                }}
              >
                Privacy Policy
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "Terms" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                  textAlign: "center",
                }}
              >
                Terms & Conditions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("Drawer", { screen: "Refund" });
              }}
            >
              <Text
                style={{
                  color: colors.white,
                  fontFamily: "MontserratRegular",
                  fontSize: 13,
                  marginVertical: 3,
                  textAlign: "center",
                }}
              >
                Refund Policy
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 8,
            backgroundColor: colors.peach,
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: colors.white,
              fontFamily: "MontserratBold",
              fontSize: 17,
              textAlign: "center",
            }}
          >
            Contact Us
          </Text>
          <View
            style={{
              marginVertical: 20,
              width: (Dimensions.get("window").width / 10) * 8,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
                textAlign: "center",
              }}
            >
              B303, Vartak Heights, RJ Nagar, Phoolpada Road, Virar, Palghar,
              Maharashtra
            </Text>
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
                textAlign: "center",
              }}
            >
              Pin: 401305
            </Text>
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
                textAlign: "center",
              }}
            >
              help@linksapp.in
            </Text>
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
                textAlign: "center",
              }}
            >
              Call: +91-7645029741
            </Text>
            <Text
              style={{
                color: colors.white,
                fontFamily: "MontserratRegular",
                fontSize: 13,
                marginVertical: 3,
                textAlign: "center",
              }}
            >
              WA: +91-7645029741
            </Text>
          </View>
        </View>
        <View
          style={{
            width: (Dimensions.get("window").width / 10) * 8,
            backgroundColor: colors.peach,
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: colors.white,
              fontFamily: "MontserratBold",
              fontSize: 17,
              textAlign: "center",
            }}
          >
            Download Links
          </Text>
          <View style={{ marginVertical: 20 }}>
          <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  "https://apps.apple.com/in/app/linksapp/id6466167153"
                );
              }}
            >
              <Image
                style={styles.appLogo}
                source={require("../assets/images/appstore.png")}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  "https://play.google.com/store/apps/details?id=com.linksapp"
                );
              }}
            >
              <Image
                style={styles.appLogo}
                source={require("../assets/images/playstore.png")}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  iconStyle: {
    justifyContent: "center",
    borderRadius: 50,
    width: 50,
    height: 50,
    alignItems: "center",
    shadowColor: "#000",
  },
  logo: {
    width: 110,
    height: 45,
  },
  appLogo: {
    width: 150,
    height: 45,
    marginVertical: 5,
  },
  footerMainContainer: {
    width: (Dimensions.get("window").width / 10) * 10,
    backgroundColor: colors.peach,
    justifyContent: "space-around",
    alignItems: "center",
    paddingVertical: 50,
    //flexDirection: "row",
  },
});

export default FooterSection;
