import React from "react";
import { View, StyleSheet, Text } from "react-native";
import colors from "../constants/theme/colors";
import LottieView from "react-native-web-lottie";

const InactiveTab = (props) => {
  return (
    <View style={styles.container}>
      <LottieView
        source={require("../assets/lottie/lost.json")}
        autoPlay
        loop
        resizeMode="cover"
      />
      <Text
        style={{
          fontFamily: "MontserratSemiBold",
          fontSize: 15,
          color: colors.black,
        }}
      >
        Account is not active!
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default InactiveTab;
